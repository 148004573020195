import Section from "../../components/Section";
import { SectionHeader } from "../../components/Header";
import FrameList from "./FrameList";
import useVisualsExcerpt from "./hooks/useVisualsExcerpt";
import Slideshow from "../slideshow/Slideshow";
import FileList from "./FileList";
import NameConflictHandler from "./NameConflictHandler";
import useLang from "../../hooks/useLang";
import Input from "../../components/Input";
import VisualsUploader from "./VisualsUploader";
import AccessWrapper from "../auth/AccessWrapper";
import { BundleGroup } from "../../components/Group";

const VisualsExcerpt = ({ visuals, title, type }) => {
  const lang = useLang();
  const {
    frames,
    obsoleteFrames,
    slideshowFrames,
    placeholders,
    handleDrop,
    handleUploadClick,
    fileInputAttribs,
    placeholdersPaused,
    onResolve,
    showHidden,
    onShowHidden,
    action,
    visibilityAction,
    project,
  } = useVisualsExcerpt(visuals, type);

  return (
    <>
      <Section>
        <SectionHeader>
          <h2>{title}</h2>
          {frames?.length > 0 ? (
            <AccessWrapper action={visibilityAction} project={project}>
              <BundleGroup width={40}>
                <Input
                  type="checkbox"
                  label={lang("manage_visibility")}
                  onChange={onShowHidden}
                />
              </BundleGroup>
            </AccessWrapper>
          ) : null}
        </SectionHeader>
        <AccessWrapper action={action}>
          <VisualsUploader
            canUpload={true}
            fileInput={fileInputAttribs}
            handleDrop={handleDrop}
            handleUploadClick={handleUploadClick}
          />
        </AccessWrapper>
        <NameConflictHandler
          placeholders={placeholdersPaused}
          onResolve={onResolve}
        />
        <FrameList
          frames={frames}
          placeholders={placeholders}
          showHidden={showHidden}
        />
        <Slideshow frames={slideshowFrames} obsoleteFrames={obsoleteFrames} />
        <FileList files={visuals?.files} type={type} />
      </Section>
    </>
  );
};

export default VisualsExcerpt;
