import { FormModal, FormModalControlled } from "../../components/Modal";
import Form from "../../components/Form";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import useLang from "../../hooks/useLang";
import ErrorMessage from "../../components/ErrorMessage";
import FormSection from "../../components/FormSection";
import { TertiaryParagraph } from "../../components/Paragraph";
import Popup from "../../components/Popup";
import Button from "../../components/Button";
import { BundleGroup } from "../../components/Group";
import { useState, useEffect } from "react";

const ModifierForm = ({
  inputs,
  templates,
  onTemplateSelect,
  selectedTemplate,
  errMsg,
  titleInput,
  commentInput,
  enabled,
  setEnabled,
}) => {
  const lang = useLang();
  const renderInput = (input) => (
    <FormSection key={input.key}>
      <Input
        type="text"
        label={input.label}
        value={input.value}
        onChange={input.onChange}
      />
    </FormSection>
  );

  useEffect(() => {
    if (selectedTemplate?.id != null && titleInput?.value) {
      if (!enabled) setEnabled(true);
    } else if (enabled) {
      setEnabled(false);
    }
    // eslint-disable-next-line
  }, [selectedTemplate, titleInput?.value]);

  return (
    <Form>
      <ErrorMessage errMsg={errMsg} />
      {titleInput ? (
        <Input
          type="text"
          label={lang("item_name")}
          invalid={!titleInput?.value}
          {...titleInput.attributes}
        />
      ) : null}

      {templates && Array.isArray(templates) && templates.length > 0 ? (
        <BundleGroup>
          <p>{selectedTemplate?.name}</p>
          <Popup
            key={selectedTemplate?.id}
            expandBottomRight
            renderOpen={(onClick) => (
              <Button onClick={onClick} icon={"chevronDown"} rounded />
            )}
          >
            <div
              style={{
                display: "flex",
                gap: "var(--spacing-2)",
                flexDirection: "column",
                whiteSpace: "nowrap",
              }}
            >
              <TertiaryParagraph>{`${lang("choose_type")}:`}</TertiaryParagraph>
              {templates
                .filter((template) => template?.id != null)
                .map((template) => (
                  <Button
                    inline
                    emphasized
                    key={template?.id || null}
                    onClick={() => {
                      onTemplateSelect(template);
                    }}
                  >
                    {template.name}
                  </Button>
                ))}
            </div>
          </Popup>
        </BundleGroup>
      ) : null}
      <BundleGroup
        vertical
        style={{
          overflow: "auto",
          maxHeight: "calc(100vh - 250px)",
        }}
      >
        {inputs?.map(renderInput)}
        <FormSection>
          <TextArea
            placeholder={lang("notes_placeholder")}
            {...commentInput.attributes}
            style={{ flexGrow: "1" }}
          />
        </FormSection>
      </BundleGroup>
    </Form>
  );
};

const ProjectModifier = (props) => {
  const {
    modalTitle,
    submitLabel,
    handleSubmit,
    handleCancel,
    isLoading,
    renderOpen,
    loader,
    show,
    ...rest
  } = props;

  const [enabled, setEnabled] = useState(false);
  if (!show) return null;
  if (isLoading) return loader;

  return (
    <>
      <FormModal
        title={modalTitle}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        submitLabel={submitLabel}
        renderOpen={renderOpen}
        disabled={!(props.enabled || enabled)}
      >
        <ModifierForm enabled={enabled} setEnabled={setEnabled} {...rest} />
      </FormModal>
    </>
  );
};

const ProjectModifierControlled = (props) => {
  const {
    isOpen,
    modalTitle,
    submitLabel,
    handleSubmit,
    handleClose,
    handleCancel,
    isLoading,
    renderOpen,
    loader,
    show,
    ...rest
  } = props;

  const [enabled, setEnabled] = useState(false);
  if (!show) return null;
  if (isLoading) return loader;

  return (
    <>
      <FormModalControlled
        title={modalTitle}
        isOpen={isOpen}
        submitLabel={submitLabel}
        onCancel={handleCancel}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disabled={!(props.enabled || enabled)}
      >
        <ModifierForm enabled={enabled} setEnabled={setEnabled} {...rest} />
      </FormModalControlled>
    </>
  );
};

export { ProjectModifierControlled };
export default ProjectModifier;
