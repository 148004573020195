import Link from "../../components/Link";
import useLang from "../../hooks/useLang";
import Section from "../../components/Section";
import { SecondaryParagraph } from "../../components/Paragraph";
import { UnseenIssuesChip, OpenIssuesChip } from "../issues/IssueCounterChip";
import useGroupExcerpt from "./hooks/useGroupExcerpt";
import GroupExcerptTemplate from "./GroupExcerptTemplate";
import StatusChanger from "./StatusChanger";

const GroupExcerpt = ({ group, owner }) => {
  const lang = useLang();
  const { created, modified } = useGroupExcerpt(group);

  return (
    <Section>
      <GroupExcerptTemplate
        groupName={
          <Link to={`${group.id}`}>
            <h2>{group.name ? group.name : lang("no_title")}</h2>
          </Link>
        }
        dateCreated={<SecondaryParagraph>{created}</SecondaryParagraph>}
        dateModified={<SecondaryParagraph>{modified}</SecondaryParagraph>}
        status={
          <>
            <StatusChanger group={group} status={group?.status} />
          </>
        }
        unseenIssues={<UnseenIssuesChip unseen={group?.no_of_unseen} />}
        openIssues={<OpenIssuesChip open={group?.no_of_open_issues} />}
        owner={owner}
      />
    </Section>
  );
};

export default GroupExcerpt;
