import { useParams } from "react-router";
import { useAddNewIssueMutation } from "../issuesSlice";
import useInput from "../../../hooks/useInput";
import { projectsApiSlice } from "../../projects/projectsSlice";
import { useDispatch } from "react-redux";
import useSearchParams from "../../../hooks/useSearchParams";

import useAlerts from "../../alerts/useAlerts";

const useIssueCreator = (target) => {
  const { onError } = useAlerts();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [addNewIssue, { ...rest }] = useAddNewIssueMutation();
  const titleInput = useInput("");
  const addDescriptionInput = useInput(false);
  const descriptionInput = useInput("");
  const { getParam } = useSearchParams();
  const frameId = getParam("frame");

  const resetInputs = () => {
    titleInput.reset();
    addDescriptionInput.reset();
    descriptionInput.reset();
  };

  const handleCreateIssue = async () => {
    let body = {
      title: titleInput.value,
      description: addDescriptionInput ? descriptionInput.value : null,
    };
    if (frameId) {
      body.target = {};
      body.target.type = "FRAME";
      body.target.frame_id = frameId;
    }
    if (target && frameId) {
      body.target = {};
      body.target.type = "MARKER";
      body.target.frame_id = frameId;
      body.target.x = target.x;
      body.target.y = target.y;
    }
    try {
      await addNewIssue({
        params: { id: projectId },
        body,
      }).unwrap();
      resetInputs();
      dispatch(
        projectsApiSlice.util.invalidateTags([
          { type: "Project", id: projectId },
        ])
      );
    } catch (err) {
      onError(err);
    }
  };

  const handleCancel = () => {
    resetInputs();
  };

  return {
    handleCreateIssue,
    handleCancel,
    titleInput,
    addDescriptionInput,
    descriptionInput,
    projectId,
    ...rest,
  };
};

export default useIssueCreator;
