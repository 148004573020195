import styles from "./slideshowAside.module.css";
import Button from "../../components/Button";
import useSlideshowAside from "./hooks/useSlideshowAside";
import classNames from "classnames";
import AsideIssuesPanel from "./AsideIssuesPanel";
import AsideCommentsPanel from "./AsideCommentsPanel";

const SlideshowAside = ({ expanded, onExpand, onCollapse }) => {
  const { issues, showIssues, showComments, closeComments, issue, showAside } =
    useSlideshowAside();

  if (!showAside) return null;
  
  if (!expanded) {
    return (
      <div
        className={classNames(styles.asideControl, {
          [styles.collapsed]: !expanded,
        })}
      >
        <Button rounded important icon={"issues"} onClick={onExpand}>{`${
          issues?.length > 0 ? issues.length : ""
        }`}</Button>
      </div>
    );
  }

  return (
    <div className={styles.slideshowAside}>
      <div className={styles.asideControl}>
        <Button rounded icon={"collapseRight"} onClick={onCollapse} />
      </div>
      {showIssues ? <AsideIssuesPanel issues={issues} /> : null}
      {showComments ? (
        <AsideCommentsPanel issue={issue} goBack={closeComments} />
      ) : null}
    </div>
  );
};

export default SlideshowAside;
