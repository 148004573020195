import classNames from "classnames";
import Label from "./Label";

import styles from "./input.module.css";

const BareInput = ({ children, invalid, ...rest }) => {
  return (
    <input
      className={classNames(styles.input, {
        [styles.checkbox]: rest?.type === "checkbox",
        [styles.invalid]: invalid === true,
      })}
      {...rest}
    >
      {children}
    </input>
  );
};

const InputWithLabel = ({ children, label, labelWidth, ...rest }) => {
  let labelStyle = {};
  if (labelWidth != null) {
    labelStyle.width = `var(--width-${labelWidth})`;
  }
  return (
    <div
      className={classNames(styles.inputWithLabel, {
        [styles.checkboxWithLabel]: rest?.type === "checkbox",
      })}
    >
      <Label
        htmlFor={rest?.name}
        className={styles.inputLabel}
        style={labelStyle}
      >
        {label}
      </Label>
      <BareInput {...rest}>{children}</BareInput>
    </div>
  );
};

const Input = ({ children, label, labelWidth, ...rest }) => {
  if (label)
    return (
      <InputWithLabel label={label} labelWidth={labelWidth} {...rest}>
        {children}
      </InputWithLabel>
    );
  return <BareInput {...rest}>{children}</BareInput>;
};

export default Input;
