import useOriginalSizeDetector from "./useOriginalSizeDetector";

const useImageScale = (imageRef, width) => {
  const { width: naturalWidth, height: naturalHeight } =
    useOriginalSizeDetector(imageRef);
  let scale = width && naturalWidth ? naturalWidth / width : 1;
  return { scale, naturalWidth, naturalHeight };
};

export default useImageScale;
