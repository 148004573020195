import { useState } from "react";
import useSearchParams from "../../../hooks/useSearchParams";
import useFrameIssueId from "../../slideshow/hooks/useFrameIssueId";

const useMarkers = (markers) => {
  const { addParams } = useSearchParams();
  const [highlighted, setHighlighted] = useState();
  const issueId = useFrameIssueId();

  let modifiedMarkers = [];
  if (Array.isArray(markers)) {
    modifiedMarkers = markers?.map((marker) => ({
      nonActive: issueId != null && issueId !== marker.id,
      label:
        issueId === marker.id || highlighted === marker.id
          ? `${marker.iid}: ${marker.title}`
          : marker.iid,
      ...marker,
    }));
  }

  const onOpenIssue = (marker) => {
    addParams({ frameIssue: marker?.id, showIssues: true });
  };

  const setHighlight = (marker) => {
    setHighlighted(marker?.id);
  };

  const unsetHighlight = () => {
    setHighlighted();
  };

  return { modifiedMarkers, onOpenIssue, setHighlight, unsetHighlight };
};

export default useMarkers;
