import useLang from "../../hooks/useLang";
import useSingleIssue from "./hooks/useSingleIssue";
import { PageHeader } from "../../components/Header";
import { PageHeaderButtonGroup, PageHeaderGroup } from "../../components/Group";
import { PageFooter } from "../../components/Footer";
import Section from "../../components/Section";
import CommentCreator from "../comments/CommentCreator";
import ErrorBoundary from "../../components/ErrorBoundary";
import Button from "../../components/Button";
import CommentList from "../comments/CommentList";
import { ConfirmationModal } from "../../components/Modal";
import useScreenSize from "../../hooks/useScreenSize";
import { StatusChip } from "../../components/Chip";
import { CreatedSubheader } from "../../components/Subheader";
import AccessWrapper from "../auth/AccessWrapper";
import FrameListItem from "../visuals/FrameListItem";
import { userActions } from "../../config/accessConfig";
import { PageNotFound } from "../auth/NotFound";

const SingleIssue = () => {
  const lang = useLang();
  const {
    footerRef,
    issue,
    onIssueToggle,
    toggleButtonLabel,
    isLoading,
    isError,
    closeIssueMutationLoading,
    frame,
  } = useSingleIssue();
  const { isSmallScreen } = useScreenSize();

  let renderOpen;
  if (closeIssueMutationLoading) {
    renderOpen = () => <Button disabled>{lang("loading")}</Button>;
  } else {
    renderOpen = (onClick) => (
      <>
        {isSmallScreen ? (
          <Button
            icon={issue?.status === "OPEN" ? "cross" : "issues"}
            onClick={onClick}
          />
        ) : (
          <Button onClick={issue?.status === "OPEN" ? onClick : onIssueToggle}>
            {toggleButtonLabel}
          </Button>
        )}
      </>
    );
  }

  let content;
  if (isLoading) {
    content = <p>{lang("Loading")}</p>;
  } else if (isError) {
    content = <PageNotFound />;
  } else {
    content = (
      <>
        <PageHeader>
          <PageHeaderGroup>
            <h1>{`${issue?.iid}: ${issue?.title}`}</h1>
            <StatusChip status={issue?.status} />
          </PageHeaderGroup>
          <AccessWrapper action={userActions.CLOSE_ISSUE}>
            <PageHeaderButtonGroup>
              <ConfirmationModal
                title={lang("close_issue_confirmation_title")}
                onSubmit={onIssueToggle}
                renderOpen={renderOpen}
              >
                <p>{lang("close_issue_confirmation_text")}</p>
              </ConfirmationModal>
            </PageHeaderButtonGroup>
          </AccessWrapper>
        </PageHeader>
        <CreatedSubheader
          created={issue?.date_created}
          modified={issue?.date_modified}
        />
        <br />
        {issue?.description ? (
          <Section>
            <p>{issue?.description}</p>
          </Section>
        ) : null}
        {frame ? (
          <FrameListItem
            frame={frame}
            issue={issue}
            showVisibilityContol={true}
            showIssues={false}
          />
        ) : null}
        <ErrorBoundary fallback="Error in comments">
          <CommentList />
        </ErrorBoundary>
        {issue?.status !== "CLOSED" ? (
          <PageFooter>
            <ErrorBoundary fallback="Error in create comment modal">
              <CommentCreator
                loader={
                  <Button important loader>
                    {lang("add_comment")}
                  </Button>
                }
                renderOpen={(onClick) => (
                  <Button important onClick={onClick}>
                    {lang("add_comment")}
                  </Button>
                )}
              />
            </ErrorBoundary>
          </PageFooter>
        ) : null}
        <div ref={footerRef} />
      </>
    );
  }

  return <>{content}</>;
};

export default SingleIssue;
