import { useState, useEffect } from "react";
import { useGetPlaceholderMutation } from "../../files/filesSlice";

const usePlaceholders = ({ type, groupId, projectId }) => {
  const [localPlaceholders, setLocalPlaceHolders] = useState([]);
  const [placeholders, setPlaceholders] = useState([]);
  const [result, setResult] = useState([]);
  const [getPlaceholderMutation] = useGetPlaceholderMutation();
  const [isFinished, setIsFinished] = useState(false);

  const getPlaceholder = async (upload) => {
    let body = {
      type,
      group_id: groupId,
      project_id: projectId,
      name: upload.name,
      size: upload.size,
    };
    if (upload.overwrite === true) {
      body.overwrite = true;
    }
    try {
      const response = await getPlaceholderMutation({
        params: { groupId: groupId, projectId: projectId, type: type },
        body,
      }).unwrap();
      setPlaceholders((state) => {
        return [
          ...state,
          {
            binary: upload.binary,
            file_id: upload.file_id,
            ...body,
            prepared: true,
            uploaded: false,
            ...response,
            id: response.placeholder_id || upload.id,
          },
        ];
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getPlaceHolders = (files) => {
    setLocalPlaceHolders([]);
    setPlaceholders([]);
    setIsFinished(false);
    setLocalPlaceHolders(files);
  };

  const reset = () => {
    setLocalPlaceHolders([]);
    setPlaceholders([]);
    setResult([]);
    setIsFinished(false);
  };

  useEffect(() => {
    if (localPlaceholders.length > 0) {
      localPlaceholders?.map((file) => {
        getPlaceholder(file);
        return false;
      });
    }
    // eslint-disable-next-line
  }, [localPlaceholders]);

  useEffect(() => {
    if (
      localPlaceholders?.length > 0 &&
      localPlaceholders?.length === placeholders?.length
    ) {
      setLocalPlaceHolders([]);
      setResult(placeholders);
      setIsFinished(true);
    }
  }, [localPlaceholders, placeholders]);

  return { getPlaceHolders, data: result, reset, isFinished };
};

export default usePlaceholders;
