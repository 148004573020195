import useMarkingMode from "../../marker/hooks/useMarkingMode";

const useSlideActions = () => {
  const { marker, toggleMarker } = useMarkingMode();

  return {
    marker,
    toggleMarker,
  };
};

export default useSlideActions;
