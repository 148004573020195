import { useState } from "react";
import { removeObjectWithKeyFromArray } from "../../../utils/arrayUtils";
import { useAddMemberToGroupMutation } from "../../projectGroups/groupsSlice";
import useAlerts from "../../alerts/useAlerts";
import { useParams } from "react-router-dom";
import validateEmail from "../../../utils/validateEmail";

const availableRoles = ["ADMIN", "CUSTOMER", "SUPPLIER"];
const defaultRole = "CUSTOMER";

const useMemberAdder = () => {
  const { onError } = useAlerts();
  const { groupId } = useParams();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState(defaultRole);
  const [addMember, { isLoading }] = useAddMemberToGroupMutation();
  const [isValid, setIsValid] = useState(false);

  const handleSubmit = async () => {
    const [selectedUser] = selectedUsers.slice(0, 1);
    if (!selectedUser) handleCancel();
    const body = {};
    body.role = selectedRole;
    if (selectedUser.id) {
      body.user_id = selectedUser.id;
    } else if (selectedUser.email) {
      body.user_email = selectedUser.email;
    } else {
      handleCancel();
    }
    try {
      await addMember({
        params: { id: groupId },
        body,
      }).unwrap();
    } catch (err) {
      onError(err);
    }
  };

  const handleCancel = () => {
    setSelectedUsers([]);
    setSelectedRole(defaultRole);
  };

  const handleUserSelect = (selectedUser) => {
    setSelectedUsers([selectedUser]);
    setIsValid((state) => (state === true ? state : true));
  };

  const handleEmailEnter = (enteredEmail) => {
    if (enteredEmail) {
      setSelectedUsers([{ email: enteredEmail }]);
      const validEmail = validateEmail(enteredEmail);
      setIsValid((state) => (state === validEmail ? state : validEmail));
    } else {
      setSelectedUsers([]);
      setIsValid((state) => (state === false ? state : false));
    }
  };

  const handleUserDeselect = (selectedUser) => {
    setSelectedUsers((state) =>
      removeObjectWithKeyFromArray("id", selectedUser.id, state)
    );
    setIsValid((state) => (state === false ? state : false));
  };

  return {
    handleSubmit,
    handleCancel,
    handleUserSelect,
    handleUserDeselect,
    handleEmailEnter,
    selectedRole,
    availableRoles,
    selectedUsers,
    selectRole: setSelectedRole,
    isLoading,
    isValid,
  };
};

export default useMemberAdder;
