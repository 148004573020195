import useSearchParams from "../../../hooks/useSearchParams";
import useLang from "../../../hooks/useLang";
import Icon from "../../../components/Icon";

const statusFilters = ["OFFERS", "ORDERS", "COMPLETED", "CANCELLED", "ON_HOLD"];

const useGroupListHeader = () => {
  const lang = useLang();
  const { getParam, addParams, removeParam } = useSearchParams();
  const sortBy = getParam("sort_by");
  const sortOrder = getParam("sort_order");

  const onSort = (newSortBy) => {
    let newSortOrder = "desc";
    if (newSortBy === sortBy) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }
    addParams({ sort_by: newSortBy, sort_order: newSortOrder });
  };

  const getIcon = (buttonSortBy) => {
    if (buttonSortBy === sortBy) {
      if (sortOrder === "desc") return <Icon descending emphasized />;
      return <Icon ascending emphasized />;
    }
    return <Icon unsorted />;
  };

  const onFilterSelect = (statusFilter) => {
    const currentStatus = getParam("status");
    if (currentStatus !== statusFilter) addParams({ status: statusFilter });
    else removeParam("status");
  };

  const nameAttribs = {
    onClick: () => {
      onSort("name");
    },
    value: lang("item_name"),
    icon: getIcon("name"),
  };

  const createdAttribs = {
    onClick: () => {
      onSort("date_created");
    },
    value: lang("created"),
    icon: getIcon("date_created"),
  };

  const modifiedAttribs = {
    onClick: () => {
      onSort("date_modified");
    },
    value: lang("modified"),
    icon: getIcon("date_modified"),
  };

  return {
    nameAttribs,
    createdAttribs,
    modifiedAttribs,
    statusFilters,
    onFilterSelect,
    activeFilter: getParam("status"),
  };
};

export default useGroupListHeader;
