import { useParams } from "react-router";
import { useAddNewCommentMutation } from "../commentsSlice";
import useInput from "../../../hooks/useInput";
import { issuesApiSlice } from "../../issues/issuesSlice";
import { projectsApiSlice } from "../../projects/projectsSlice";
import { groupsApiSlice } from "../../projectGroups/groupsSlice";
import { useDispatch } from "react-redux";
import useAlerts from "../../alerts/useAlerts";
import useFrameIssueId from "../../slideshow/hooks/useFrameIssueId";

const useCommetCreator = () => {
  const { onError } = useAlerts();
  const dispatch = useDispatch();
  const { issueId, projectId, groupId } = useParams();
  const frameIssueId = useFrameIssueId();
  const [addNewComment, { isLoading }] = useAddNewCommentMutation();
  const commentInput = useInput("");

  const handleAddComment = async () => {
    try {
      const id = issueId ? issueId : frameIssueId;
      await addNewComment({
        params: { id },
        body: {
          text: commentInput.value,
        },
      }).unwrap();
      dispatch(issuesApiSlice.util.invalidateTags([{ type: "Issue", id }]));
      dispatch(
        projectsApiSlice.util.invalidateTags([
          { type: "Project", id: projectId },
        ])
      );
      dispatch(
        groupsApiSlice.util.invalidateTags([{ type: "Group", id: groupId }])
      );
    } catch (err) {
      onError(err);
    }
  };

  const handleCancel = () => {
    commentInput.reset();
  };

  return {
    handleAddComment,
    handleCancel,
    commentInput,
    isLoading,
  };
};

export default useCommetCreator;
