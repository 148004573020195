import { useEffect, useRef } from "react";
import { useMarkAsReadMutation } from "../commentsSlice";
import { issuesApiSlice } from "../../issues/issuesSlice";
import { projectsApiSlice } from "../../projects/projectsSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const MARK_AS_SEEN_TIMEOUT = 2000;

const useSingleComment = (comment) => {
  const isVisibleRef = useRef(false);
  const [markAsSeenMutation] = useMarkAsReadMutation();
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const markAsSeen = async () => {
    try {
      await markAsSeenMutation({
        params: { issueId: comment?.issue_id, messageIdx: comment?.idx },
      }).unwrap();
      dispatch(
        issuesApiSlice.util.invalidateTags([
          { type: "Issue", id: comment?.issue_id },
        ])
      );
      dispatch(
        projectsApiSlice.util.invalidateTags([
          { type: "Project", id: projectId },
        ])
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onVisible = () => {
    isVisibleRef.current = true;
    if (comment?.unseen) {
      setTimeout(() => {
        if (isVisibleRef.current) markAsSeen();
      }, MARK_AS_SEEN_TIMEOUT);
    }
  };

  useEffect(() => {
    return () => {
      isVisibleRef.current = false;
    };
  }, []);

  return { onVisible };
};

export default useSingleComment;
