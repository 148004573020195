import { useParams } from "react-router";
import Button from "../../components/Button";
import Paragraph, { SecondaryParagraph } from "../../components/Paragraph";
import styles from "./fileList.module.css";
import useUsersWithInitials from "../users/hooks/useUsersWithInitials";
import OwnerBadge from "../../components/OwnerBadge";
import useSimpleDate from "../../hooks/useSimpleDate";
import { useState } from "react";
import useLang from "../../hooks/useLang";
import { useRemoveFromProjectMutation } from "../projects/projectsSlice";
import { useRemoveFileFromGroupMutation } from "../projectGroups/groupsSlice";
import { BundleGroup, SplitGroup } from "../../components/Group";
import { ConfirmationModal } from "../../components/Modal";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const apiForTypes = {
  VISUALIZATION: "visualizations",
  TECH_DRAWING: "drawings",
  COMMON_FILE: "common_files",
};

const downloadAction = {
  VISUALIZATION: userActions.DOWNLOAD_VISUALIZATIONS,
  TECH_DRAWING: userActions.DOWNLOAD_DRAWINGS,
  COMMON_FILE: userActions.DOWNLOAD_COMMON_FILES,
};

const removeAction = {
  VISUALIZATION: userActions.DELETE_VISUALIZATIONS,
  TECH_DRAWING: userActions.DELETE_DRAWINGS,
  COMMON_FILE: userActions.DELETE_COMMON_FILES,
};

const FileList = ({ files, type }) => {
  const [loadingList, setLoadingList] = useState([]);
  const { users } = useUsersWithInitials();
  const { getSimpleDate } = useSimpleDate();
  const { groupId, projectId } = useParams();
  const lang = useLang();
  const [removeFileFromProjectMutation] = useRemoveFromProjectMutation();
  const [removeFileFromGroupMutation] = useRemoveFileFromGroupMutation();
  const removeFileMutation =
    type === "COMMON_FILE"
      ? removeFileFromGroupMutation
      : removeFileFromProjectMutation;

  const handleRemove = async (e, file) => {
    if (typeof e?.preventDefault === "function") e.preventDefault();
    try {
      setLoadingList((state) => [...state, file.id]);
      await removeFileMutation({
        groupId,
        projectId,
        fileId: file.id,
        type: apiForTypes[type],
      });
    } catch (err) {
      console.err(err);
    }
  };

  const downloadFile = async (fileUrl, fileName) => {
    fetch(fileUrl, {
      method: "GET",
      headers: {
        "Content-Disposition": `attachment; filename="${encodeURIComponent(
          fileName
        )}"`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("File download failed");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fileItems = Array.isArray(files)
    ? files.map((file) => {
        const owner = users.find((user) => user.id === file.owner_id);
        if (loadingList.indexOf(file.id) >= 0) {
          return (
            <div className={styles.fileListItem} key={file.id}>
              {lang("loading")}
            </div>
          );
        }
        return (
          <div className={styles.fileListItem} key={file.id}>
            <BundleGroup width={72}>
              <Paragraph ellipsisOverflow>{file.name}</Paragraph>
            </BundleGroup>
            <BundleGroup width={72}>
              <SplitGroup>
                <OwnerBadge user={owner} />
              </SplitGroup>
              <BundleGroup width={28}>
                <SecondaryParagraph>
                  {getSimpleDate(file.date_uploaded)}
                </SecondaryParagraph>
              </BundleGroup>
            </BundleGroup>
            <SplitGroup>
              <div />
              <BundleGroup>
                <AccessWrapper action={downloadAction[type]}>
                  <Button
                    inline
                    onClick={() => {
                      downloadFile(file.url, file.name);
                    }}
                    icon={"download"}
                  />
                </AccessWrapper>
                {typeof removeFileMutation === "function" ? (
                  <AccessWrapper action={removeAction[type]}>
                    <ConfirmationModal
                      title={lang("remove_file_confirmation_title")}
                      onSubmit={(e) => {
                        handleRemove(e, file);
                      }}
                      renderOpen={(onClick) => (
                        <Button inline onClick={onClick} icon={"trashIcon"} />
                      )}
                    >
                      <p>
                        {lang("remove_file_name_confirmation_text").replace(
                          "%FILE_NAME%",
                          `"${file.name}"`
                        )}
                      </p>
                    </ConfirmationModal>
                  </AccessWrapper>
                ) : null}
              </BundleGroup>
            </SplitGroup>
          </div>
        );
      })
    : null;

  return (
    <>
      <div className={styles.fileList}>{fileItems}</div>
    </>
  );
};

export default FileList;
