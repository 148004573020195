import useSimpleDate from "../../../hooks/useSimpleDate";

const useGroupExcerpt = (group) => {
  const { getSimpleDate } = useSimpleDate();

  return {
    created: getSimpleDate(group?.date_created),
    modified: getSimpleDate(group?.date_modified),
  };
};

export default useGroupExcerpt;
