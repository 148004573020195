import useFrameIssues from "../../slideshow/hooks/useFrameIssues";
import useRelativeCoords from "./useRelativeCoords";

const useMarkerIssues = (imageSize = {}) => {
  const { issues, ...rest } = useFrameIssues();
  const scale = imageSize?.scale ? imageSize?.scale : 1;
  const { getX, getRightX, getY, isOnRight } = useRelativeCoords(imageSize);

  let markerIssues = [];
  if (Array.isArray(issues)) {
    markerIssues = issues.filter((issue) => issue.target?.type === "MARKER");
  }

  let markerIssuesWithNaturalCoords = [];
  if (Array.isArray(markerIssues)) {
    markerIssuesWithNaturalCoords = markerIssues?.map((issue) => {
      return {
        id: issue.id,
        iid: issue.iid,
        title: issue.title,
        expandLeft: isOnRight(issue.target.x),
        x: getX(issue.target.x),
        rightX: getRightX(issue.target.x),
        y: getY(issue.target.y),
      };
    });
  }

  const addTempMarker = ({ x, y }) => {
    let result = [...markerIssuesWithNaturalCoords];
    result.push({
      id: "new",
      iid: "+",
      x: getX(x),
      rightX: getRightX(x),
      y: getY(y),
    });
    return result;
  };

  return {
    issues: markerIssuesWithNaturalCoords,
    scale,
    addTempMarker,
    ...rest,
  };
};

export default useMarkerIssues;
