import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";

const organizationsAdapter = createEntityAdapter();
const initialState = organizationsAdapter.getInitialState();

export const organizationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: () => `/organizations`,
      transformResponse: (response) => {
        return organizationsAdapter.setAll(initialState, response || []);
      },
      providesTags: (result) =>
        result ? result.ids?.map((id) => ({ type: "Organization", id })) : [],
    }),
  }),
});

export const { useGetOrganizationsQuery } = organizationsApiSlice;

// returns the query result object
export const selectOrganizationsResult =
  organizationsApiSlice.endpoints.getOrganizations.select();

// Creates memoized selector
const selectOrganizationsData = createSelector(
  selectOrganizationsResult,
  (result) => result?.data // normalized state object with ids & entities
);

export const {
  selectAll: selectAllOrganizations,
  selectIds: selectOrganizationIds,
  selectById: selectOrganizationById,
  // Pass in a selector that returns the posts slice of state
} = organizationsAdapter.getSelectors(
  (state) => selectOrganizationsData(state) ?? initialState
);
