import classNames from "classnames";
import useFrameListItem from "./hooks/useFrameListItem";
import ProgressBar from "./ProgressBar";
import {
  TertiaryParagraph,
  SecondaryParagraph,
} from "../../components/Paragraph";
import useLang from "../../hooks/useLang";
import useFrameHider from "./hooks/useFrameHider";
import Input from "../../components/Input";
import { UnseenIssuesChip, OpenIssuesChip } from "../issues/IssueCounterChip";

import styles from "./frameListItem.module.css";

const FrameListItem = ({
  frame,
  issue,
  showHidden,
  showVisibilityContol = true,
  showIssues = true,
}) => {
  const lang = useLang();
  const { showFrame, isLoading: frameHideLoading } = useFrameHider(frame);
  const { onShowSlide, unseenIssues, openIssues, link } = useFrameListItem(
    frame,
    issue
  );

  if (!showHidden && frame.show === false) return null;

  const content = (
    <div
      className={classNames(styles.frameLoResImage, {
        [styles.beingReplaced]: frame.being_replaced || frameHideLoading,
      })}
    >
      <img src={frame?.lores?.url} alt={frame?.original?.name} />
    </div>
  );

  return (
    <div
      className={classNames(styles.frameListItem, {
        [styles.withLink]: !frame.being_replaced && !frame.obsolete,
        [styles.beingReplaced]: frame.being_replaced || frameHideLoading,
      })}
    >
      <a href={link} onClick={onShowSlide}>
        {content}
      </a>
      {frame.being_replaced ? (
        <div className={styles.replacementMessage}>
          <SecondaryParagraph>{lang("being_replaced")}</SecondaryParagraph>
        </div>
      ) : showHidden && showVisibilityContol ? (
        <div className={styles.frameVisibilityMenu}>
          <SecondaryParagraph>{`${lang(
            frame.show ? "visible" : "hidden"
          )}`}</SecondaryParagraph>
          <Input
            type="checkbox"
            checked={frame.show}
            onChange={showFrame}
            disabled={frameHideLoading}
          />
        </div>
      ) : frame.obsolete ? (
        <div className={styles.frameDeletedMessage}>
          <SecondaryParagraph style={{ color: "var(--clr-red-700)" }}>{`${lang(
            "file_deleted"
          )}`}</SecondaryParagraph>
        </div>
      ) : null}
      {showIssues && (openIssues || unseenIssues) ? (
        <div className={styles.frameMenu}>
          <UnseenIssuesChip unseen={unseenIssues} frame={frame} />
          <OpenIssuesChip open={openIssues} frame={frame} />
        </div>
      ) : null}
    </div>
  );
};

const PlaceholderItem = ({ placeholder }) => {
  const fileUploading =
    placeholder.state == null && placeholder.uploaded === false ? true : false;

  const lang = useLang();
  return (
    <div
      className={classNames(styles.placeholderItem, {
        [styles.uploading]: fileUploading,
      })}
    >
      <TertiaryParagraph>{placeholder.name}</TertiaryParagraph>
      {placeholder.progress != null ? (
        <ProgressBar progress={placeholder.progress} />
      ) : null}
      {fileUploading ? (
        <>
          <SecondaryParagraph
            style={{ color: "var(--clr-red-700)", textAlign: "center" }}
          >
            {lang("uploading")}
          </SecondaryParagraph>
          <SecondaryParagraph
            style={{ color: "var(--clr-red-700)", textAlign: "center" }}
          >
            {lang("uploading_do_not_close")}
          </SecondaryParagraph>
        </>
      ) : null}
      {placeholder.prepared == null ? (
        <SecondaryParagraph>{lang("processing")}</SecondaryParagraph>
      ) : null}
    </div>
  );
};

export { PlaceholderItem };
export default FrameListItem;
