import { useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import { CenteredLayout } from "./Layout";
import { useParams } from "react-router-dom";
import useSearchParams from "../hooks/useSearchParams";
import useLang from "../hooks/useLang";
import Avatar from "../features/users/Avatar";
import { BundleGroup } from "./Group";
import { TertiaryParagraph } from "./Paragraph";

import {
  useCheckInvitationMutation,
  useMergeInvitationMutation,
} from "../features/auth/authApiSlice";

import { FormModalControlled } from "./Modal";

const InvitationHandler = () => {
  const lang = useLang();
  const { groupId, projectId } = useParams();
  const { getParam, removeParam } = useSearchParams();
  const [checkInvitationMutation] = useCheckInvitationMutation();
  const [mergeInvitationMutation] = useMergeInvitationMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [response, setResponse] = useState({});
  const [invitationId, setInvitationId] = useState();
  const mergeRef = useRef();

  const checkInvitation = async (invitationId) => {
    try {
      const response = await checkInvitationMutation(invitationId).unwrap();
      if (response.action) {
        if (response.action === "MERGE") {
          setIsOpen(true);
          setResponse(response);
        }
      } else {
        setIsOpen(false);
        setResponse({});
        setInvitationId();
      }
    } catch (err) {
      setIsOpen(false);
      setResponse({});
      setInvitationId();
      console.error(err);
    }
  };

  const mergeInvitation = async (invitationId, code, merge) => {
    try {
      await mergeInvitationMutation({ invitationId, code, merge }).unwrap();
      setInvitationId();
    } catch (err) {
      console.error(err);
      setInvitationId();
    }
  };

  useEffect(() => {
    const invitationId = getParam("invitation_id");
    if (invitationId) {
      removeParam("invitation_id");
      setInvitationId(invitationId);
      if (groupId && !projectId) {
        checkInvitation(invitationId);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    mergeInvitation(invitationId, response?.code, mergeRef.current);
    setIsOpen(false);
  };

  const handleCancel = () => {
    mergeRef.current = false;
    handleClose();
  };

  const handleSubmit = () => {
    mergeRef.current = true;
  };

  return (
    <>
      <FormModalControlled
        isOpen={isOpen}
        cancelLabel={lang("cancel")}
        submitLabel={lang("merge")}
        onCancel={handleCancel}
        onClose={handleClose}
        onSubmit={handleSubmit}
      >
        <h2>Jūs esate prisijungęs kaip:</h2>
        <br />
        <BundleGroup>
          <div style={{ flexShrink: "0" }}>
            <Avatar user={response?.merge?.cognito} />
          </div>
          <BundleGroup vertical alignLeft>
            <h2>{`${response?.merge?.cognito?.given_name} ${response?.merge?.cognito?.family_name} `}</h2>
            <TertiaryParagraph>{`${response?.merge?.cognito?.emails?.join(
              ", "
            )}`}</TertiaryParagraph>
          </BundleGroup>
        </BundleGroup>
        <br />
        <p>
          {`Tačiau kvietimas buvo siųstas į `}
          <u>{`${response?.merge?.invitation?.email}`}</u>
          {`. Ar norite prijungti `}
          <u>{`${response?.merge?.invitation?.email}`}</u>
          {` prie šios paskyros?`}
        </p>
        <br />
        <p>
          {"Jei suklydote prisijungdami arba nenorite prijungti "}
          <u>{`${response?.merge?.invitation?.email}`}</u>
          {` prie paskyros, spauskite "${lang("cancel")}"`}
        </p>
      </FormModalControlled>
      {invitationId ? (
        <CenteredLayout>
          <p>{lang("Loading")}</p>
        </CenteredLayout>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default InvitationHandler;
