import { Routes, Route, Navigate } from "react-router-dom";
import AlertHandler from "./components/alerts/AlertHandler";
import Layout from "./components/Layout";
import Login from "./features/auth/Login";
import MyProfile from "./features/myProfile/MyProfile";
import GroupTabs from "./features/projectGroups/GroupTabs";
import GroupOverview from "./features/projectGroups/GroupOverview";
import ProjectOverview from "./features/projects/ProjectOverview";
import IssuesList from "./features/issues/IssuesList";
import SingleIssue from "./features/issues/SingleIssue";
import OffersOverview from "./features/offers/OffersOverview";
import MembersList from "./features/users/MembersList";
import PersistLogin from "./components/PersistLogin";
import InvitationHandler from "./components/InvitationHandler";
import AuthorizedRoute from "./components/AutorizedRoute";
import Cognito from "./features/auth/Cognito";
import { userActions } from "./config/accessConfig";
import { PageNotFound } from "./features/auth/NotFound";
import NotFoundHandler from "./components/NotFountHandler";

const App = () => {
  return (
    <>
      <Routes>
        <Route element={<AlertHandler />}>
          <Route path="cognito" element={<Cognito />} />
          <Route path="login" element={<Login />} />
          <Route element={<PersistLogin />}>
            <Route element={<InvitationHandler />}>
              <Route index element={<GroupTabs />} />
              <Route element={<Layout />}>
                <Route element={<NotFoundHandler />}>
                  <Route path="profile" element={<MyProfile />} />
                  <Route path=":groupId" element={<GroupOverview />}></Route>
                  <Route path=":groupId/members" element={<MembersList />} />
                  <Route
                    path=":groupId/offers"
                    element={
                      <AuthorizedRoute
                        action={userActions.SHOW_GROUP_OFFERS_PAGE}
                      />
                    }
                  >
                    <Route index element={<OffersOverview />} />
                  </Route>
                  <Route
                    path=":groupId/:projectId/members"
                    element={<MembersList />}
                  />
                  <Route
                    path=":groupId/:projectId/offers"
                    element={
                      <AuthorizedRoute
                        action={userActions.SHOW_PROJECT_OFFERS_PAGE}
                      />
                    }
                  >
                    <Route index element={<OffersOverview />} />
                  </Route>
                  <Route
                    path=":groupId/:projectId/specs"
                    element={<ProjectOverview />}
                  />
                  <Route
                    path=":groupId/:projectId/issues"
                    element={<IssuesList />}
                  />
                  <Route
                    path=":groupId/:projectId/issues/:issueId"
                    element={<SingleIssue />}
                  />
                  <Route
                    path=":groupId/:projectId/:subsection"
                    element={<PageNotFound />}
                  />
                  <Route
                    path=":groupId/:projectId/:subsection/*"
                    element={<PageNotFound />}
                  />
                  <Route
                    path=":groupId/:projectId"
                    element={<Navigate to="specs" replace />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default App;
