import Button from "../../components/Button";
import DragAndDropArea from "../../components/DragAndDropArea";
import useLang from "../../hooks/useLang";
import styles from "./visualsUploader.module.css";
import Icon from "../../components/Icon";
import { SecondaryParagraph } from "../../components/Paragraph";
import { BundleGroup } from "../../components/Group";
import { acceptedFileTypes } from "../../config/acceptedFiles";

const VisualsUploader = ({
  canUpload,
  fileInput,
  handleUploadClick,
  handleDrop,
}) => {
  const lang = useLang();
  if (!canUpload) return null;
  return (
    <div className={styles.visualsUploaderContainer}>
      <DragAndDropArea onDrop={handleDrop} canDrop={canUpload} showOutline>
        <div className={styles.visualsUploader}>
          <BundleGroup>
            <Icon upload />
            <SecondaryParagraph>{` ${lang(
              "drag_files_here"
            )} `}</SecondaryParagraph>
          </BundleGroup>
          <Button inline emphasized onClick={handleUploadClick}>
            {lang("press_upload").toLowerCase()}
          </Button>
          <input
            type="file"
            style={{ display: "none" }}
            accept={acceptedFileTypes.join(", ")}
            {...fileInput}
          />
        </div>
      </DragAndDropArea>
    </div>
  );
};

export default VisualsUploader;
