import { PageHeader } from "../../components/Header";
import { BundleGroup } from "../../components/Group";
import IssueCreator from "../issues/IssueCreator";
import Button from "../../components/Button";
import useLang from "../../hooks/useLang";
import ProjectPatcher from "./ProjectPatcher";
import useIssuesByStatus from "../issues/hooks/useIssuesByStatus";
import IssueCounterChip from "../issues/IssueCounterChip";
import { SplitGroup } from "../../components/Group";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import ProjectSwitch from "./ProjectSwitch";
import ProjectFreezer from "./ProjectFreezer";
import RemoveProjectButton from "./RemoveProjectButton";

const ProjectHeader = ({ project }) => {
  const lang = useLang();
  const { openIssues, unseenIssues } = useIssuesByStatus();

  return (
    <>
      <PageHeader>
        <SplitGroup>
          <ProjectSwitch />
          <BundleGroup>
            <IssueCounterChip
              unseen={unseenIssues?.length}
              open={openIssues?.length}
            />
            <IssueCreator
              renderOpen={(onClick) => (
                <Button icon={"addIssue"} inline emphasized onClick={onClick}>
                  {lang("create_issue")}
                </Button>
              )}
            />
          </BundleGroup>
        </SplitGroup>
        <ProjectFreezer project={project} />
        <RemoveProjectButton project={project} />
      </PageHeader>
      <PageHeader>
        <SplitGroup>
          <h2>{lang("specs")}</h2>
          <AccessWrapper action={userActions.PATCH_PROJECT_SPECS}>
            <BundleGroup>
              <ProjectPatcher
                project={project}
                loader={
                  <Button emphasized loader disabled>
                    {lang("edit_specifications")}
                  </Button>
                }
                renderOpen={(onClick) => (
                  <Button emphasized icon={"pencil"} onClick={onClick}>
                    {lang("edit_specifications")}
                  </Button>
                )}
              />
            </BundleGroup>
          </AccessWrapper>
        </SplitGroup>
      </PageHeader>
    </>
  );
};

export default ProjectHeader;
