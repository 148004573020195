import useSearchParams from "../../../hooks/useSearchParams";

const useFrameIssueId = () => {
  const { getParam } = useSearchParams();
  const frameIssue = getParam("frameIssue");

  return frameIssue;
};

export default useFrameIssueId;
