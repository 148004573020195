import useScreenSize from "../../hooks/useScreenSize";
import SpecsTableBigScreen from "./SpecsTableBigScreen";
import SpecsTableSmallScreen from "./SpecsTableSmallScreen";

const SpecsTable = ({ group }) => {
  const { isSmallScreen } = useScreenSize();
  return (
    <>
      <br />
      <br />
      {isSmallScreen ? (
        <SpecsTableSmallScreen group={group} />
      ) : (
        <SpecsTableBigScreen group={group} />
      )}
    </>
  );
};

export default SpecsTable;
