import useBreadcrumbs from "../features/breadcrumbs/useBreadcrumbs";
import Crumb from "../features/breadcrumbs/Crumb";
import useScreenSize from "../hooks/useScreenSize";

import styles from "./breadcrumbs.module.css";

const Breadcrumbs = () => {
  const crumbsFromHook = useBreadcrumbs();
  const { isSmallScreen, isMobile } = useScreenSize();
  let crumbs;
  if (isSmallScreen) {
    crumbs = crumbsFromHook.slice(-2);
  } else if (isMobile) {
    crumbs = crumbsFromHook.slice(-3);
  } else {
    crumbs = crumbsFromHook;
  }

  return (
    <nav className={styles.breadcrumbs}>
      {crumbs?.map((crumb, index) => (
        <div key={crumb?.id} style={{ display: "flex" }}>
          {index > 0 ? <span className={styles.crumbSpacer}>»</span> : null}
          <Crumb crumb={crumb} />
        </div>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
