import useLang from "../../hooks/useLang";
import { BundleGroup } from "../../components/Group";
import { SecondaryParagraph } from "../../components/Paragraph";

const SpecsList = ({ specs }) => {
  const lang = useLang();

  return (
    <BundleGroup vertical>
      {specs?.map((item, idx) => {
        return (
          <BundleGroup
            key={`${item.k}-${item.v}`}
            style={{
              borderBottom:
                idx < specs.length - 1
                  ? "1px solid var(--clr-neutral-100)"
                  : "unset",
              paddingBottom: "var(--spacing-2)",
              marginBottom:
                idx < specs.length - 1 ? "var(--spacing-1)" : "unset",
              alignItems: "flex-start",
            }}
          >
            <BundleGroup width={36}>
              <SecondaryParagraph>{lang(item.k) || item.k}</SecondaryParagraph>
            </BundleGroup>
            <BundleGroup>
              <pre
                style={{
                  margin: "0",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                {item?.v || ""}
              </pre>
            </BundleGroup>
          </BundleGroup>
        );
      })}
    </BundleGroup>
  );
};

export default SpecsList;
