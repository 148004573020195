const useOffersForProject = (project) => {
  const incommingOffers = project?.offers?.incoming
    ? project?.offers?.incoming
    : [];
  const outgoingOffers = project?.offers?.outgoing
    ? project?.offers?.outgoing
    : [];

  return { incommingOffers, outgoingOffers };
};

export default useOffersForProject;
