import { apiSlice } from "../api/apiAuthenticatedSlice";

export const filesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    placeholder: builder.mutation({
      query: () => ({
        url: "/files/placeholders",
        method: "POST",
      }),
    }),
    getPlaceholder: builder.mutation({
      query: ({ params, body }) => {
        const urlsByType = {
          COMMON_FILE: `/groups/${params.groupId}/common_files/placeholders`,
          TECH_DRAWING: `/projects/${params.projectId}/drawings/placeholders`,
          VISUALIZATION: `/projects/${params.projectId}/visualizations/placeholders`,
        };
        return {
          url: urlsByType[params.type],
          method: "POST",
          body,
        };
      },
    }),
    addNewFile: builder.mutation({
      query: (initialBody) => ({
        url: "/files",
        method: "POST",
        body: initialBody,
      }),
      invalidatesTags: [{ type: "File" }],
    }),
  }),
});

export const {
  usePlaceholderMutation,
  useGetPlaceholderMutation,
  useAddNewFileMutation,
} = filesApiSlice;
