import { useRef } from "react";
import useResizeDetector from "./useResizeDetector";
import useMarkerIssues from "./useMarkerIssues";
import useMarkingMode from "./useMarkingMode";
import useMarkCreator from "./useMarkCreator";
import useTransformer from "./useTransformer";
import useDragger from "./useDragger";

const useMarkable = () => {
  const imageRef = useRef();
  const canvasRef = useRef();
  const { marker: markingMode } = useMarkingMode();
  const imageSize = useResizeDetector(imageRef);
  const canvasSize = useResizeDetector(canvasRef);
  const { issues, addTempMarker } = useMarkerIssues(imageSize);
  const [creatorProps, { isLoading }] = useMarkCreator(imageSize, issues);

  const [dragActions, positionStyle, onCenter] = useDragger(imageRef);
  const { parentTransform, childTransform, onZoomIn, onZoomOut, onZoomToFit } =
    useTransformer(canvasSize, imageSize, onCenter);

  let markers = [...issues];
  if (creatorProps?.isOpen || isLoading) {
    markers = addTempMarker({
      x: creatorProps?.target?.x,
      y: creatorProps?.target?.y,
    });
  }

  const onAddMarker = (event) => {
    if (markingMode) {
      creatorProps?.onOpen(event);
    }
  };

  const onWheel = (event) => {
    const { deltaY } = event;
    if (deltaY > 0) onZoomOut(event);
    if (deltaY < 0) onZoomIn(event);
  };

  return {
    imageRef,
    canvasRef,
    markers,
    onAddMarker,
    creatorProps,
    isLoading,
    markingMode,
    figureStyle: { ...parentTransform, ...positionStyle },
    markerStyle: { ...childTransform },
    dragActions: !markingMode ? { ...dragActions, onWheel } : { onWheel },
    zoomActions: { onZoomIn, onZoomOut, onZoomToFit },
  };
};

export default useMarkable;
