import { PageHeader } from "../../components/Header";
import { PageHeaderGroup, PageHeaderButtonGroup } from "../../components/Group";
import ErrorBoundary from "../../components/ErrorBoundary";
import Button from "../../components/Button";
import useLang from "../../hooks/useLang";
import ProjectCreator from "../projects/ProjectCreator";
import useGroup from "./hooks/useGroup";
import useScreenSize from "../../hooks/useScreenSize";
import StatusChanger from "./StatusChanger";
import VisualsExcerpt from "../visuals/VisualsExcerpt";
import { ProjectNotFound } from "../auth/NotFound";
import SpecsTable from "../projects/SpecsTable";
import GroupSubheader from "./GroupSubheader";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const GroupOverview = () => {
  const lang = useLang();
  const { group, isLoading, isFetching, isSuccess, isError, error } =
    useGroup();
  const { isSmallScreen } = useScreenSize();

  let content;
  if (isLoading) {
    content = <p>{lang("Loading")}</p>;
  } else if (isSuccess) {
    content = [<SpecsTable key="specs" group={{ ...group, isFetching }} />];
    content.push(
      <VisualsExcerpt
        visuals={group?.common_files}
        key={"files"}
        title={lang("files")}
        type="COMMON_FILE"
      />
    );
  } else if (isError && (error?.status === 404 || error?.status === 403)) {
    content = <ProjectNotFound />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderGroup>
          <h1>{group?.name}</h1>
          <StatusChanger
            group={{ ...group, isFetching }}
            status={group?.status}
          />
        </PageHeaderGroup>
        <AccessWrapper action={userActions.CREATE_PROJECT}>
          <PageHeaderButtonGroup>
            <ProjectCreator
              renderOpen={(onClick) => (
                <>
                  {isSmallScreen ? (
                    <Button important icon={"addProject"} onClick={onClick} />
                  ) : (
                    <Button important icon={"addProject"} onClick={onClick}>
                      {lang("add_project")}
                    </Button>
                  )}
                </>
              )}
            />
          </PageHeaderButtonGroup>
        </AccessWrapper>
      </PageHeader>
      <GroupSubheader group={{ ...group, isLoading, isError }} />
      <ErrorBoundary fallback="Error in project specs">{content}</ErrorBoundary>
    </>
  );
};

export default GroupOverview;
