const ALLOW_ALL = "allowAll";

const userActions = {
  GET_GROUP_STATUS: ALLOW_ALL,
  DOWNLOAD_COMMON_FILES: ALLOW_ALL,
  DOWNLOAD_DRAWINGS: ALLOW_ALL,
  DOWNLOAD_VISUALIZATIONS: ALLOW_ALL,
  GET_GROUPS: "urbano_api.groups.id.GET",
  PATCH_COMMON_FILES_VISIBILITY:
    "urbano_api.groups.id.common_files.frames.id.PATCH",
  DELETE_COMMON_FILES: "urbano_api.groups.id.common_files.id.DELETE",
  POST_COMMON_FILES: "urbano_api.groups.id.common_files.placeholders.POST",
  POST_MEMBER: "urbano_api.groups.id.members.POST",
  DELETE_MEMBER: "urbano_api.groups.id.members.id.DELETE",
  CREATE_PROJECT: "urbano_api.groups.id.projects.POST",
  PATCH_GROUP_STATUS: "urbano_api.groups.id.status.PATCH",
  GET_ISSUES: "urbano_api.issues.id.GET",
  CLOSE_ISSUE: "urbano_api.issues.id.PATCH",
  POST_COMMENT: "urbano_api.issues.id.messages.POST",
  GET_ORGANIZATIONS: "urbano_api.organizations.id.GET",
  CREATE_GROUP: "urbano_api.organizations.id.groups.POST",
  CHANGE_ADDRESS: "urbano_api.groups.id.contacts.PATCH",
  DELETE_PROJECT: "urbano_api.projects.id.DELETE",
  FREEZE_PROJECT: "urbano_api.projects.id.PATCH",
  PATCH_DRAWINGS_VISIBILITY: "urbano_api.projects.id.drawings.frames.id.PATCH",
  DELETE_DRAWINGS: "urbano_api.projects.id.drawings.id.DELETE",
  POST_DRAWINGS: "urbano_api.projects.id.drawings.placeholders.POST",
  POST_ISSUE: "urbano_api.projects.id.issues.POST",
  SHOW_GROUP_OFFERS_PAGE: "urbano_api.projects.id.offers.POST",
  SHOW_PROJECT_OFFERS_PAGE: "urbano_api.projects.id.offers.POST",
  POST_OFFER: "urbano_api.projects.id.offers.POST",
  DELETE_OFFER: "urbano_api.projects.id.offers.id.DELETE",
  PATCH_OFFER: "urbano_api.projects.id.offers.id.PATCH",
  PATCH_PROJECT_SPECS: "urbano_api.projects.id.specifications.PATCH",
  PATCH_VISUALIZATIONS_VISIBILITY:
    "urbano_api.projects.id.visualizations.frames.id.PATCH",
  DELETE_VISUALIZATIONS: "urbano_api.projects.id.visualizations.id.DELETE",
  POST_VISUALIZATIONS:
    "urbano_api.projects.id.visualizations.placeholders.POST",
};

const localPolicies = {
  allowAll: ["OWNER", "ADMIN", "SUPPLIER", "CUSTOMER"],
};

export { userActions, localPolicies };
