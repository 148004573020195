import Section from "../../components/Section";
import GroupExcerptTemplate from "./GroupExcerptTemplate";
import useLang from "../../hooks/useLang";
import useGroupListHeader from "./hooks/useGroupListHeader";
import SortButton from "./SortButton";
import Popup from "../../components/Popup";
import { ToggleChip } from "../../components/Chip";
import Icon from "../../components/Icon";

const GroupListHeader = ({ owner }) => {
  const lang = useLang();
  const {
    nameAttribs,
    createdAttribs,
    modifiedAttribs,
    statusFilters,
    onFilterSelect,
    activeFilter,
  } = useGroupListHeader();

  const statusOptions = statusFilters?.map((status) => (
    <ToggleChip
      key={status}
      small
      onClick={(event) => {
        event.preventDefault();
        onFilterSelect(status);
      }}
      active={status === activeFilter}
    >
      {lang(`${status}_plural`)}
    </ToggleChip>
  ));

  return (
    <Section paddingSm filled>
      <GroupExcerptTemplate
        owner={owner}
        isHeader
        groupName={<SortButton {...nameAttribs} />}
        dateCreated={<SortButton {...createdAttribs} />}
        dateModified={<SortButton {...modifiedAttribs} />}
        status={
          <Popup
            renderOpen={(onClick) => (
              <div
                onClick={onClick}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "var(--spacing-2)",
                }}
              >
                {lang("status")}
                {activeFilter ? (
                  <Icon emphasized filter />
                ) : (
                  <Icon filterEmpty />
                )}
              </div>
            )}
          >
            <div
              style={{
                display: "flex",
                gap: "var(--spacing-2)",
                flexDirection: "column",
              }}
            >
              {statusOptions}
            </div>
          </Popup>
        }
        issues={lang("issues")}
        openIssues={lang("open_plural")}
        unseenIssues={lang("unseen_plural")}
      />
    </Section>
  );
};

export default GroupListHeader;
