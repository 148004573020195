import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";

const issuesAdapter = createEntityAdapter();
const initialState = issuesAdapter.getInitialState();

export const issuesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIssueById: builder.query({
      query: (id) => `/issues/${id}`,
      transformResponse: (response) => {
        return issuesAdapter.setAll(initialState, [response] || []);
      },
      providesTags: (result, error, arg) => [{ type: "Issue", id: arg }],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 1,
    }),
    addNewIssue: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/projects/${params?.id}/issues`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Issue" }],
    }),
    closeIssue: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/issues/${params?.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Issue", id: arg?.params?.id },
      ],
    }),
    markIssueAsSeen: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/issues/${params?.issueId}/messages/-1/seen`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Issue", id: arg?.params?.id },
      ],
    }),
  }),
});

export const {
  useGetIssueByIdQuery,
  useAddNewIssueMutation,
  useCloseIssueMutation,
  useMarkIssueAsSeenMutation,
} = issuesApiSlice;
