import { useParams } from "react-router-dom";
import { useGetGroupByIdQuery } from "../groupsSlice";

const useGroup = () => {
  const { groupId } = useParams();
  const { data, ...rest } = useGetGroupByIdQuery(groupId, {
    skip: !groupId,
  });
  const { ids, entities } = data || {};
  let group = ids != null ? entities[ids] : {};
  if (group.id !== groupId) group = {};

  return { group, ...rest };
};

export default useGroup;
