import styles from "./progressBar.module.css";

const ProgressBar = ({ progress }) => {
  const barStyle = { width: `${progress}%` };

  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressBar} style={barStyle} />
    </div>
  );
};

export default ProgressBar;
