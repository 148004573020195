import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";

const paginationHeaders = {
  currentPage: "X-Current-Page",
  nextPage: "X-Next-Page",
  pageSize: "X-Page-Size",
  totalEntries: "X-Total-Entries",
  totalPages: "X-Total-Pages",
};

const getUrlWithParams = (baseUrl, params) => {
  const QUERY_PARAMS = {
    pageNo: "page_no",
    pageSize: "page_size",
    sort: "sort_by",
    status: "status",
  };
  if (!params) return `${baseUrl}?${QUERY_PARAMS.pageNo}=1}`;
  const paramKeys = Object.keys(params);
  const queryParams = paramKeys?.map((paramKey) => {
    let value = params[paramKey];
    if (paramKey === "pageNo" && !value) value = 1;
    if (paramKey === "status" && value && Array.isArray(value)) {
      return value
        .flatMap((item) => `${QUERY_PARAMS[paramKey]}=${item}`)
        .join("&");
    }
    return `${QUERY_PARAMS[paramKey]}=${value}`;
  });
  const result = `${baseUrl}?${queryParams.join("&")}`;
  return result;
};

const getPaginationInfo = (meta) => {
  const paginationObj = {};
  const paginationKeys = Object.keys(paginationHeaders);
  paginationKeys?.map((paginationKey) => {
    const headerValue = meta.response.headers.get(
      paginationHeaders[paginationKey]
    );
    paginationObj[paginationKey] = parseInt(headerValue)
      ? parseInt(headerValue)
      : 0;
    return false;
  });
  return paginationObj;
};

const groupsAdapter = createEntityAdapter();
const initialState = groupsAdapter.getInitialState();

export const groupsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupsByOrganizations: builder.query({
      queryFn: async (ids, _queryApi, _extraOptions, fetchWithBQ) => {
        const promises = ids?.map(async (id) => {
          try {
            const response = await fetchWithBQ(`/organizations/${id}/groups`);
            const pagination = getPaginationInfo(response.meta);
            return {
              data: response.data,
              pagination: { [id]: { ...pagination } },
            };
          } catch (error) {
            console.error(
              `Error fetching groups for organization ${id}:`,
              error
            );
            return null; // or any other appropriate fallback value
          }
        });
        const results = await Promise.all(promises);
        const responses = results.flatMap((result) => {
          const data = groupsAdapter.setAll(initialState, result.data || []);
          return { ...data, pagination: result.pagination };
        });
        return { data: responses };
      },
      providesTags: (result, error, arg) =>
        result
          ? result.flatMap((resultItem) =>
              resultItem?.ids?.map((id) => ({ type: "Group", id }))
            )
          : [],
    }),
    getNextNumber: builder.query({
      query: (organizationId) => {
        return `${
          organizationId === "my" ? "" : "/organizations"
        }/${organizationId}/groups/next`;
      },
      providesTags: [{ type: "GroupNumber" }],
    }),
    getMyGroups: builder.query({
      query: (arg) => getUrlWithParams("/my/groups", arg),
      transformResponse: (response, meta) => {
        const pagination = getPaginationInfo(meta);
        const data = groupsAdapter.setAll(initialState, response || []);
        return { ...data, pagination: { my: { ...pagination } } };
      },
      providesTags: (result, error, arg) =>
        result ? result?.ids?.map((id) => ({ type: "Group", id })) : [],
    }),
    getSharedGroups: builder.query({
      query: (arg) => getUrlWithParams("/shared/groups", arg),
      transformResponse: (response, meta) => {
        const pagination = getPaginationInfo(meta);
        const data = groupsAdapter.setAll(initialState, response || []);
        return { ...data, pagination: { shared: { ...pagination } } };
      },
      providesTags: (result, error, arg) =>
        result ? result?.ids?.map((id) => ({ type: "Group", id })) : [],
    }),
    getOrganizationGroups: builder.query({
      query: ([id, params]) =>
        getUrlWithParams(`/organizations/${id}/groups`, params),
      transformResponse: (response, meta, arg) => {
        const pagination = getPaginationInfo(meta);
        const data = groupsAdapter.setAll(initialState, response || []);
        return { ...data, pagination: { [arg[0]]: { ...pagination } } };
      },
      providesTags: (result, error, arg) =>
        result ? result?.ids?.map((id) => ({ type: "Group", id })) : [],
    }),
    getGroupById: builder.query({
      query: (id) => `/groups/${id}`,
      transformResponse: (response) => {
        return groupsAdapter.setAll(initialState, [response] || []);
      },
      providesTags: (result, error, arg) => [{ type: "Group", id: arg }],
    }),
    addNewGroup: builder.mutation({
      query: ({ params, body }) => {
        const result = {
          url: `${
            params?.organizationId && params?.organizationId !== "my"
              ? "/organizations/" + params?.organizationId
              : "/my"
          }/groups`,
          method: "POST",
          body: body,
        };
        return result;
      },
      invalidatesTags: [{ type: "Group" }, { type: "GroupNumber" }],
    }),
    changeContacts: builder.mutation({
      query: ({ params, body }) => ({
        url: `groups/${params?.id}/contacts`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Group", id: arg?.params?.id },
      ],
    }),
    addMemberToGroup: builder.mutation({
      query: ({ params, body }) => ({
        url: `groups/${params?.id}/members`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Group", id: arg?.params?.id },
      ],
    }),
    removeMemberFromGroup: builder.mutation({
      query: ({ params }) => ({
        url: `groups/${params?.id}/members/${params?.memberId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Group", id: arg?.params?.id },
      ],
    }),
    removeFileFromGroup: builder.mutation({
      query: ({ groupId, type, fileId }) => ({
        url: `groups/${groupId}/${type}/${fileId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Group", id: arg?.groupId },
      ],
    }),
    hideFrameInGroup: builder.mutation({
      query: ({ groupId, type, frameId, body }) => ({
        url: `groups/${groupId}/${type}/frames/${frameId}`,
        body,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Group", id: arg?.groupId },
      ],
    }),
    changeStatus: builder.mutation({
      query: ({ params, body }) => ({
        url: `groups/${params?.id}/status`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Group", id: arg?.params?.id },
      ],
    }),
  }),
});

export const {
  useGetMyGroupsQuery,
  useGetSharedGroupsQuery,
  useGetOrganizationGroupsQuery,
  useGetGroupsByOrganizationsQuery,
  useGetNextNumberQuery,
  useGetGroupByIdQuery,
  useAddNewGroupMutation,
  useAddMemberToGroupMutation,
  useRemoveMemberFromGroupMutation,
  useRemoveFileFromGroupMutation,
  useHideFrameInGroupMutation,
  useChangeStatusMutation,
  useChangeContactsMutation,
} = groupsApiSlice;
