import Section from "./Section";
import styles from "./selectCard.module.css";

const SelectCard = ({ children, onSelect }) => {
  return (
    <div className={styles.selectCard} onClick={onSelect}>
      <Section boxed>{children}</Section>
    </div>
  );
};

export default SelectCard;
