import { useRef, useState } from "react";

const CENTER_X = 50;
const CENTER_Y = 50;

const useDragger = (imageRef) => {
  const draggingRef = useRef(false);
  const centerXRef = useRef(CENTER_X);
  const centerYRef = useRef(CENTER_Y);
  const startLeftRef = useRef();
  const startTopRef = useRef();
  const leftRef = useRef();
  const topRef = useRef();
  const [positionStyle, setPositionStyle] = useState({
    left: `${centerXRef.current}%`,
    top: `${centerYRef.current}%`,
  });

  const preventDefault = (event) => {
    if (event.cancelable) {
      event.preventDefault();
    }
    event.stopPropagation();
  };

  const getClientCoords = (event) => {
    return event.touches
      ? {
          clientX: event.touches[0].clientX,
          clientY: event.touches[0].clientY,
        }
      : event;
  };

  const onDragStart = (event) => {
    preventDefault(event);
    if (imageRef.current && event.target === imageRef.current) {
      const { clientX, clientY } = getClientCoords(event);
      const canvasRect = event.currentTarget.getBoundingClientRect();
      startLeftRef.current =
        ((clientX - canvasRect.left) / canvasRect.width) * 100;
      startTopRef.current =
        ((clientY - canvasRect.top) / canvasRect.height) * 100;
      draggingRef.current = true;
      leftRef.current = startLeftRef.current;
      topRef.current = startTopRef.current;
    }
  };

  const onDragFinish = (event) => {
    preventDefault(event);
    if (draggingRef.current) {
      draggingRef.current = false;
      const distanceLeft = leftRef.current - startLeftRef.current;
      const distanceTop = topRef.current - startTopRef.current;
      centerXRef.current = centerXRef.current + distanceLeft;
      centerYRef.current = centerYRef.current + distanceTop;
    }
  };

  const onDrag = (event) => {
    preventDefault(event);
    if (draggingRef.current) {
      const { clientX, clientY } = getClientCoords(event);
      const canvasRect = event.currentTarget.getBoundingClientRect();
      leftRef.current = ((clientX - canvasRect.left) / canvasRect.width) * 100;
      topRef.current = ((clientY - canvasRect.top) / canvasRect.height) * 100;
      const distanceLeft = leftRef.current - startLeftRef.current;
      const distanceTop = topRef.current - startTopRef.current;
      setPositionStyle({
        left: `${distanceLeft + centerXRef.current}%`,
        top: `${distanceTop + centerYRef.current}%`,
      });
    }
  };

  const onCenter = () => {
    centerXRef.current = CENTER_X;
    centerYRef.current = CENTER_Y;
    setPositionStyle({
      left: `${centerXRef.current}%`,
      top: `${centerYRef.current}%`,
    });
  };

  return [
    {
      onMouseDown: onDragStart,
      onTouchStart: onDragStart,
      onMouseUp: onDragFinish,
      onTouchEnd: onDragFinish,
      onMouseMove: onDrag,
      onTouchMove: onDrag,
      onMouseLeave: onDragFinish,
    },
    positionStyle,
    onCenter,
  ];
};

export default useDragger;
