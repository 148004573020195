import useLang from "../../hooks/useLang";
import { FormModal } from "../../components/Modal";
import Form from "../../components/Form";
import usePriceProvider from "./hooks/usePriceProvider";
import Input from "../../components/Input";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const PriceProvider = ({ project, renderOpen, onClose }) => {
  const lang = useLang();
  const { handleSubmit, handleCancel, amountInput, commentInput, isLoading } =
    usePriceProvider(project, onClose);

  if (isLoading) return <p>{lang("loading")}</p>;
  return (
    <>
      <AccessWrapper action={userActions.POST_OFFER} project={project}>
        <FormModal
          title={lang("add_offer_title")}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          submitLabel={lang("add_offer")}
          renderOpen={renderOpen}
        >
          <Form modal>
            <Input
              label={lang("price_vat_excluded")}
              {...amountInput.attributes}
            />
            <Input label={lang("comment")} {...commentInput.attributes} />
          </Form>
        </FormModal>
      </AccessWrapper>
    </>
  );
};

export default PriceProvider;
