import classNames from "classnames";
import styles from "./icon.module.css";

const svgSize = 64;

const iconNames = {
  CHEVRON_RIGHT: "chevronRight",
  CHEVRON_LEFT: "chevronLeft",
  CHEVRON_DOWN: "chevronDown",
  CHEVRON_UP: "chevronUp",
  ARROWHEAD_RIGHT: "arrowheadRight",
  ARROWHEAD_LEFT: "arrowheadLeft",
  ARROWHEAD_DOWN: "arrowheadDown",
  ARROWHEAD_UP: "arrowheadUp",
  ARROW_RIGHT: "arrowRight",
  ARROW_LEFT: "arrowLeft",
  ARROW_RETURN_UP: "arrowReturnUp",
  REFRESH: "refresh",
  PLUS: "plus",
  MINUS: "minus",
  CROSS: "cross",
  CHECK: "check",
  PLAY: "play",
  PAUSE: "pause",
  BURGER: "burger",
  OPTIONS: "options",
  TRASH: "trashIcon",
  PENCIL: "pencil",
  COG: "cog",
  IMAGE: "image",
  IMAGES: "images",
  NOTE: "note",
  NOTES: "notes",
  COMMENT: "comment",
  CHAT: "chat",
  LAB_FLASK: "labFlask",
  WIZZARD: "wizzard",
  FOLDER: "folder",
  FOLDER_OPEN: "folderOpen",
  DESCRIPTION: "description",
  CAR: "car",
  HOME: "home",
  EVENT: "event",
  FILTER: "filter",
  FILTER_EMPTY: "filterEmpty",
  EXCLAMATION: "exclamation",
  EURO: "euro",
  COLLAPSE_LEFT: "collapseLeft",
  COLLAPSE_RIGHT: "collapseRight",
  REFUND: "refund",
  RESCHEDULE: "reschedule",
  ISSUES: "issues",
  UNSEEN_ISSUES: "unseenIssues",
  ADD_ISSUE: "addIssue",
  MEMBERS: "members",
  MARKER: "marker",
  LEAVE: "leave",
  ADD_PROJECT: "addProject",
  ADD_MEMBER: "addMember",
  FIT_TO_SCREEN: "fitToScreen",
  OFFER: "offer",
  ADD_OFFER: "addOffer",
  ASCENDING: "ascending",
  DESCENDING: "descending",
  UNSORTED: "unsorted",
  DOWNLOAD: "download",
  UPLOAD: "upload",
  HIDE: "hide",
  SHOW: "show",
  FREEZE: "freeze",
  UNFREEZE: "unfreeze",
};

const iconPaths = {
  [iconNames.CHEVRON_RIGHT]:
    "M21.172 49.172a4 4 0 1 0 5.656 5.656l20-20a4 4 0 0 0 0-5.656l-20-20a4 4 0 1 0-5.656 5.656L38.343 32 21.172 49.172Z",
  [iconNames.CHEVRON_LEFT]:
    "M42.828 14.828a4 4 0 1 0-5.656-5.656l-20 20a4 4 0 0 0 0 5.656l20 20a4 4 0 1 0 5.656-5.656L25.657 32l17.171-17.172Z",
  [iconNames.CHEVRON_DOWN]:
    "M9.172 21.172a4 4 0 0 1 5.656 0L32 38.343l17.172-17.171a4 4 0 1 1 5.656 5.656l-20 20a4 4 0 0 1-5.656 0l-20-20a4 4 0 0 1 0-5.656Z",
  [iconNames.CHEVRON_UP]:
    "M9.172 42.828a4 4 0 0 0 5.656 0L32 25.657l17.172 17.171a4 4 0 1 0 5.656-5.656l-20-20a4 4 0 0 0-5.656 0l-20 20a4 4 0 0 0 0 5.656Z",
  [iconNames.ARROWHEAD_RIGHT]: "M24 48L40 32L24 16V48Z",
  [iconNames.ARROWHEAD_LEFT]: "M40 48L24 32L40 16V48Z",
  [iconNames.ARROWHEAD_DOWN]: "M16 24H48L32 40L16 24Z",
  [iconNames.ARROWHEAD_UP]: "M16 40H48L32 24L16 40Z",
  [iconNames.ARROW_RIGHT]:
    "M39.0294 7.71576C37.4673 6.15366 34.9347 6.15367 33.3726 7.71576C31.8105 9.27786 31.8105 11.8105 33.3726 13.3726L48 28H4V36H48L33.3726 50.6274C31.8105 52.1895 31.8105 54.7222 33.3726 56.2843C34.9347 57.8464 37.4673 57.8464 39.0294 56.2843L57.6569 37.6569C60.781 34.5327 60.781 29.4674 57.6569 26.3432L39.0294 7.71576Z",
  [iconNames.ARROW_LEFT]:
    "M24.9706 7.71576C26.5327 6.15366 29.0653 6.15367 30.6274 7.71576C32.1895 9.27786 32.1895 11.8105 30.6274 13.3726L16 28H60V36H16L30.6274 50.6274C32.1895 52.1895 32.1895 54.7222 30.6274 56.2843C29.0653 57.8464 26.5327 57.8464 24.9706 56.2843L6.34315 37.6569C3.21895 34.5327 3.21895 29.4674 6.34315 26.3432L24.9706 7.71576Z",
  [iconNames.ARROW_RETURN_UP]:
    "M7.71576 26.6274C6.15366 25.0653 6.15366 22.5327 7.71576 20.9706L22.3432 6.34315C25.4674 3.21895 30.5327 3.21895 33.6569 6.34315L48.2843 20.9706C49.8464 22.5327 49.8464 25.0653 48.2843 26.6274C46.7222 28.1895 44.1895 28.1895 42.6274 26.6274L32 16V48H60V56H32C27.5817 56 24 52.4183 24 48V16L13.3726 26.6274C11.8105 28.1895 9.27786 28.1895 7.71576 26.6274Z",
  [iconNames.REFRESH]:
    "M32 56C39.29 56 45.8209 52.7497 50.2225 47.6193L44.1483 42.4128C41.2139 45.8332 36.86 48 32 48C23.1634 48 16 40.8366 16 32C16 23.1634 23.1634 16 32 16C37.9223 16 43.093 19.2176 45.8595 24H40C37.7909 24 36 25.7909 36 28C36 30.2091 37.7909 32 40 32H52H56C58.2091 32 60 30.2091 60 28V24V12C60 9.79086 58.2091 8 56 8C53.7909 8 52 9.79086 52 12V18.7288C47.7003 12.262 40.3479 8 32 8C18.7452 8 8 18.7452 8 32C8 45.2548 18.7452 56 32 56Z",
  [iconNames.PLUS]:
    "M28 56C28 58.2091 29.7909 60 32 60C34.2091 60 36 58.2091 36 56V36H56C58.2091 36 60 34.2091 60 32C60 29.7909 58.2091 28 56 28H36V8C36 5.79086 34.2091 4 32 4C29.7909 4 28 5.79086 28 8V28H8C5.79086 28 4 29.7909 4 32C4 34.2091 5.79086 36 8 36H28V56Z",
  [iconNames.MINUS]:
    "M4 32C4 29.7909 5.79086 28 8 28H56C58.2091 28 60 29.7909 60 32C60 34.2091 58.2091 36 56 36H8C5.79086 36 4 34.2091 4 32Z",
  [iconNames.CROSS]:
    "M54.8284 9.17157C56.3905 10.7337 56.3905 13.2663 54.8284 14.8284L37.6569 32L54.8284 49.1716C56.3905 50.7337 56.3905 53.2663 54.8284 54.8284C53.2663 56.3905 50.7337 56.3905 49.1716 54.8284L32 37.6569L14.8284 54.8284C13.2663 56.3905 10.7337 56.3905 9.17157 54.8284C7.60948 53.2663 7.60948 50.7337 9.17157 49.1716L26.3431 32L9.17157 14.8284C7.60948 13.2663 7.60948 10.7337 9.17157 9.17158C10.7337 7.60948 13.2663 7.60948 14.8284 9.17158L32 26.3431L49.1716 9.17157C50.7337 7.60948 53.2663 7.60948 54.8284 9.17157Z",
  [iconNames.CHECK]:
    "M11.1716 29.1716C12.7337 27.6095 15.2663 27.6095 16.8284 29.1716L26 38.3431L47.1716 17.1716C48.7337 15.6095 51.2663 15.6095 52.8284 17.1716C54.3905 18.7337 54.3905 21.2663 52.8284 22.8284L28.8284 46.8284C27.2663 48.3905 24.7337 48.3905 23.1716 46.8284L11.1716 34.8284C9.60948 33.2663 9.60948 30.7337 11.1716 29.1716Z",
  [iconNames.PLAY]: "M16 12L52 32L16 52V12Z",
  [iconNames.PAUSE]: "M28 12H16V52H28V12ZM48 12H36V52H48V12Z",
  [iconNames.BURGER]:
    "M8 32C8 29.7909 9.79086 28 12 28H52C54.2091 28 56 29.7909 56 32C56 34.2091 54.2091 36 52 36H12C9.79086 36 8 34.2091 8 32Z M8 16C8 13.7909 9.79086 12 12 12H52C54.2091 12 56 13.7909 56 16C56 18.2091 54.2091 20 52 20H12C9.79086 20 8 18.2091 8 16Z M8 48C8 45.7909 9.79086 44 12 44H52C54.2091 44 56 45.7909 56 48C56 50.2091 54.2091 52 52 52H12C9.79086 52 8 50.2091 8 48Z",
  [iconNames.OPTIONS]:
    "M38 10C38 13.3137 35.3137 16 32 16C28.6863 16 26 13.3137 26 10C26 6.68629 28.6863 4 32 4C35.3137 4 38 6.68629 38 10Z M38 32C38 35.3137 35.3137 38 32 38C28.6863 38 26 35.3137 26 32C26 28.6863 28.6863 26 32 26C35.3137 26 38 28.6863 38 32Z M32 60C35.3137 60 38 57.3137 38 54C38 50.6863 35.3137 48 32 48C28.6863 48 26 50.6863 26 54C26 57.3137 28.6863 60 32 60Z",
  [iconNames.TRASH]:
    "M22 20C20.8954 20 20 20.8954 20 22V50C20 51.1046 20.8954 52 22 52C23.1046 52 24 51.1046 24 50V22C24 20.8954 23.1046 20 22 20Z M30 22C30 20.8954 30.8954 20 32 20C33.1046 20 34 20.8954 34 22V50C34 51.1046 33.1046 52 32 52C30.8954 52 30 51.1046 30 50V22Z M42 20C40.8954 20 40 20.8954 40 22V50C40 51.1046 40.8954 52 42 52C43.1046 52 44 51.1046 44 50V22C44 20.8954 43.1046 20 42 20Z M32 4H28C25.7909 4 24 5.79086 24 8V12H10C8.89543 12 8 12.8954 8 14C8 15.1046 8.89543 16 10 16H12V52C12 56.4183 15.5817 60 20 60H44C48.4183 60 52 56.4183 52 52V16H54C55.1046 16 56 15.1046 56 14C56 12.8954 55.1046 12 54 12H40V8C40 5.79086 38.2091 4 36 4H32V8L36 8V12H28V8L32 8V4ZM16 52V16H48V52C48 54.2091 46.2091 56 44 56H20C17.7909 56 16 54.2091 16 52Z",
  [iconNames.PENCIL]:
    "M54 21.9999L28 47.9999L8 56L16 36L42 9.99993C45.3137 6.68622 50.6863 6.68623 54 9.99993C57.3137 13.3136 57.3137 18.6862 54 21.9999ZM19.0773 39.0772L15.1802 48.8198L24.9228 44.9227L19.0773 39.0772ZM51.1716 19.1715C52.9232 17.4199 52.9232 14.58 51.1716 12.8284C49.42 11.0767 46.58 11.0767 44.8284 12.8284L21.6569 35.9999L28 42.3431L51.1716 19.1715Z",
  [iconNames.COG]:
    "M27.985 6A2 2 0 0 0 26 7.752l-.955 7.635a17.998 17.998 0 0 0-3.976 2.299l-7.092-2.993a2 2 0 0 0-2.51.843l-4 6.928a2 2 0 0 0 .525 2.595l6.137 4.645a18.224 18.224 0 0 0 0 4.592L7.993 38.94a2 2 0 0 0-.525 2.595l4 6.928a2 2 0 0 0 2.51.843l7.092-2.992a17.998 17.998 0 0 0 3.976 2.298l.955 7.635A2 2 0 0 0 27.985 58h8a2 2 0 0 0 1.985-1.752l.954-7.635a17.977 17.977 0 0 0 3.976-2.298l7.092 2.992a2 2 0 0 0 2.51-.843l4-6.928a2 2 0 0 0-.525-2.595l-6.137-4.645a18.195 18.195 0 0 0 0-4.592l6.137-4.645a2 2 0 0 0 .525-2.595l-4-6.928a2 2 0 0 0-2.51-.843L42.9 17.686a17.977 17.977 0 0 0-3.976-2.3l-.954-7.634A2 2 0 0 0 35.985 6h-8ZM40 32.03v-.06a8 8 0 1 0 0 .06Z",
  [iconNames.IMAGE]:
    "M48 8H16a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8V16a8 8 0 0 0-8-8ZM16 4C9.373 4 4 9.373 4 16v32c0 6.627 5.373 12 12 12h32c6.627 0 12-5.373 12-12V16c0-6.627-5.373-12-12-12H16Zm32 20a8 8 0 1 1-16 0 8 8 0 0 1 16 0ZM34.5 45 24 24 12 48h40L42 36l-7.5 9Z",
  [iconNames.IMAGES]:
    "M46 36C49.3137 36 52 33.3137 52 30C52 26.6863 49.3137 24 46 24C42.6863 24 40 26.6863 40 30C40 33.3137 42.6863 36 46 36ZM34 32L42.1818 48.3636L46 42L52 52H44H40H24L34 32Z M10.6785 9.41099C6.32737 10.1782 3.42203 14.3275 4.18925 18.6786L9.0514 46.2533C9.40849 48.2784 10.4982 49.9904 12 51.1647V39.9404L8.12848 17.984C7.74487 15.8085 9.19754 13.7338 11.3731 13.3502L12.1615 13.2112C12.4676 12.4989 13.1755 12 14 12H19.0307L38.9477 8.48807C41.1233 8.10446 43.1979 9.55713 43.5816 11.7327L43.6287 12H47.6904L47.5208 11.0381C46.7536 6.68696 42.6043 3.78161 38.2531 4.54884L10.6785 9.41099Z M16 24C16 19.5817 19.5817 16 24 16H52C56.4183 16 60 19.5817 60 24V52C60 56.4183 56.4183 60 52 60H24C19.5817 60 16 56.4183 16 52V24ZM24 20H52C54.2091 20 56 21.7909 56 24V52C56 54.2091 54.2091 56 52 56H24C21.7909 56 20 54.2091 20 52V24C20 21.7909 21.7909 20 24 20Z",
  [iconNames.IMAGES]:
    "M46 36C49.3137 36 52 33.3137 52 30C52 26.6863 49.3137 24 46 24C42.6863 24 40 26.6863 40 30C40 33.3137 42.6863 36 46 36ZM34 32L42.1818 48.3636L46 42L52 52H44H40H24L34 32Z M10.6785 9.41099C6.32737 10.1782 3.42203 14.3275 4.18925 18.6786L9.0514 46.2533C9.40849 48.2784 10.4982 49.9904 12 51.1647V39.9404L8.12848 17.984C7.74487 15.8085 9.19754 13.7338 11.3731 13.3502L12.1615 13.2112C12.4676 12.4989 13.1755 12 14 12H19.0307L38.9477 8.48807C41.1233 8.10446 43.1979 9.55713 43.5816 11.7327L43.6287 12H47.6904L47.5208 11.0381C46.7536 6.68696 42.6043 3.78161 38.2531 4.54884L10.6785 9.41099Z M16 24C16 19.5817 19.5817 16 24 16H52C56.4183 16 60 19.5817 60 24V52C60 56.4183 56.4183 60 52 60H24C19.5817 60 16 56.4183 16 52V24ZM24 20H52C54.2091 20 56 21.7909 56 24V52C56 54.2091 54.2091 56 52 56H24C21.7909 56 20 54.2091 20 52V24C20 21.7909 21.7909 20 24 20Z",
  [iconNames.NOTE]:
    "M38.5221 17.5703C35.978 15.0759 35.3361 11.0886 37.2 7.86033C39.4091 4.03399 44.3018 2.72299 48.1282 4.93213C51.9545 7.14127 53.2655 12.034 51.0564 15.8603C49.1925 19.0886 45.4184 20.5264 41.9862 19.5703L36.3602 29.3148C36.0841 29.7931 35.4725 29.957 34.9942 29.6808L33.2621 28.6808C32.7838 28.4047 32.62 27.7931 32.8961 27.3148L38.5221 17.5703ZM42.1246 15.3224C40.2142 14.2169 39.5602 11.7723 40.6641 9.86033C41.7686 7.94716 44.215 7.29166 46.1282 8.39623C48.0413 9.5008 48.6968 11.9472 47.5923 13.8603C46.4884 15.7723 44.0443 16.4282 42.1317 15.3265L42.1246 15.3224Z M32.1271 12H16C11.5817 12 8 15.5817 8 20V52C8 56.4183 11.5817 60 16 60H48C52.4183 60 56 56.4183 56 52V20C56 18.7451 55.7111 17.5576 55.1961 16.5006C55.0021 16.9616 54.7771 17.4157 54.5205 17.8602C53.8354 19.0468 52.9787 20.0718 52 20.9221V52C52 54.2091 50.2091 56 48 56H16C13.7909 56 12 54.2091 12 52V20C12 17.7909 13.7909 16 16 16H32.8654C32.3961 14.7221 32.1445 13.3685 32.1271 12Z",
  [iconNames.NOTES]:
    "M42.5221 17.5703C39.978 15.0759 39.3361 11.0886 41.2 7.86033C43.4091 4.03399 48.3018 2.72299 52.1282 4.93213C55.9545 7.14127 57.2655 12.034 55.0564 15.8603C53.1925 19.0886 49.4184 20.5264 45.9862 19.5703L40.3602 29.3148C40.0841 29.7931 39.4725 29.957 38.9942 29.6808L37.2621 28.6808C36.7838 28.4047 36.62 27.7931 36.8961 27.3148L42.5221 17.5703ZM46.1245 15.3223C44.2142 14.2168 43.5602 11.7723 44.664 9.86033C45.7686 7.94716 48.215 7.29166 50.1282 8.39623C52.0413 9.5008 52.6968 11.9472 51.5923 13.8603C50.4884 15.7723 48.0444 16.4282 46.1318 15.3265L46.1245 15.3223Z M36.8654 16H24C19.5817 16 16 19.5817 16 24V52C16 56.4183 19.5817 60 24 60H52C56.4183 60 60 56.4183 60 52V24C60 21.966 59.2409 20.1092 57.9905 18.6976C57.2041 19.8348 56.2494 20.7981 55.1801 21.5734C55.6945 22.2464 56 23.0875 56 24V52C56 54.2091 54.2091 56 52 56H24C21.7909 56 20 54.2091 20 52V24C20 21.7909 21.7909 20 24 20H36.5005L37.7354 17.861C37.3919 17.265 37.1012 16.6421 36.8654 16ZM36.2636 13.6597L17.5581 10.3614C15.3825 9.97781 13.3079 11.4305 12.9243 13.6061L12.8171 14.2136C12.2839 14.5728 11.9331 15.1822 11.9331 15.8735V19.2272L8.06211 41.1807C7.67849 43.3563 9.13117 45.4309 11.3067 45.8145L11.9331 45.9249V49.9867L10.6122 49.7537C6.261 48.9865 3.35565 44.8372 4.12288 40.4861L8.98502 12.9115C9.75225 8.56031 13.9015 5.65497 18.2527 6.42219L36.3373 9.611C36.0799 10.9601 36.0607 12.3293 36.2636 13.6597Z",
  [iconNames.COMMENT]:
    "M20 60L36 44H48C54.6274 44 60 38.6274 60 32V20C60 13.3726 54.6274 8 48 8H16C9.37256 8 4 13.3726 4 20V32C4 38.6274 9.37256 44 16 44H20V60ZM24 40H16C11.5818 40 8 36.4182 8 32V20C8 15.5818 11.5818 12 16 12H48C52.4182 12 56 15.5818 56 20V32C56 36.4182 52.4182 40 48 40H34.3433L24 50.3433V40Z",
  [iconNames.CHAT]:
    "M16 28V34.3431L20 30.3431V36L12 44L12 32C7.58172 32 4 28.4183 4 24V12C4 7.58172 7.58172 4 12 4H32C36.4183 4 40 7.58172 40 12V16H36V12C36 9.79086 34.2091 8 32 8H12C9.79086 8 8 9.79086 8 12V24C8 26.2091 9.79086 28 12 28H16Z M52 60V48C56.4183 48 60 44.4183 60 40V28C60 23.5817 56.4183 20 52 20H32C27.5817 20 24 23.5817 24 28V40C24 44.4183 27.5817 48 32 48H40L52 60ZM41.6569 44H32C29.7909 44 28 42.2091 28 40V28C28 25.7909 29.7909 24 32 24H52C54.2091 24 56 25.7909 56 28V40C56 42.2091 54.2091 44 52 44H48V50.3431L41.6569 44Z",
  [iconNames.LAB_FLASK]:
    "M8 52.9296C8 56.8345 11.1655 60 15.0704 60H48.9296C52.8345 60 56 56.8345 56 52.9296C56 51.5338 55.5868 50.1691 54.8125 49.0077L40 26.7889V16V15.6586C42.3304 14.8349 44 12.6124 44 10C44 6.68629 41.3137 4 38 4H26C22.6863 4 20 6.68629 20 10C20 12.6124 21.6696 14.8349 24 15.6586V16V26.7889L9.18747 49.0077C8.41318 50.1691 8 51.5338 8 52.9296ZM38 12H36H28H26C24.8954 12 24 11.1046 24 10C24 8.89543 24.8954 8 26 8H38C39.1046 8 40 8.89543 40 10C40 11.1046 39.1046 12 38 12ZM36 16H28V28L12.5157 51.2265C12.1794 51.7309 12 52.3235 12 52.9296C12 54.6254 13.3746 56 15.0704 56H48.9296C50.6254 56 52 54.6254 52 52.9296C52 52.3235 51.8206 51.7309 51.4843 51.2265L36 28V16ZM27.9999 34.8H35.9999L47.7926 52.4891C47.8812 52.622 47.7859 52.8 47.6262 52.8H16.3736C16.2139 52.8 16.1186 52.622 16.2072 52.4891L24.0622 40.7066C24.3959 42.5787 26.032 44 28 44C30.2091 44 32 42.2091 32 40C32 37.7909 30.2091 36 28 36C27.704 36 27.4155 36.0322 27.1378 36.0932L27.9999 34.8ZM38 44C39.1046 44 40 43.1046 40 42C40 40.8954 39.1046 40 38 40C36.8954 40 36 40.8954 36 42C36 43.1046 36.8954 44 38 44ZM36 48C36 49.1046 35.1046 50 34 50C32.8954 50 32 49.1046 32 48C32 46.8954 32.8954 46 34 46C35.1046 46 36 46.8954 36 48Z",
  [iconNames.WIZZARD]:
    "M35.9999 6.47213L33.1714 5.17157L34.472 8L33.1714 10.8284L35.9999 9.52786L38.8283 10.8284L37.5277 8L38.8283 5.17157L35.9999 6.47213Z M43.5562 14.7868C44.3373 14.0057 45.6036 14.0057 46.3846 14.7868L49.2131 17.6152C49.9941 18.3962 49.9941 19.6626 49.2131 20.4436L40.9704 28.6863L35.3136 23.0294L43.5562 14.7868Z M32.4853 25.8579L38.1422 31.5148L12.4437 57.2132C11.6626 57.9943 10.3963 57.9943 9.61526 57.2132L6.78684 54.3848C6.00579 53.6038 6.00579 52.3374 6.78684 51.5564L32.4853 25.8579Z M14.343 10.3431L19.9999 12.9443L25.6567 10.3431L23.0556 16L25.6567 21.6569L19.9999 19.0557L14.343 21.6569L16.9442 16L14.343 10.3431Z M51.9999 36.9443L46.343 34.3431L48.9441 40L46.343 45.6568L51.9999 43.0557L57.6567 45.6568L55.0556 40L57.6567 34.3431L51.9999 36.9443Z",
  [iconNames.FOLDER]:
    "M8 8C5.79086 8 4 9.79086 4 12V24V52C4 54.2091 5.79086 56 8 56H56C58.2091 56 60 54.2091 60 52V24C60 21.7909 58.2091 20 56 20H40L30.3431 10.3431C28.8429 8.84285 26.808 8 24.6863 8H8ZM34 20L27.1716 13.1716C26.4214 12.4214 25.404 12 24.3431 12H8V20H34ZM8 24V52H56V24H8Z",
  [iconNames.FOLDER_OPEN]:
    "M4 12C4 9.79086 5.79086 8 8 8H24.6863C26.808 8 28.8429 8.84285 30.3431 10.3431L40 20H56C58.2091 20 60 21.7909 60 24H56H38L27.1716 13.1716C26.4214 12.4214 25.404 12 24.3431 12H8V24H5.79009C5.01259 24 4.32995 24.446 4 25.1073V12ZM11.4692 28C9.47862 28 7.79094 29.4637 7.50943 31.4343L4.65228 51.4343C4.30803 53.844 6.17788 56 8.61208 56H56.5309C58.5215 56 60.2091 54.5363 60.4907 52.5657L63.3478 32.5657C63.692 30.156 61.8222 28 59.388 28H11.4692ZM59.388 32L11.4692 32L8.61208 52L56.5309 52L59.388 32Z",
  [iconNames.DESCRIPTION]:
    "M8 8C8 5.79086 9.79086 4 12 4H52C54.2091 4 56 5.79086 56 8V56C56 58.2091 54.2091 60 52 60H12C9.79086 60 8 58.2091 8 56V8ZM12 8H52V56H12V8ZM44 16H20V20H44V16ZM20 28H44V32H20V28ZM32 40H20V44H32V40Z",
  [iconNames.CAR]:
    "M16 52a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0-4a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM48 52a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0-4a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z M20 28 4 30v14h2c0-5.523 4.477-10 10-10s10 4.477 10 10h12c0-5.523 4.477-10 10-10s10 4.477 10 10h2V28L48 12H28l-8 16Zm24-12H30l-6 12h28l-8-12Z",
  [iconNames.HOME]: "M52 28 32 8 12 28l-8 8h8v16h20V40h12v12h8V36h8l-8-8Z",
  [iconNames.EVENT]:
    "M18 8a2 2 0 0 0-2 2v2h-4a4 4 0 0 0-4 4v36a4 4 0 0 0 4 4h40a4 4 0 0 0 4-4V16a4 4 0 0 0-4-4h-4v-2a2 2 0 1 0-4 0v2H20v-2a2 2 0 0 0-2-2Zm34 16v28H12V24h40Zm-24 4H16v12h12V28Z",
  [iconNames.FILTER]: "M24 33.4031V56H40V33.4031L60.3225 8H3.67749L24 33.4031Z",
  [iconNames.FILTER_EMPTY]:
    "M24 33.4031V56H40V33.4031L60.3225 8H3.67749L24 33.4031ZM28 52H36L36 32L52 12H12L28 32L28 52Z",
  [iconNames.EXCLAMATION]:
    "M26 50a6 6 0 1 1 12 0 6 6 0 0 1-12 0ZM24 8h16l-4 32h-8L24 8Z",
  [iconNames.EURO]:
    "M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Zm2.667-52.997V2.667h-5.334v8.336c-4.6 1.184-8 5.36-8 10.33 0 3.006 1.243 5.72 3.243 7.66l-.005.005.15.133c.141.131.285.259.433.382l10.7 9.467a5.333 5.333 0 1 1-9.188 3.686h-5.333c0 4.97 3.4 9.147 8 10.331v8.336h5.334v-8.336c4.6-1.184 8-5.36 8-10.33 0-3.294-1.493-6.238-3.838-8.195l-.01.011-10.5-9.29a5.333 5.333 0 1 1 9.015-3.859h5.333c0-4.97-3.4-9.147-8-10.331Z",
  [iconNames.COLLAPSE_LEFT]:
    "M30.357 17.69a3.333 3.333 0 1 0-4.714-4.714L8.976 29.643a3.333 3.333 0 0 0 0 4.714l16.667 16.667a3.333 3.333 0 0 0 4.714-4.714L16.047 32l14.31-14.31Z M50.357 17.69a3.333 3.333 0 1 0-4.714-4.714L28.976 29.643a3.333 3.333 0 0 0 0 4.714l16.667 16.667a3.333 3.333 0 0 0 4.714-4.714L36.047 32l14.31-14.31Z",
  [iconNames.COLLAPSE_RIGHT]:
    "M33.976 46.31a3.333 3.333 0 0 0 4.714 4.714l16.667-16.667a3.333 3.333 0 0 0 0-4.714L38.69 12.976a3.333 3.333 0 0 0-4.714 4.714L48.286 32l-14.31 14.31Z M13.976 46.31a3.333 3.333 0 0 0 4.714 4.714l16.667-16.667a3.333 3.333 0 0 0 0-4.714L18.69 12.976a3.333 3.333 0 0 0-4.714 4.714L28.286 32l-14.31 14.31Z",
  [iconNames.REFUND]:
    "M34.733 2.6001V10.9362C39.3336 12.1202 42.733 16.2965 42.733 21.2668H37.4C37.4 18.3213 35.0118 15.9335 32.0665 15.9335C29.1212 15.9335 26.733 18.3213 26.733 21.2668C26.733 22.7847 27.3673 24.1545 28.3849 25.1257L38.8863 34.4164L38.8951 34.4055C41.2408 36.3622 42.733 39.3069 42.733 42.6001C42.733 47.5704 39.3336 51.7467 34.733 52.9308V61.2668H29.4V52.9308C24.7994 51.7467 21.4 47.5704 21.4 42.6001H26.733C26.733 45.5457 29.1212 47.9335 32.0665 47.9335C35.0118 47.9335 37.4 45.5457 37.4 42.6001C37.4 41.1702 36.837 39.8717 35.921 38.9139L25.2198 29.4465C25.0724 29.323 24.9283 29.1956 24.7882 29.0645L24.6373 28.9312L24.6422 28.9258C22.6427 26.9869 21.4 24.272 21.4 21.2668C21.4 16.2965 24.7994 12.1202 29.4 10.9362V2.6001H34.733Z M18.364 4.12131L14.4832 18.6045L10.2473 14.3686C6.34086 19.1821 4 25.3178 4 32.0001C4 39.7126 7.11826 46.6969 12.1628 51.7608L9.34322 54.5981C3.56962 48.8096 0 40.8217 0 32.0001C0 24.2128 2.78162 17.0752 7.40546 11.5267L3.88081 8.00206L18.364 4.12131Z M56.778 52.2514L60.6045 56.0779L46.1214 59.9586L50.0021 45.4755L53.9333 49.4067C57.7312 44.6276 60 38.5788 60 32.0001C60 24.2485 56.8501 17.2327 51.7605 12.1627L54.5978 9.34314C60.4061 15.1363 64 23.1485 64 32.0001C64 39.6838 61.2919 46.7351 56.778 52.2514Z M31.9553 64.0001L32 64.0001L32.0447 64.0001H31.9553Z",
  [iconNames.RESCHEDULE]:
    "M8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10V12H36V10C36 8.89543 36.8954 8 38 8C39.1046 8 40 8.89543 40 10V12H44C46.2091 12 48 13.7909 48 16V32H44V24H4V52H40H44H48C48 54.2091 46.2091 56 44 56H4C1.79086 56 0 54.2091 0 52V16C0 13.7909 1.79086 12 4 12H8V10ZM28 28C28 28 30 36 36 36H52V28L64 42L52 56V48H40C28 48 28 28 28 28Z",
  [iconNames.ISSUES]:
    "M16 4C11.5818 4 8 7.58179 8 12V52C8 56.4182 11.5818 60 16 60H36C40.4182 60 44 56.4182 44 52V12C44 7.58179 40.4182 4 36 4H16ZM16 8C13.7908 8 12 9.79077 12 12V52C12 54.2092 13.7908 56 16 56H36C38.2092 56 40 54.2092 40 52V12C40 9.79077 38.2092 8 36 8H16Z M43.6001 53.2378L57.4565 29.2375C59.6655 25.4111 58.3545 20.5183 54.5283 18.3093L43.6001 12V16.6187L52.5283 21.7734C54.4414 22.8779 55.0969 25.3242 53.9924 27.2375L43.6001 45.2375V53.2378Z",
  [iconNames.UNSEEN_ISSUES]:
    "M38 20C43.5228 20 48 15.5228 48 10C48 4.47715 43.5228 0 38 0C32.4772 0 28 4.47715 28 10C28 15.5228 32.4772 20 38 20ZM16 4H25.3473C24.7566 5.24341 24.3438 6.58771 24.1418 8H16C13.7908 8 12 9.79077 12 12V52C12 54.2092 13.7908 56 16 56H36C38.2092 56 40 54.2092 40 52V23.8582C41.4123 23.6562 42.7566 23.2434 44 22.6527V44.5449L53.9924 27.2375C55.0969 25.3242 54.4414 22.8779 52.5283 21.7734L48.3896 19.3839C49.2852 18.3931 50.0409 17.2735 50.6259 16.0563L54.5283 18.3093C58.3545 20.5183 59.6655 25.4111 57.4565 29.2375L43.9792 52.5811C43.6815 56.7281 40.2228 60 36 60H16C11.5818 60 8 56.4182 8 52V12C8 7.58179 11.5818 4 16 4Z",
  [iconNames.ADD_ISSUE]:
    "M8 12C8 7.58179 11.5818 4 16 4H36C40.4182 4 44 7.58179 44 12V12.2309L54.5283 18.3093C58.3545 20.5183 59.6655 25.4111 57.4565 29.2375L55.9123 31.9123L52.9841 28.9841L53.9924 27.2375C55.0969 25.3242 54.4414 22.8779 52.5283 21.7734L44 16.8495V24H40V12C40 9.79077 38.2092 8 36 8H16C13.7908 8 12 9.79077 12 12V52C12 54.2092 13.7908 56 16 56H36V60H16C11.5818 60 8 56.4182 8 52V12Z M48 30C48 28.8954 47.1046 28 46 28C44.8954 28 44 28.8954 44 30L44 44H30C28.8954 44 28 44.8954 28 46C28 47.1046 28.8954 48 30 48H44L44 62C44 63.1046 44.8954 64 46 64C47.1046 64 48 63.1046 48 62L48 48H62C63.1046 48 64 47.1046 64 46C64 44.8954 63.1046 44 62 44H48L48 30Z",
  [iconNames.MEMBERS]:
    "M20 28C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12C15.5817 12 12 15.5817 12 20C12 24.4183 15.5817 28 20 28ZM12.8858 29.6647C7.61912 32.2834 4 37.719 4 44H25.6642C27.2692 40.3265 29.9457 37.2284 33.2988 35.1007C31.7513 32.7929 29.6191 30.9102 27.1142 29.6647C25.1235 31.1325 22.6631 32 20 32C17.3369 32 14.8765 31.1325 12.8858 29.6647ZM36.8858 37.6647C31.6191 40.2834 28 45.719 28 52H60C60 45.719 56.3809 40.2834 51.1142 37.6647C49.1235 39.1325 46.6631 40 44 40C41.3369 40 38.8764 39.1325 36.8858 37.6647ZM44 36C48.4183 36 52 32.4183 52 28C52 23.5817 48.4183 20 44 20C39.5817 20 36 23.5817 36 28C36 32.4183 39.5817 36 44 36Z",
  [iconNames.MARKER]:
    "M34 4H4V34C4 50.5685 17.4315 64 34 64C50.5685 64 64 50.5685 64 34C64 17.4315 50.5685 4 34 4ZM37 31V18C37 16.3431 35.6569 15 34 15C32.3431 15 31 16.3431 31 18V31H18C16.3431 31 15 32.3431 15 34C15 35.6569 16.3431 37 18 37H31V50C31 51.6569 32.3431 53 34 53C35.6569 53 37 51.6569 37 50V37H50C51.6569 37 53 35.6569 53 34C53 32.3431 51.6569 31 50 31H37Z",
  [iconNames.LEAVE]:
    "M8 4C3.58179 4 0 7.58179 0 12V52C0 56.4182 3.58179 60 8 60H28C32.4182 60 36 56.4182 36 52V44H32V52C32 54.2092 30.2092 56 28 56H8C5.79077 56 4 54.2092 4 52V12C4 9.79077 5.79077 8 8 8H28C30.2092 8 32 9.79077 32 12V20H36V12C36 7.58179 32.4182 4 28 4H8ZM48.6863 17.8579L61.4142 30.5858C62.1953 31.3668 62.1953 32.6332 61.4142 33.4142L48.6863 46.1421C47.9052 46.9232 46.6389 46.9232 45.8579 46.1421C45.0768 45.3611 45.0768 44.0947 45.8579 43.3137L55.1716 34H12V30H55.1716L45.8579 20.6863C45.0768 19.9052 45.0768 18.6389 45.8579 17.8579C46.6389 17.0768 47.9052 17.0768 48.6863 17.8579Z",
  [iconNames.ADD_PROJECT]:
    "M2.54413 29.1715C0.982034 30.7336 0.982034 33.2663 2.54413 34.8284L29.1715 61.4558C30.7336 63.0179 33.2663 63.0179 34.8284 61.4558L38.1421 58.1421L35.3137 55.3137L32 58.6274L5.37256 32L32 5.37256L58.6274 32L55.3137 35.3137L58.1421 38.1421L61.4558 34.8284C63.0179 33.2663 63.0179 30.7336 61.4558 29.1715L34.8284 2.54413C33.2663 0.982034 30.7336 0.982035 29.1715 2.54413L2.54413 29.1715Z M48 30C48 28.8954 47.1046 28 46 28C44.8954 28 44 28.8954 44 30V44H30C28.8954 44 28 44.8954 28 46C28 47.1046 28.8954 48 30 48H44V62C44 63.1046 44.8954 64 46 64C47.1046 64 48 63.1046 48 62V48H62C63.1046 48 64 47.1046 64 46C64 44.8954 63.1046 44 62 44H48V30Z",
  [iconNames.ADD_MEMBER]:
    "M20 20C25.5228 20 30 15.5228 30 10C30 4.47715 25.5228 0 20 0C14.4772 0 10 4.47715 10 10C10 15.5228 14.4772 20 20 20ZM12.155 21.5972C5.00871 24.6475 0 31.7388 0 40H20H22.0988H24.8281C26.4113 34.4024 30.1545 29.7118 35.1004 26.8855C34.3125 25.9791 33.4444 25.1443 32.507 24.3921C31.097 23.2608 29.5304 22.3166 27.845 21.5972C25.6071 23.1139 22.907 24 20 24C17.093 24 14.3929 23.1139 12.155 21.5972Z M48 30C48 28.8954 47.1046 28 46 28C44.8954 28 44 28.8954 44 30V44H30C28.8954 44 28 44.8954 28 46C28 47.1046 28.8954 48 30 48H44V62C44 63.1046 44.8954 64 46 64C47.1046 64 48 63.1046 48 62V48H62C63.1046 48 64 47.1046 64 46C64 44.8954 63.1046 44 62 44H48V30Z",
  [iconNames.FIT_TO_SCREEN]:
    "M49.306 54L33 37.6568L17.5478 54H24C25.6569 54 27 55.3431 27 57C27 58.6569 25.6569 60 24 60H8C6.34314 60 5 58.6569 5 57V41C5 39.3431 6.34314 38 8 38C9.65686 38 11 39.3431 11 41V49.244L27.3431 32L11 15.6215L11 23C11 24.6569 9.65685 26 8 26C6.34315 26 5 24.6569 5 23L5 7C5 5.34315 6.34315 4 8 4L24 4C25.6569 4 27 5.34315 27 7C27 8.65686 25.6569 10 24 10L16.694 10L33 26.3431L48.4522 10L42 10C40.3431 10 39 8.65686 39 7C39 5.34314 40.3431 4 42 4L58 4C59.6569 4 61 5.34314 61 7V23C61 24.6569 59.6569 26 58 26C56.3431 26 55 24.6569 55 23V14.756L38.6569 32L55 48.3785V41C55 39.3431 56.3431 38 58 38C59.6569 38 61 39.3431 61 41V57C61 58.6569 59.6569 60 58 60H42C40.3431 60 39 58.6569 39 57C39 55.3431 40.3431 54 42 54L49.306 54Z",
  [iconNames.OFFER]:
    "M28 2C28 0.895431 28.8954 0 30 0C31.1046 0 32 0.895431 32 2V8.12152C38.7846 8.95335 44 13.9546 44 20C44 21.1046 43.1046 22 42 22C40.8954 22 40 21.1046 40 20C40 16.1297 36.5645 12.9013 32 12.16V30.1317C38.7846 31.0328 44 36.4508 44 43C44 49.5492 38.7846 54.9672 32 55.8684V62C32 63.1046 31.1046 64 30 64C28.8954 64 28 63.1046 28 62V55.8785C21.2154 55.0467 16 50.0454 16 44C16 42.8954 16.8954 42 18 42C19.1046 42 20 42.8954 20 44C20 47.8703 23.4355 51.0987 28 51.84V33.8684C21.2154 32.9672 16 27.5492 16 21C16 14.4508 21.2154 9.03279 28 8.13165V2ZM28 29.82V12.18C23.4355 13.0139 20 16.6459 20 21C20 25.3541 23.4355 28.9861 28 29.82ZM32 34.18V51.82C36.5645 50.9861 40 47.3541 40 43C40 38.6459 36.5645 35.0139 32 34.18Z",
  [iconNames.ADD_OFFER]:
    "M22 2C20.8954 2 20 2.89543 20 4V8V8.13165C13.2154 9.03279 8 14.4508 8 21C8 27.5492 13.2154 32.9672 20 33.8684V51.84C15.4355 51.0987 12 47.8703 12 44C12 42.8954 11.1046 42 10 42C8.89543 42 8 42.8954 8 44C8 50.0454 13.2154 55.0467 20 55.8785V56V60C20 61.1046 20.8954 62 22 62C23.1046 62 24 61.1046 24 60V56V55.8684C27.166 55.4478 29.9904 54.0437 32.1025 52H24V34.18C27.4618 34.8125 30.2741 37.0543 31.431 40H35.6254C34.3165 34.8547 29.7149 30.8907 24 30.1317V12.16C28.5645 12.9013 32 16.1297 32 20C32 21.1046 32.8954 22 34 22C35.1046 22 36 21.1046 36 20C36 13.9546 30.7846 8.95335 24 8.12152V8V4C24 2.89543 23.1046 2 22 2ZM20 12.18C15.4355 13.0139 12 16.6459 12 21C12 25.3541 15.4355 28.9861 20 29.82V12.18ZM48 30C48 28.8954 47.1046 28 46 28C44.8954 28 44 28.8954 44 30V44H30C28.8954 44 28 44.8954 28 46C28 47.1046 28.8954 48 30 48H44V62C44 63.1046 44.8954 64 46 64C47.1046 64 48 63.1046 48 62V48H62C63.1046 48 64 47.1046 64 46C64 44.8954 63.1046 44 62 44H48V30Z",
  [iconNames.DESCENDING]:
    "M15.4222 59.4142L24.1501 50.6863C24.9311 49.9052 24.9311 48.6389 24.1501 47.8579C23.369 47.0768 22.1027 47.0768 21.3217 47.8579L16.008 53.1716L16.008 4L12.008 4L12.008 53.1716L6.69424 47.8579C5.91319 47.0768 4.64686 47.0768 3.86582 47.8579C3.08477 48.6389 3.08477 49.9052 3.86582 50.6863L12.5937 59.4142C13.3748 60.1953 14.6411 60.1953 15.4222 59.4142ZM56 4H32C29.7909 4 28 5.79086 28 8C28 10.2091 29.7909 12 32 12H56C58.2091 12 60 10.2091 60 8C60 5.79086 58.2091 4 56 4ZM48 20H32C29.7909 20 28 21.7909 28 24C28 26.2091 29.7909 28 32 28H48C50.2091 28 52 26.2091 52 24C52 21.7909 50.2091 20 48 20ZM32 44H40C42.2091 44 44 42.2091 44 40C44 37.7909 42.2091 36 40 36H32C29.7909 36 28 37.7909 28 40C28 42.2091 29.7909 44 32 44ZM32 60C34.2091 60 36 58.2091 36 56C36 53.7909 34.2091 52 32 52C29.7909 52 28 53.7909 28 56C28 58.2091 29.7909 60 32 60Z",
  [iconNames.ASCENDING]:
    "M15.4221 59.4142L24.15 50.6863C24.9311 49.9052 24.9311 48.6389 24.15 47.8579C23.369 47.0768 22.1026 47.0768 21.3216 47.8579L16.0079 53.1716L16.0079 4L12.0079 4L12.0079 53.1716L6.69418 47.8579C5.91313 47.0768 4.6468 47.0768 3.86575 47.8579C3.08471 48.6389 3.08471 49.9052 3.86575 50.6863L12.5937 59.4142C13.3747 60.1953 14.6411 60.1953 15.4221 59.4142ZM32 52C29.7908 52 28 53.7909 28 56C28 58.2091 29.7908 60 32 60H56C58.2091 60 60 58.2091 60 56C60 53.7909 58.2091 52 56 52H32ZM48 36H32C29.7908 36 28 37.7909 28 40C28 42.2091 29.7908 44 32 44H48C50.2091 44 52 42.2091 52 40C52 37.7909 50.2091 36 48 36ZM32 28H40C42.2091 28 44 26.2091 44 24C44 21.7909 42.2091 20 40 20H32C29.7908 20 28 21.7909 28 24C28 26.2091 29.7908 28 32 28ZM32 12C34.2091 12 36 10.2091 36 8C36 5.79086 34.2091 4 32 4C29.7908 4 28 5.79086 28 8C28 10.2091 29.7908 12 32 12Z",
  [iconNames.UNSORTED]:
    "M42 4H22C19.7909 4 18 5.79086 18 8C18 10.2091 19.7909 12 22 12H42C44.2091 12 46 10.2091 46 8C46 5.79086 44.2091 4 42 4ZM18 52H46C48.2091 52 50 53.7909 50 56C50 58.2091 48.2091 60 46 60H18C15.7909 60 14 58.2091 14 56C14 53.7909 15.7909 52 18 52ZM38 36H26C23.7909 36 22 37.7909 22 40C22 42.2091 23.7909 44 26 44H38C40.2091 44 42 42.2091 42 40C42 37.7909 40.2091 36 38 36ZM30 20H34C36.2091 20 38 21.7909 38 24C38 26.2091 36.2091 28 34 28H30C27.7909 28 26 26.2091 26 24C26 21.7909 27.7909 20 30 20Z",
  [iconNames.DOWNLOAD]:
    "M32 6C32 4.89543 31.1046 4 30 4C28.8954 4 28 4.89543 28 6V42L18.2426 32.2426C17.4616 31.4616 16.1953 31.4616 15.4142 32.2426C14.6332 33.0237 14.6332 34.29 15.4142 35.0711L28.5858 48.2426C29.3668 49.0237 30.6332 49.0237 31.4142 48.2426L44.5858 35.0711C45.3668 34.29 45.3668 33.0237 44.5858 32.2426C43.8047 31.4616 42.5384 31.4616 41.7574 32.2426L32 42V6ZM8 56V46C8 44.8954 7.10457 44 6 44C4.89543 44 4 44.8954 4 46V58C4 59.1046 4.89543 60 6 60H54C55.1046 60 56 59.1046 56 58V46C56 44.8954 55.1046 44 54 44C52.8954 44 52 44.8954 52 46V56H8Z",
  [iconNames.UPLOAD]:
    "M30 48C28.8954 48 28 47.1046 28 46V11.6569L18.2426 21.4142C17.4616 22.1953 16.1953 22.1953 15.4142 21.4142C14.6332 20.6332 14.6332 19.3668 15.4142 18.5858L28.5858 5.41421C29.3668 4.63316 30.6332 4.63316 31.4142 5.41421L44.5858 18.5858C45.3668 19.3668 45.3668 20.6332 44.5858 21.4142C43.8047 22.1953 42.5384 22.1953 41.7574 21.4142L32 11.6569V46C32 47.1046 31.1046 48 30 48ZM8 56V46C8 44.8954 7.10457 44 6 44C4.89543 44 4 44.8954 4 46V58C4 59.1046 4.89543 60 6 60H54C55.1046 60 56 59.1046 56 58V46C56 44.8954 55.1046 44 54 44C52.8954 44 52 44.8954 52 46V56H8Z",
  [iconNames.HIDE]:
    "M9.37244 54.6275C8.59139 53.8464 8.59139 52.5801 9.37244 51.799L25.1076 36.0639C24.4038 34.8729 24 33.4836 24 32C24 28.6863 26.0147 25.8431 28.886 24.6287C29.8431 24.2239 30.8954 24 32 24C32.0927 24 32.1851 24.0016 32.277 24.0047C33.6565 24.0516 34.9473 24.4478 36.0639 25.1076L51.7988 9.37264C52.5799 8.59159 53.8462 8.59159 54.6273 9.37264C55.4083 10.1537 55.4083 11.42 54.6273 12.2011L46.5525 20.2758C52.0542 23.4868 56.6339 28.0003 59.7189 31.9999C54.186 39.1736 43.8444 48 31.9999 48C28.2089 48 24.5719 47.0958 21.1973 45.6311L12.2009 54.6275C11.4198 55.4085 10.1535 55.4085 9.37244 54.6275ZM27.936 38.8923L25.0468 41.7816C27.0084 43.1784 29.4081 44 31.9997 44C38.6272 44 43.9997 38.6274 43.9997 32C43.9997 29.4084 43.1782 27.0086 41.7813 25.047L38.8923 27.936C39.4551 28.8883 39.8261 29.9675 39.9521 31.1202C39.9838 31.4091 40 31.7027 40 32C40 36.4183 36.4183 40 32 40C31.7026 40 31.4091 39.9838 31.1202 39.9522C29.9674 39.8261 28.8883 39.4551 27.936 38.8923Z M31.9997 20C33.476 20 34.8899 20.2665 36.1961 20.7542L39.7291 17.2212C37.2538 16.4468 34.6649 16 31.9998 16C20.1553 16 9.8138 24.8263 4.28076 31.9998C6.86981 35.3566 10.5118 39.0754 14.868 42.0823L20.7539 36.1964C20.2663 34.8902 19.9997 33.4762 19.9997 32C19.9997 25.3726 25.3723 20 31.9997 20Z",
  [iconNames.SHOW]:
    "M31.9999 48C43.8444 48 54.186 39.1736 59.7189 31.9999C54.1859 24.8263 43.8443 16 31.9998 16C20.1553 16 9.8138 24.8263 4.28076 31.9998C9.81367 39.1735 20.1553 48 31.9999 48ZM31.9997 44C38.6272 44 43.9997 38.6274 43.9997 32C43.9997 25.3726 38.6272 20 31.9997 20C25.3723 20 19.9997 25.3726 19.9997 32C19.9997 38.6274 25.3723 44 31.9997 44Z M40 32C40 36.4183 36.4183 40 32 40C27.5817 40 24 36.4183 24 32C24 27.5817 27.5817 24 32 24C36.4183 24 40 27.5817 40 32Z",
  [iconNames.FREEZE]:
    "M26.0001 2L23.1717 4.82843L28.0001 9.65676V20.2C26.7232 20.4592 25.5347 20.9617 24.485 21.6569L17.0295 14.2013V7.37248H13.0295L13.0295 13.0293L7.37261 13.0293L7.37261 17.0293L14.2005 17.0293L21.6567 24.4854C20.9615 25.535 20.4592 26.7234 20.2 28.0001H9.65715L4.82843 23.1714L2 25.9998L5.99996 29.9998L2 33.9998L4.82843 36.8282L9.65646 32.0001H20.2001C20.4592 33.2768 20.9616 34.4652 21.6568 35.5148L14.2011 42.9705H7.37224L7.37224 46.9705H13.029L13.0291 52.6274H17.0291L17.029 45.7995L24.4853 38.3432C25.5349 39.0384 26.7233 39.5408 28.0001 39.8V50.3428L23.1714 55.1716L25.9998 58L29.9998 54L33.9998 58L36.8282 55.1716L32.0001 50.3435V39.7999C33.2767 39.5408 34.4651 39.0384 35.5146 38.3433L42.9704 45.7991V52.6275H46.9704V46.9707L52.6272 46.9707V42.9707L45.7989 42.9707L38.3431 35.515C39.0383 34.4653 39.5408 33.2769 39.7999 32.0001H50.3431L55.1716 36.8285L58 34.0001L54 30.0002L58 26.0001L55.1716 23.1717L50.3432 28.0001H39.8C39.5408 26.7233 39.0384 25.5349 38.3432 24.4853L45.7989 17.0296L52.6273 17.0296V13.0296L46.9705 13.0296L46.9704 7.37277H42.9704L42.9705 14.2011L35.5148 21.6568C34.4652 20.9616 33.2768 20.4592 32.0001 20.2001V9.65685L36.8285 4.82843L34.0001 2L30.0002 5.99996L26.0001 2ZM30 36C33.3137 36 36 33.3137 36 30C36 26.6863 33.3137 24 30 24C26.6863 24 24 26.6863 24 30C24 33.3137 26.6863 36 30 36Z",
  [iconNames.UNFREEZE]:
    "M26.0001 2L23.1717 4.82843L28.0001 9.65676V20.2C26.7232 20.4592 25.5347 20.9617 24.485 21.6569L17.0295 14.2013V7.37248H13.0295L13.0295 13.0293L7.37261 13.0293L7.37261 17.0293L14.2005 17.0293L21.6567 24.4854C20.9615 25.535 20.4592 26.7234 20.2 28.0001H9.65715L4.82843 23.1714L2 25.9998L5.99996 29.9998L2 33.9998L4.82843 36.8282L9.65646 32.0001H12V32H24.3414C24.1203 31.3744 24 30.7013 24 30C24 26.6863 26.6863 24 30 24C33.3137 24 36 26.6863 36 30C36 30.7013 35.8797 31.3744 35.6586 32H48V32.0001H50.3431L55.1716 36.8285L58 34.0001L54 30.0002L58 26.0001L55.1716 23.1717L50.3432 28.0001H39.8C39.5408 26.7233 39.0384 25.5349 38.3432 24.4853L45.7989 17.0296L52.6273 17.0296V13.0296L46.9705 13.0296L46.9704 7.37277H42.9704L42.9705 14.2011L35.5148 21.6568C34.4652 20.9616 33.2768 20.4592 32.0001 20.2001V9.65685L36.8285 4.82843L34.0001 2L30.0002 5.99996L26.0001 2ZM38 36C41.3137 36 44 38.6863 44 42C44 43.1046 44.8954 44 46 44C47.1046 44 48 43.1046 48 42V36H52V42C52 45.3137 49.3137 48 46 48C42.6863 48 40 45.3137 40 42C40 40.8954 39.1046 40 38 40C36.8954 40 36 40.8954 36 42V54C36 57.3137 33.3137 60 30 60C26.6863 60 24 57.3137 24 54V46C24 44.8954 23.1046 44 22 44C20.8954 44 20 44.8954 20 46V50C20 53.3137 17.3137 56 14 56C10.6863 56 8 53.3137 8 50V36H12V50C12 51.1046 12.8954 52 14 52C15.1046 52 16 51.1046 16 50V46C16 42.6863 18.6863 40 22 40C25.3137 40 28 42.6863 28 46V54C28 55.1046 28.8954 56 30 56C31.1046 56 32 55.1046 32 54V42C32 38.6863 34.6863 36 38 36Z",
};

const Icon = ({
  name,
  hover,
  emphasized,
  warning,
  important,
  alert,
  color,
  size = 16,
  ...rest
}) => {
  const iconSize = size && parseInt(size) ? parseInt(size) : svgSize;
  const restKeys = rest != null && Object.keys(rest) ? Object.keys(rest) : [];

  let pathName;
  restKeys?.map((key) => {
    const found = Object.keys(iconNames).find(
      (iconNameKey) => key === iconNames[iconNameKey]
    );
    if (found) pathName = found;
    return false;
  });

  return (
    <svg
      className={styles.icon}
      width={iconSize}
      height={iconSize}
      viewBox={"0 0 " + svgSize + " " + svgSize}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classNames(styles[color], {
          [styles.hover]: hover === true,
          [styles.emphasized]: emphasized,
          [styles.warning]: warning,
          [styles.important]: important,
          [styles.alert]: alert,
        })}
        fillRule="evenodd"
        clipRule="evenodd"
        d={iconPaths[iconNames[pathName]]}
      />
    </svg>
  );
};

export default Icon;
