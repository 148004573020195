import ReactDOM from "react-dom";
import { SlideshowScreener } from "../../components/Screener";
import useSlideshow from "./hooks/useSlideshow";
import SlideshowControls from "./SlideshowControls";
import SlideActions from "./SlideActions";
import SlideshowAside from "./SlideshowAside";
import classNames from "classnames";
import Markable from "../marker/Markable";

import styles from "./slideshow.module.css";

const Slideshow = ({ frames, obsoleteFrames }) => {
  const {
    show,
    onClose,
    onExpandAside,
    onCollapseAside,
    asideExpanded,
    frame,
  } = useSlideshow(frames, obsoleteFrames);

  if (!show) return null;
  return ReactDOM.createPortal(
    <>
      <SlideshowScreener onClick={onClose} />
      <div
        className={classNames(styles.slideshowContainer, {
          [styles.asideExpanded]: asideExpanded,
        })}
      >
        <Markable frame={frame} />
        <SlideActions />
        <SlideshowAside
          onExpand={onExpandAside}
          onCollapse={onCollapseAside}
          expanded={asideExpanded}
        />
        <SlideshowControls frames={frames} currentFrame={frame}/>
      </div>
    </>,
    document.getElementById("slideshow")
  );
};

export default Slideshow;
