import useIssuesByStatus from "./useIssuesByStatus";
import usePagination from "../../../hooks/usePagination";
import { useMemo, useEffect } from "react";
import useLang from "../../../hooks/useLang";
import useSearchParams from "../../../hooks/useSearchParams";
import { useNavigate } from "react-router-dom";
import useProject from "../../projects/hooks/useProject";

const useIssueList = () => {
  const lang = useLang();
  const navigate = useNavigate();
  const { project } = useProject();
  const { getParam, getLinkWithParam } = useSearchParams();
  const { allIssues, openIssues, closedIssues, isLoading } =
    useIssuesByStatus();

  let frames = [];
  if (project?.drawings?.frames) {
    frames = project?.drawings?.frames;
  }
  if (project?.visualizations?.frames) {
    frames = [...frames, ...project?.visualizations?.frames];
  }
  if (project?.obsolete?.frames) {
    frames = [...frames, ...project?.obsolete?.frames];
  }

  const issueType = getParam("issueType");
  let data = openIssues;
  if (issueType === "closed") {
    data = closedIssues;
  } else if (issueType === "all") {
    data = allIssues;
  }

  const { pageIndex, pageCount, pageSize, goToNext, goToPrevious } =
    usePagination(data?.length);

  const getIssuesInPage = (issues, pageIndex, pageSize) => {
    const firstIndexInSlice = (pageIndex - 1) * pageSize;
    const lastIndexInSlice = pageIndex * pageSize;
    return issues.slice(firstIndexInSlice, lastIndexInSlice);
  };
  const paginatedIssues = useMemo(
    () => getIssuesInPage(data, pageIndex, pageSize),
    [data, pageIndex, pageSize]
  );

  const issuesWithLinks = paginatedIssues.map((issue) => {
    let link = issue?.id;
    let target;
    if (issue.target) {
      target = issue.target;
      const frame = frames?.find((frame) => {
        return frame.id === issue.target.frame_id;
      });
      if (frame?.lores?.url) {
        target = { ...target, url: frame.lores?.url };
      }
    }
    return { ...issue, target, link };
  });

  const counts = {
    open: openIssues?.length,
    closed: closedIssues?.length,
    all: allIssues?.length,
  };

  const paramTabAttributes = [
    {
      key: "0",
      label: `${lang("open_plural")} (${counts.open})`,
      param: "issueType",
      to: "open",
    },
    {
      key: "1",
      label: `${lang("closed_plural")} (${counts.closed})`,
      param: "issueType",
      to: "closed",
    },
    {
      key: "2",
      label: `${lang("all")} (${counts.all})`,
      param: "issueType",
      to: "all",
    },
  ];

  useEffect(() => {
    if (issueType == null) {
      navigate(`?${getLinkWithParam("issueType", "open")}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line
  }, []);

  return {
    issues: issuesWithLinks,
    showPagination: pageCount > 1,
    disablePrev: pageIndex <= 1,
    disableNext: pageIndex >= pageCount,
    isLoading,
    goToNext,
    goToPrevious,
    paramTabAttributes,
  };
};

export default useIssueList;
