import {
  setError,
  setErrorWithOverride,
  resetError,
  overrideError,
  selectError,
  selectErrorMessage,
  selectHasOverride,
  selectOverride,
  selectPostOverride,
  selectRequestUrl,
  selectRequestBody,
} from "./alertsSlice";
import { useDispatch } from "react-redux";
import { getErrorMessage } from "../../utils/errorUtils";
import { useSelector } from "react-redux";
import useOverrides from "../../hooks/useOverrides";

const useAlerts = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const hasOverride = useSelector(selectHasOverride);
  const override = useSelector(selectOverride);
  const requestUrl = useSelector(selectRequestUrl);
  const requestBody = useSelector(selectRequestBody);
  const postOverride = useSelector(selectPostOverride);

  const { hasOverrides, getOverrides } = useOverrides();

  const onError = (error, callback) => {
    const message = getErrorMessage(error);
    if (hasOverrides(error)) {
      const override = getOverrides(error);
      dispatch(
        setErrorWithOverride({
          message,
          override,
          request: error.request,
        })
      );
    } else {
      dispatch(setError({ message }));
    }
  };

  const onOverride = () => {
    dispatch(overrideError());
  };

  const onReset = () => {
    dispatch(resetError());
  };

  return {
    error,
    errorMessage,
    hasOverride,
    override,
    postOverride,
    requestUrl,
    requestBody,
    onError,
    onOverride,
    onReset,
  };
};

export default useAlerts;
