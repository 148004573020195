import { useParams } from "react-router-dom";
import useGroup from "../projectGroups/hooks/useGroup";
import { useLocation } from "react-router-dom";
import useLang from "../../hooks/useLang";

const useBreadcrumbs = () => {
  const lang = useLang();
  const { groupId } = useParams();
  const { group } = useGroup();
  const { pathname } = useLocation();

  const addCrumb = (currentCrumbs, currentUrl, label, id, link) => {
    const url = `${currentUrl}/${link != null ? link : id}`;
    if (currentUrl == null || `${url}/specs` === pathname) {
      return [[...currentCrumbs, { id, label, url: null }], null];
    }
    if (url === pathname) {
      return [currentCrumbs, currentUrl];
    }
    return [[...currentCrumbs, { id, label, url: url }], url];
  };

  let crumbs = [];
  let url = "";
  [crumbs] = addCrumb(crumbs, url, lang("home"), "home", "");
  if (groupId) {
    [crumbs, url] = addCrumb(
      crumbs,
      url,
      group?.name ? group?.name : "...",
      groupId
    );
  }

  return crumbs;
};

export default useBreadcrumbs;
