import SingleComment from "./SingleComment";
import ErrorBoundary from "../../components/ErrorBoundary";
import useIssue from "../issues/hooks/useIssue";
import useLang from "../../hooks/useLang";

const CommentList = () => {
  const lang = useLang();
  const { issue, isLoading, isSuccess } = useIssue();

  let content;
  if (isLoading) {
    content = <p>{lang("Loading")}</p>;
  } else if (isSuccess) {
    content = issue?.messages?.map((comment) => {
      return (
        <ErrorBoundary key={comment.id} fallback="Error in comment">
          <SingleComment comment={comment} />
        </ErrorBoundary>
      );
    });
  }

  return <>{content}</>;
};

export default CommentList;
