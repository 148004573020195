import React, { useEffect, useRef } from "react";

const VisibilitySensor = ({ children, onVisible }) => {
  const targetRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (typeof onVisible === "function") onVisible();
          observer.unobserve(target);
        }
      },
      { threshold: 1 } // Adjust threshold as needed
    );

    const target = targetRef.current;
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
    // eslint-disable-next-line
  }, []);

  return <div ref={targetRef}>{children}</div>;
};

export default VisibilitySensor;
