import useSearchParams from "../../../hooks/useSearchParams";

const useMarkingMode = () => {
  const { getParam, addParam } = useSearchParams();
  const marker = getParam("marker") === "true";

  return {
    marker,
    toggleMarker: () => {
      addParam("marker", !marker);
    },
  };
};

export default useMarkingMode;
