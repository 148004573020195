import classNames from "classnames";

import styles from "./section.module.css";

const Section = ({
  children,
  boxed,
  filled,
  paddingSm,
  emphasized,
  ...rest
}) => {
  return (
    <section
      className={classNames(styles.section, {
        [styles.paddingSm]: paddingSm,
        [styles.boxed]: boxed,
        [styles.emphasized]: emphasized,
        [styles.filled]: filled,
      })}
      {...rest}
    >
      {children}
    </section>
  );
};

export default Section;
