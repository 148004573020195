import FrameListItem, { PlaceholderItem } from "./FrameListItem";
import styles from "./frameList.module.css";

const FrameList = ({ frames, placeholders, showHidden }) => {
  return (
    <div className={styles.frameList}>
      {frames?.map((frame) => (
        <FrameListItem
          key={`${frame?.id}`}
          frame={frame}
          showHidden={showHidden}
        />
      ))}
      {placeholders?.map((placeholder) => (
        <PlaceholderItem key={placeholder.id} placeholder={placeholder} />
      ))}
    </div>
  );
};

export default FrameList;
