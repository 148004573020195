import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useSearchParams from "./useSearchParams";
import { useLocation } from "react-router-dom";
import useOrganizations from "../features/organizations/hooks/useOrganizations";
import useOrganizationGroups from "../features/projectGroups/hooks/useOrganizationGroups";
import useSharedGroups from "../features/projectGroups/hooks/useSharedGroups";
import useMyGroups from "../features/projectGroups/hooks/useMyGroups";
import useGroup from "../features/projectGroups/hooks/useGroup";
import useProject from "../features/projects/hooks/useProject";
import useIssueById from "../features/issues/hooks/useIssueById";
import useMyProfile from "../features/auth/useMyProfile";
import { isNotFoundError } from "../utils/errorUtils";

const useRefresher = () => {
  const [invalidGroupId, setInvalidGroupId] = useState(null);
  const [invalidProjectId, setInvalidProjectId] = useState(null);
  const [invalidIssueId, setInvalidIssueId] = useState(null);
  const location = useLocation();
  const { groupId, projectId, issueId } = useParams();
  const { getParam } = useSearchParams();
  const [userActive, setUserActive] = useState(true);
  const [refreshCounter, setRefreshCounter] = useState(3);
  const refreshTimerRef = useRef();
  const owner = getParam("owner");
  const { refetch: refetchOrganizations } = useOrganizations();
  const { refetch: refetchOrganizationGroups } = useOrganizationGroups(
    owner,
    owner !== "my" && owner !== "shared"
  );
  const { refetch: refetchSharedGroups } = useSharedGroups(true);
  const { refetch: refetchMyGroups } = useMyGroups(true);
  const { error: groupError, refetch: refetchGroup } = useGroup(true);
  const { error: projectError, refetch: refetchProject } = useProject();
  const { error: issueError, refetch: refetchIssue } = useIssueById(issueId);
  const { refetch: refetchProfile } = useMyProfile();

  useEffect(() => {
    if (isNotFoundError(groupError)) {
      setInvalidGroupId((state) => (state !== groupId ? groupId : state));
    } else {
      setInvalidGroupId((state) => (state != null ? null : state));
    }
    // eslint-disable-next-line
  }, [groupError]);

  useEffect(() => {
    if (isNotFoundError(projectError)) {
      setInvalidProjectId((state) => (state !== projectId ? projectId : state));
    } else {
      setInvalidProjectId((state) => (state != null ? null : state));
    }
    // eslint-disable-next-line
  }, [projectError]);

  useEffect(() => {
    if (isNotFoundError(issueError)) {
      setInvalidIssueId((state) => (state !== issueId ? issueId : state));
    } else {
      setInvalidIssueId((state) => (state !== null ? null : state));
    }
    // eslint-disable-next-line
  }, [issueError]);

  const invalidateData = () => {
    if (groupId) {
      if (projectId) {
        if (issueId) {
          if (issueId !== invalidIssueId) refetchIssue();
        } else {
          if (projectId !== invalidProjectId) refetchProject();
        }
      } else {
        if (groupId !== invalidGroupId) {
          refetchGroup();
        }
      }
    } else if (location?.pathname === "/profile") {
      refetchProfile();
    } else {
      refetchOrganizations();
      if (owner == null || owner === "shared") {
        refetchSharedGroups();
      } else if (owner === "my") {
        refetchMyGroups();
      } else {
        refetchOrganizationGroups();
      }
    }
  };

  let inactivityTimer;
  const handleMouseMove = () => {
    setUserActive((state) => state || true);
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(() => {
      setUserActive((state) => (state ? false : state));
    }, 10000);
  };

  const handleWindowBlur = () => {
    setRefreshCounter((state) => Math.max(state - 1, 0));
  };

  const handleWindowFocus = () => {
    setRefreshCounter((state) => (state !== 3 ? 3 : state));
  };

  useEffect(() => {
    clearTimeout(refreshTimerRef.current);
    if (refreshCounter > 0) {
      invalidateData();
      refreshTimerRef.current = setTimeout(() => {
        setRefreshCounter((state) => Math.max(state - 1, 0));
      }, 10000);
    }
    // eslint-disable-next-line
  }, [refreshCounter]);

  useEffect(() => {
    if (userActive) {
      setRefreshCounter((state) => (state !== 3 ? 3 : state));
    } else {
      setRefreshCounter((state) => (state !== 0 ? 0 : state));
    }
    // eslint-disable-next-line
  }, [userActive]);

  useEffect(() => {
    invalidateData();
    setRefreshCounter((state) => (state !== 3 ? 3 : state));
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("focus", handleWindowFocus);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("focus", handleWindowFocus);
      window.removeEventListener("mousemove", handleMouseMove);
    };
    // eslint-disable-next-line
  }, []);

  return false;
};

export default useRefresher;
