import { useState, useEffect } from "react";
import useInput from "../../../hooks/useInput";
import { useMyProfileQuery } from "../../auth/authApiSlice";
import { usePatchMyProfileMutation } from "../../auth/authApiSlice";

const useProfilePatcher = () => {
  const { data: myProfile, isLoading: profileLoading } = useMyProfileQuery();
  const [primaryEmail, setPrimaryEmail] = useState(
    myProfile?.primary_email || ""
  );
  const usernameInput = useInput(myProfile?.username || "");

  const [patchProfileMutation] = usePatchMyProfileMutation();

  const handleSubmit = async () => {
    try {
      await patchProfileMutation({
        username: usernameInput?.value,
        primary_email: primaryEmail,
      }).unwrap();
      setPrimaryEmail(primaryEmail);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCancel = () => {
    setPrimaryEmail(myProfile?.primary_email);
  };

  useEffect(() => {
    if (!profileLoading) {
      usernameInput.setValue(myProfile?.username);
      setPrimaryEmail(myProfile?.primary_email);
    }
    // eslint-disable-next-line
  }, [profileLoading]);

  return {
    usernameInput,
    emails: myProfile?.emails || [],
    primaryEmail,
    selectEmail: setPrimaryEmail,
    handleSubmit,
    handleCancel,
  };
};

export default useProfilePatcher;
