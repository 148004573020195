import useProject from "../../projects/hooks/useProject";
import useSlideId from "../../slideshow/hooks/useSlideId";

const useFrameIssues = () => {
  const { project, ...rest } = useProject();
  const { slideId } = useSlideId();
  let issues = [];
  if (Array.isArray(project?.issues)) {
    issues = project.issues.filter(
      (issue) => issue.target?.frame_id === slideId
    );
  }
  return { issues, ...rest };
};

export default useFrameIssues;
