export const removeIndexFromArray = (idx, array) => {
  if (array == null || !Array.isArray(array)) {
    console.warn(`removeIndexFromArray: ${array} is not an array`);
    return [];
  }
  return array.slice(0, idx).concat(array.slice(idx + 1));
};

export const removeStringFromArray = (string, array) => {
  if (array == null || !Array.isArray(array)) {
    console.warn(`removeStringFromArray: ${array} is not an array`);
    return [];
  }
  if (typeof string !== "string") {
    console.warn(`removeStringFromArray: ${string} is not a string`);
    return array;
  }
  const idx = array.findIndex((item) => item === string);
  if (idx >= 0) return removeIndexFromArray(idx, array);
  else {
    console.warn(`removeStringFromArray: ${string} was not found in ${array}`);
    return array;
  }
};

export const removeObjectWithKeyFromArray = (keyName, keyValue, array) => {
  if (array == null || !Array.isArray(array)) {
    console.warn(`removeObjectWithKeyFromArray: ${array} is not an array`);
    return [];
  }
  if (typeof keyName !== "string") {
    console.warn(`removeObjectWithKeyFromArray: ${keyName} is not a string`);
    return array;
  }
  if (keyValue == null) {
    console.warn(`removeObjectWithKeyFromArray: key value is ${keyValue}`);
    return array;
  }
  const idx = array.findIndex((item) => item[keyName] === keyValue);
  if (idx >= 0) return removeIndexFromArray(idx, array);
  else {
    console.warn(
      `removeObjectWithKeyFromArray: Object with ${keyName}=${keyValue} was not found in ${array}`
    );
    return array;
  }
};
