import { useParams } from "react-router";
import { useAddOfferMutation } from "../../projects/projectsSlice";
import useInput from "../../../hooks/useInput";
import { groupsApiSlice } from "../../projectGroups/groupsSlice";
import { useDispatch } from "react-redux";
import useAlerts from "../../alerts/useAlerts";

const usePriceProvider = (project, onClose) => {
  const { onError } = useAlerts();
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const [addOffer, { isLoading }] = useAddOfferMutation();
  const amountInput = useInput("");
  const commentInput = useInput("");

  const handleSubmit = async () => {
    try {
      const id = project?.id;
      await addOffer({
        params: { id },
        body: {
          amount: amountInput.value,
          notes: commentInput.value,
          vat_included: false,
          currency: "EUR",
        },
      }).unwrap();
      dispatch(
        groupsApiSlice.util.invalidateTags([{ type: "Group", id: groupId }])
      );
    } catch (err) {
      onError(err);
    }
  };

  const handleCancel = () => {
    if (typeof onClose === "function") onClose();
    amountInput.reset();
    commentInput.reset();
  };

  return {
    handleSubmit,
    handleCancel,
    amountInput,
    commentInput,
    isLoading,
  };
};

export default usePriceProvider;
