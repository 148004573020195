import useLang from "../../hooks/useLang";
import { PageHeader } from "../../components/Header";
import { BundleGroup, SplitGroup } from "../../components/Group";
import { SecondaryParagraph } from "../../components/Paragraph";
import ProfilePatcher from "./ProfilePatcher";
import EmailAdder from "./EmailAdder";
import Button from "../../components/Button";
import useMyProfile from "./hooks/useMyProfile";
import Modal from "../../components/Modal";
import { DialogFooter } from "../../components/Footer";

const MyProfile = () => {
  const lang = useLang("profile");
  const { myProfile, showSuccessMessage, onHideSuccessMessage } =
    useMyProfile();

  const emailList = myProfile?.emails
    ?.filter((email) => email !== myProfile?.primary_email)
    .map((email, idx) => {
      return (
        <BundleGroup key={email} style={{ marginTop: "var(--spacing-3)" }}>
          <BundleGroup width={40}>
            {idx === 0 ? (
              <SecondaryParagraph>{lang("other_emails")}</SecondaryParagraph>
            ) : null}
          </BundleGroup>
          {email}
        </BundleGroup>
      );
    });

  return (
    <>
      <PageHeader>
        <h1>{lang("profile")}</h1>
        <SplitGroup />
        <ProfilePatcher
          renderOpen={(onClick) => (
            <Button icon={"pencil"} important onClick={onClick}>
              {lang("edit_profile")}
            </Button>
          )}
        />
      </PageHeader>
      {myProfile?.picture ? (
        <>
          <img src={myProfile?.picture} alt={lang("profile_picture")} />
          <br />
          <br />
        </>
      ) : null}
      {myProfile ? (
        <BundleGroup style={{ marginTop: "var(--spacing-3)" }}>
          <BundleGroup width={40}>
            <SecondaryParagraph>{lang("username")}</SecondaryParagraph>
          </BundleGroup>
          {myProfile?.username}
        </BundleGroup>
      ) : null}
      <BundleGroup style={{ marginTop: "var(--spacing-3)" }}>
        <BundleGroup width={40}>
          <SecondaryParagraph>{lang("primary_email")}</SecondaryParagraph>
        </BundleGroup>
        {myProfile?.primary_email}
      </BundleGroup>
      {emailList}
      <BundleGroup style={{ marginTop: "var(--spacing-3)" }}>
        <BundleGroup width={40}>
          {emailList?.length > 0 ? null : (
            <SecondaryParagraph>{lang("other_emails")}</SecondaryParagraph>
          )}
        </BundleGroup>
        <EmailAdder
          renderOpen={(onClick) => (
            <Button onClick={onClick}>{lang("add_email")}</Button>
          )}
        />
      </BundleGroup>
      <Modal isOpen={showSuccessMessage} onClose={onHideSuccessMessage}>
        {lang("email_confirmed")}
        <DialogFooter>
          <Button important onClick={onHideSuccessMessage}>
            {lang("Close")}
          </Button>
        </DialogFooter>
      </Modal>
    </>
  );
};

export default MyProfile;
