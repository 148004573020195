import { useRef } from "react";
import useSearchParams from "../../../hooks/useSearchParams";
import useLang from "../../../hooks/useLang";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const useSlideshowControls = (frames) => {
  const lang = useLang();
  const framesRef = useRef();
  const slideIdRef = useRef();
  framesRef.current = frames;
  const { getParam, setParams, clearParams } = useSearchParams();
  const slideId = getParam("frame");
  const showIssues = getParam("showIssues");
  slideIdRef.current = slideId;
  const slide = frames?.find((frame) => frame.id === slideId);
  const navigate = useNavigate();
  const location = useLocation();
  const origin = location?.state?.origin;

  const getCurrentIndex = () => {
    const idx = framesRef.current.findIndex(
      (frame) => frame.id === slideIdRef.current
    );
    return idx;
  };

  const onClose = () => {
    if (origin) {
      navigate(origin);
    } else {
      clearParams();
    }
  };

  const onPrevious = () => {
    const idx = getCurrentIndex();
    if (idx > 0)
      setParams({ frame: framesRef.current[idx - 1].id, showIssues });
    else
      setParams({
        frame: framesRef.current[framesRef.current.length - 1].id,
        showIssues,
      });
  };

  const onNext = () => {
    const idx = getCurrentIndex();
    if (idx < framesRef.current.length - 1)
      setParams({ frame: framesRef.current[idx + 1].id, showIssues });
    else setParams({ frame: framesRef.current[0].id, showIssues });
  };

  return {
    fileName: `${
      slide?.original?.commonFile ? lang("common_files_dir") + "/" : ""
    }${slide?.original?.name}${
      slide?.page_no ? " (" + lang("page_num") + " " + slide?.page_no + ")" : ""
    }`,
    fileUrl: slide?.original?.url,
    pageNum: getCurrentIndex() + 1,
    pagesTotal: frames.length,
    onClose,
    onPrevious,
    onNext,
  };
};

export default useSlideshowControls;
