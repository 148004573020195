import { NavLink } from "react-router-dom";

import style from "./navigation.module.css";

const Logo = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 210 210"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_136_123)">
      <rect width="210" height="210" rx="105" fill="#F5F5F5" />
      <path
        d="M40.063 54.0071C62.2654 32.5269 166.453 26.5383 181.548 54.0069C196.643 81.4754 248.477 212.247 228.047 225.537C207.618 238.827 20.3007 237.373 -2.79118 213.449C-25.8831 189.525 17.8606 75.4874 40.063 54.0071Z"
        fill="#EABA20"
      />
      <path
        d="M-21.8206 -11.7851C99.8589 -94.02 150.766 18.4542 172.093 42.5467C193.421 66.6392 180.713 136.129 161.162 150.407C141.612 164.684 95.4011 161.115 88.2917 162.9C81.1824 164.684 78.5185 168.254 68.7431 174.5C58.9678 180.746 10.2757 189.049 -0.388312 181.018C-11.0523 172.987 -143.5 70.4498 -21.8206 -11.7851Z"
        fill="#616E43"
      />
      <path
        d="M190.045 -12.7992C222.822 3.98096 336.097 145.925 145.041 145.925C89.8525 145.925 90.0052 179.853 77.6031 177C65.2009 174.147 67.6282 165.981 59.2559 154.5C50.8837 143.019 45.9384 98.1831 42.3966 92C38.8547 85.8169 23.3808 72.5513 39.9946 48.7734C107.607 -47.9947 157.267 -29.5794 190.045 -12.7992Z"
        fill="#1D3D59"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.4728 60.6967C36.4406 57.9544 38.3182 55.695 40.0629 54.0071C62.2653 32.5269 166.453 26.5383 181.548 54.0069C189.582 68.6268 208.024 112.51 220.438 152.369C203.885 159.892 180.576 164.36 148.688 164.36C113.602 164.36 94.8426 173.723 82.6443 179.811C75.6548 183.299 70.8196 185.712 66.3019 184.673C59.3977 183.084 59.2062 179.433 58.9488 174.526C58.7438 170.619 58.4971 165.917 54.7856 160.827C50.0476 154.329 45.3662 152.393 41.5527 150.816C38.6276 149.607 36.2131 148.608 34.6756 145.924C31.6181 140.587 25.4226 89.787 34.4728 60.6967Z"
        fill="#E17E23"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.0946 63.2006C35.7263 59.2515 38.2269 56.0779 40.5015 53.876C60.4629 34.553 146.684 27.7572 174.877 46.5475C191.808 73.612 179.426 136.787 160.894 150.326C146.323 160.972 116.941 161.698 99.9808 162.117H99.9807C94.1883 162.26 89.8449 162.368 88.0353 162.822C83.2707 164.019 80.5031 166.017 76.2526 169.087L76.2524 169.087C74.1617 170.597 71.7122 172.366 68.4898 174.426C66.5697 175.653 63.148 176.96 58.808 178.207C58.5895 177.032 58.5215 175.737 58.4482 174.34C58.2428 170.427 57.9957 165.716 54.2766 160.618C49.5291 154.11 44.8383 152.171 41.0172 150.591C38.0861 149.38 35.6668 148.38 34.1262 145.691C31.1611 140.517 25.2508 92.6881 33.0946 63.2006Z"
        fill="#F8F7F7"
      />
    </g>
    <defs>
      <clipPath id="clip0_136_123">
        <rect width="210" height="210" rx="105" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Logotype = () => (
  <svg
    width="80"
    height="20"
    viewBox="0 0 840 210"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_134_2)">
      <rect x="453.25" width="211.75" height="210" rx="105" fill="#F5F5F5" />
      <path
        d="M493.647 54.0071C516.034 32.5269 621.09 26.5383 636.311 54.0069C651.532 81.4754 703.797 212.247 683.197 225.537C662.598 238.827 473.72 237.373 450.435 213.449C427.151 189.525 471.259 75.4874 493.647 54.0071Z"
        fill="#EABA20"
      />
      <path
        d="M431.248 -11.7851C553.941 -94.02 605.272 18.4542 626.777 42.5467C648.283 66.6392 635.469 136.129 615.755 150.407C596.042 164.684 549.446 161.115 542.277 162.9C535.109 164.684 532.423 168.254 522.566 174.5C512.709 180.746 463.611 189.049 452.858 181.018C442.106 172.987 308.554 70.4498 431.248 -11.7851Z"
        fill="#616E43"
      />
      <path
        d="M644.878 -12.7992C677.929 3.98096 792.148 145.925 599.5 145.925C543.851 145.925 544.005 179.853 531.5 177C518.994 174.147 521.442 165.981 513 154.5C504.558 143.019 499.571 98.1831 496 92C492.429 85.8169 476.826 72.5513 493.578 48.7734C561.754 -47.9947 611.828 -29.5794 644.878 -12.7992Z"
        fill="#1D3D59"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M488.01 60.6968C489.994 57.9545 491.888 55.695 493.647 54.0071C516.034 32.5269 621.09 26.5383 636.311 54.0069C644.412 68.6268 663.007 112.51 675.525 152.369C658.835 159.892 635.331 164.36 603.177 164.36C567.799 164.36 548.883 173.723 536.583 179.811C529.535 183.299 524.66 185.712 520.105 184.673C513.143 183.084 512.95 179.433 512.69 174.526C512.483 170.619 512.235 165.917 508.492 160.827C503.715 154.329 498.994 152.393 495.149 150.816C492.2 149.607 489.765 148.608 488.215 145.924C485.132 140.587 478.885 89.7871 488.01 60.6968Z"
        fill="#E17E23"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M486.62 63.2006C489.274 59.2515 491.796 56.0779 494.089 53.876C514.217 34.553 601.156 27.7572 629.584 46.5475C646.657 73.612 634.171 136.787 615.485 150.326C600.793 160.972 571.166 161.698 554.064 162.117C548.223 162.26 543.844 162.368 542.019 162.822C537.215 164.019 534.424 166.017 530.138 169.087L530.138 169.087C528.03 170.597 525.56 172.366 522.311 174.426C520.375 175.653 516.924 176.96 512.548 178.207C512.328 177.032 512.259 175.737 512.185 174.34C511.978 170.427 511.729 165.716 507.979 160.618C503.192 154.11 498.462 152.171 494.609 150.591C491.654 149.38 489.214 148.38 487.661 145.691C484.671 140.517 478.711 92.6881 486.62 63.2006Z"
        fill="#F8F7F7"
      />
    </g>
    <path
      d="M7 206.89V6.60994H41.2174V98.6642L127.232 6.60994H166.786L92.3865 87.4684L169.925 206.89H133.51L71.0398 108.927L41.2174 140.648V206.89H7Z"
      fill="#1D3D59"
    />
    <path d="M203.2 206.89V6.60994H237.418V206.89H203.2Z" fill="#1D3D59" />
    <path
      d="M277.082 206.89V6.60994H367.178C382.664 6.60994 395.43 9.51255 405.476 15.3178C415.521 21.123 422.951 29.1052 427.764 39.2643C432.578 49.2161 434.984 60.6192 434.984 73.4736C434.984 93.3773 428.706 109.342 416.149 121.367C403.802 133.392 387.059 139.404 365.922 139.404H311.3V206.89H277.082ZM311.3 112.659H359.643C373.247 112.659 383.501 109.134 390.408 102.085C397.314 95.0359 400.767 85.4987 400.767 73.4736C400.767 60.6192 397.419 50.6674 390.722 43.6182C384.234 36.569 374.398 33.0444 361.213 33.0444H311.3V112.659Z"
      fill="#1D3D59"
    />
    <path
      d="M753.264 210C727.104 210 706.071 206.683 690.166 200.048V170.193C699.165 173.51 708.792 176.205 719.047 178.279C729.511 180.352 739.87 181.389 750.125 181.389C766.24 181.389 778.273 179.523 786.226 175.791C794.388 172.059 798.469 164.595 798.469 153.399C798.469 146.557 796.794 141.063 793.446 136.916C790.097 132.77 784.552 129.245 776.808 126.343C769.065 123.233 758.392 120.019 744.788 116.702C723.023 111.104 707.746 104.055 698.956 95.5542C690.166 86.8464 685.771 74.925 685.771 59.7899C685.771 42.1669 692.259 28.3795 705.234 18.4277C718.21 8.47591 737.568 3.5 763.31 3.5C775.029 3.5 786.017 4.32932 796.271 5.98796C806.735 7.64659 814.897 9.51256 820.757 11.5858V41.4413C804.852 35.4287 787.272 32.4224 768.018 32.4224C752.95 32.4224 741.231 34.3921 732.859 38.3313C724.488 42.2706 720.302 49.4235 720.302 59.7899C720.302 65.8025 721.767 70.6747 724.697 74.4066C727.627 78.1386 732.65 81.3522 739.766 84.0474C747.09 86.7427 757.136 89.749 769.902 93.0663C786.435 97.2129 799.201 102.189 808.2 107.994C817.409 113.592 823.792 120.226 827.349 127.898C831.116 135.361 833 143.862 833 153.399C833 171.022 826.408 184.913 813.223 195.072C800.038 205.024 780.052 210 753.264 210Z"
      fill="#1D3D59"
    />
    <defs>
      <clipPath id="clip0_134_2">
        <rect x="453.25" width="211.75" height="210" rx="105" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const HomeLink = ({ expanded }) => {
  return (
    <>
      <NavLink to="/" className={style.homeLink}>
        {expanded ? <Logotype /> : <Logo />}
      </NavLink>
    </>
  );
};

export default HomeLink;
