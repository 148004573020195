import useSlideActions from "./hooks/useSlideActions";
import Button from "../../components/Button";
import IssueCreator from "../issues/IssueCreator";
import useLang from "../../hooks/useLang";
import useScreenSize from "../../hooks/useScreenSize";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import { useParams } from "react-router-dom";
import styles from "./slideActions.module.css";

const SlideActions = () => {
  const lang = useLang();
  const { marker, toggleMarker } = useSlideActions();
  const { isMobile } = useScreenSize();
  const { projectId } = useParams();

  if (!projectId) return null;
  return (
    <div className={styles.slideActions}>
      <AccessWrapper action={userActions.POST_ISSUE}>
        <Button
          rounded
          icon={"marker"}
          emphasized={!marker}
          important={marker}
          onClick={toggleMarker}
        >
          {!isMobile ? lang("mark") : null}
        </Button>
      </AccessWrapper>
      <IssueCreator
        renderOpen={(onClick) => (
          <Button rounded emphasized icon={"addIssue"} onClick={onClick}>
            {!isMobile ? lang("create_issue") : null}
          </Button>
        )}
      />
    </div>
  );
};

export default SlideActions;
