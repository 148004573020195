import { SecondaryParagraph } from "../../components/Paragraph";
import useLang from "../../hooks/useLang";
import styles from "./specsTable.module.css";

const TotalPrice = ({ group }) => {
  const lang = useLang();

  return (
    <>
      {group?.total?.before_vat != null ? (
        <div
          style={{
            marginTop: "var(--spacing-4)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <SecondaryParagraph>{`${lang(
              "total_vat_excluded"
            )}:`}</SecondaryParagraph>
            <div className={styles.totalsCell}>{`${
              Math.round(group?.total?.before_vat * 100) / 100
            } ${group?.total?.currency}`}</div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SecondaryParagraph>{`${lang("vat")}:`}</SecondaryParagraph>
            <div className={styles.totalsCell}>{`${
              Math.round(group?.total?.vat * 100) / 100
            } ${group?.total?.currency}`}</div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SecondaryParagraph>{`${lang(
              "total_vat_included"
            )}:`}</SecondaryParagraph>
            <div className={styles.totalsCell}>{`${
              Math.round(group?.total?.total_w_vat * 100) / 100
            } ${group?.total?.currency}`}</div>
          </div>
        </div>
      ) : (
        <>
          {group?.total?.comment ? (
            <div
              style={{
                marginTop: "var(--spacing-4)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <SecondaryParagraph>{group?.total?.comment}</SecondaryParagraph>
              <br />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default TotalPrice;
