import { useState, useRef } from "react";

const useTransformer = (canvasSize, imageSize, onCenter) => {
  const [scale, setScale] = useState(1);
  const closestScaleRef = useRef();
  let translateX = -50;
  let translateY = -50;

  const findClosestScale = (scale) => {
    let multiplier;
    let closestScale = 1;
    if (scale === 1) return closestScale;
    if (scale > 1) multiplier = 1.25;
    else multiplier = 0.8;
    let found = false;
    while (!found) {
      const nextClosest = closestScale * multiplier;
      const lowerLimit = Math.min(closestScale, nextClosest);
      const upperLimit = Math.max(closestScale, nextClosest);
      if (scale >= lowerLimit && scale <= upperLimit) {
        if (scale - lowerLimit < upperLimit - scale) closestScale = lowerLimit;
        else closestScale = upperLimit;
        found = true;
      } else {
        closestScale = nextClosest;
      }
    }
    return closestScale;
  };

  const onZoomIn = () => {
    setScale(
      (state) =>
        (closestScaleRef.current ? closestScaleRef.current : state) * 1.25
    );
    closestScaleRef.current = null;
  };

  const onZoomOut = () => {
    setScale(
      (state) =>
        (closestScaleRef.current ? closestScaleRef.current : state) * 0.8
    );
    closestScaleRef.current = null;
  };

  const onZoomToFit = () => {
    const horizontalScaleToFit = canvasSize.width / imageSize.naturalWidth;
    const verticalScaleToFit = canvasSize.height / imageSize.naturalHeight;
    if (horizontalScaleToFit && verticalScaleToFit) {
      closestScaleRef.current = findClosestScale(
        Math.min(horizontalScaleToFit, verticalScaleToFit)
      );
      setScale(Math.min(horizontalScaleToFit, verticalScaleToFit));
    }
    onCenter();
  };

  return {
    parentTransform: {
      transform: `translateX(${translateX}%) translateY(${translateY}%) scale(${scale})`,
    },
    childTransform: {
      transform: `scale(${1 / scale})`,
      transformOrigin: "top left",
    },
    onZoomIn,
    onZoomOut,
    onZoomToFit,
  };
};

export default useTransformer;
