import useLang from "../../hooks/useLang";
import useMembersList from "./hooks/useMembersList";
import ErrorBoundary from "../../components/ErrorBoundary";
import { PageHeader } from "../../components/Header";
import { PageHeaderGroup, PageHeaderButtonGroup } from "../../components/Group";
import MemberExcerpt from "./MemberExcerpt";
import Button from "../../components/Button";
import MemberAdder from "./MemberAdder";
import useScreenSize from "../../hooks/useScreenSize";
import ProjectSwitch from "../projects/ProjectSwitch";

const MembersList = () => {
  const lang = useLang();
  const { users, isLoading, isSuccess } = useMembersList();
  const { isSmallScreen } = useScreenSize();

  let content;
  if (isLoading) {
    content = <p>{lang("Loading")}</p>;
  } else if (isSuccess) {
    content = users?.map((user, index) => (
      <MemberExcerpt key={`${user.id}-${index}`} member={user} />
    ));
  }

  return (
    <>
      <ProjectSwitch />
      <PageHeader>
        <PageHeaderGroup>
          <h2>{lang("members")}</h2>
        </PageHeaderGroup>
        <PageHeaderButtonGroup>
          <MemberAdder
            loader={
              <Button important loader disabled>
                {isSmallScreen ? null : lang("add_member")}
              </Button>
            }
            renderOpen={(onClick) => (
              <Button important icon={"addMember"} onClick={onClick}>
                {isSmallScreen ? null : lang("add_member")}
              </Button>
            )}
          />
        </PageHeaderButtonGroup>
      </PageHeader>
      <ErrorBoundary fallback="Error in project specs">{content}</ErrorBoundary>
    </>
  );
};

export default MembersList;
