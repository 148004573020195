import useInput from "../../../hooks/useInput";
import { getErrorMessage } from "../../../utils/errorUtils";
import useProjectCreate from "./useProjectCreate";
import usePatchableInputs from "./usePatchableInputs";
import useLang from "../../../hooks/useLang";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useGetRecipesQuery } from "../../recipes/recipesSlice";

const useProjectCreator = () => {
  const lang = useLang();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [loadingStarted, setLoadingStarted] = useState(false);
  const [loadingFinished, setLoadingFinished] = useState(false);
  const titleInput = useInput("");
  const { data: templatesFromApi } = useGetRecipesQuery();
  const emptyTemplate = { name: lang("type_not_selected"), id: null };
  const templates = Array.isArray(templatesFromApi)
    ? [emptyTemplate, ...templatesFromApi]
    : emptyTemplate;
  const [selectedTemplate, setSelectedTemplate] = useState(
    Array.isArray(templates) ? templates[0] : {}
  );
  const patchableInputs = usePatchableInputs(null, selectedTemplate);

  const body = {
    name: titleInput.value,
    recipe_id: selectedTemplate?.id,
    specifications: patchableInputs?.specifications,
    notes: patchableInputs?.notes || "",
  };
  const { handleSubmit, isLoading, response, error } = useProjectCreate(body);
  const handleCancel = () => {
    titleInput.reset();
    patchableInputs?.reset();
    setSelectedTemplate(emptyTemplate);
  };

  useEffect(() => {
    if (isLoading) {
      setLoadingStarted(true);
      setLoadingFinished(false);
    } else if (!isLoading && loadingStarted) {
      setLoadingStarted(false);
      setLoadingFinished(true);
    }
    // eslint-disable-next-line
  }, [isLoading, loadingStarted]);

  useEffect(() => {
    if (loadingFinished) {
      if (response?.project_id) {
        navigate(`/${groupId}/${response?.project_id}`);
      }
    }
    // eslint-disable-next-line
  }, [loadingFinished]);

  useEffect(() => {
    patchableInputs?.reset();
    // eslint-disable-next-line
  }, [selectedTemplate]);

  useEffect(() => {
    if (Array.isArray(templates)) {
      setSelectedTemplate(templates[0]);
    }
    // eslint-disable-next-line
  }, [templatesFromApi]);

  return {
    modalTitle: lang("add_project_title"),
    submitLabel: lang("add_project"),
    handleSubmit,
    handleCancel,
    isLoading,
    titleInput,
    ...patchableInputs,
    errMsg: getErrorMessage(error),
    show: true,
    templates: Array.isArray(templates) ? templates : [],
    onTemplateSelect: setSelectedTemplate,
    selectedTemplate,
  };
};

export default useProjectCreator;
