import useProject from "../../projects/hooks/useProject";
import useLang from "../../../hooks/useLang";

const useIssuesByStatus = () => {
  const lang = useLang();
  const { project, isLoading } = useProject();

  const allIssues = Array.isArray(project?.issues)
    ? project?.issues?.map((issue) => ({
        ...issue,
        title: issue?.title ? issue?.title : lang("no_title"),
      }))
    : [];

  const openIssues = allIssues.filter((issue) => issue.status === "OPEN");
  const closedIssues = allIssues.filter((issue) => issue.status === "CLOSED");
  const unseenIssues = allIssues.filter((issue) => issue.unseen);

  return { allIssues, openIssues, closedIssues, unseenIssues, isLoading };
};

export default useIssuesByStatus;
