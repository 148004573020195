import { useState } from "react";
import useProject from "./hooks/useProject";
import Popup from "../../components/Popup";
import Button from "../../components/Button";
import Link from "../../components/Link";
import { useParams } from "react-router";
import useGroup from "../projectGroups/hooks/useGroup";
import { ProjectCreatorControlled } from "./ProjectCreator";
import useLang from "../../hooks/useLang";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import { useLocation } from "react-router-dom";

const ProjectSwitch = () => {
  const [creatorOpen, setCreatorOpen] = useState(false);
  const { groupId } = useParams();
  const { project } = useProject();
  const { group } = useGroup();
  const lang = useLang();
  const location = useLocation();
  const pathArrayFromLocation = location.pathname.split("/").slice(3);

  const siblingLinks = group?.projects?.map((sibling) => (
    <Link
      key={sibling.id}
      to={`/${groupId}/${sibling.id}/${pathArrayFromLocation.join("/")}`}
    >
      {sibling.name}
    </Link>
  ));

  return (
    <div
      style={{
        display: "flex",
        marginBottom: "var(--spacing-2)",
        gap: "var(--spacing-2)",
      }}
    >
      <h1>{project?.name}</h1>
      {project?.id ? (
        <Popup
          expandBottomRight
          renderOpen={(onClick) => {
            return <Button rounded onClick={onClick} icon={"chevronDown"} />;
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "var(--spacing-2)",
              flexDirection: "column",
            }}
          >
            {siblingLinks}
            <AccessWrapper action={userActions.CREATE_PROJECT}>
              <Button
                emphasized
                onClick={() => {
                  setCreatorOpen(true);
                }}
              >
                {lang("add_project")}
              </Button>
            </AccessWrapper>
          </div>
        </Popup>
      ) : null}
      <ProjectCreatorControlled
        isOpen={creatorOpen}
        handleClose={() => {
          setCreatorOpen(false);
        }}
        handleCancel={() => {
          setCreatorOpen(false);
        }}
      />
    </div>
  );
};

export default ProjectSwitch;
