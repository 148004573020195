import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { CenteredLayout } from "../../components/Layout";

import useAuth from "./useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";
import { logOut } from "./authSlice";

const Login = () => {
  const [origin, setOrigin] = useLocalStorage("origin") || "/";
  const [originSearch, setOriginSearch] = useLocalStorage("search");
  const { setRefreshToken, getLoginUrl } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const fromSearch = location.state?.from?.search;
  const dispatch = useDispatch();

  if (from !== origin || originSearch !== fromSearch) {
    setOrigin(from);
    setOriginSearch(fromSearch);
  }
  useEffect(() => {
    setRefreshToken(null);
    dispatch(logOut());
    window.location.href = getLoginUrl();
    // eslint-disable-next-line
  }, []);

  let content = null;
  return <CenteredLayout>{content}</CenteredLayout>;
};

export default Login;
