import useLang from "../../hooks/useLang";
import { FormModal } from "../../components/Modal";
import Form from "../../components/Form";
import TextArea from "../../components/TextArea";
import useCommentCreator from "./hooks/useCommentCreator";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const CommentCreator = ({ renderOpen, loader }) => {
  const lang = useLang();
  const { handleAddComment, handleCancel, commentInput, isLoading } =
    useCommentCreator();

  if (isLoading) return loader;
  return (
    <>
      <AccessWrapper action={userActions.POST_COMMENT}>
        <FormModal
          title={lang("create_new_comment_title")}
          onSubmit={handleAddComment}
          onCancel={handleCancel}
          submitLabel={lang("add_comment")}
          renderOpen={renderOpen}
        >
          <Form modal>
            <TextArea
              placeholder={lang("notes_placeholder")}
              {...commentInput.attributes}
            />
          </Form>
        </FormModal>
      </AccessWrapper>
    </>
  );
};

export default CommentCreator;
