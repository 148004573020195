import { useParams } from "react-router";
import { groupsApiSlice } from "../../projectGroups/groupsSlice";
import { useDispatch } from "react-redux";
import { useAddNewProjectMutation } from "../projectsSlice";
import { useState } from "react";

const useProjectCreate = (body) => {
  const { groupId } = useParams();
  const [response, setResponse] = useState({});
  const dispatch = useDispatch();
  const [addNewProject, { isLoading, error }] = useAddNewProjectMutation();

  const handleSubmit = async () => {
    try {
      const responseFromApi = await addNewProject({
        params: { id: groupId },
        body,
      }).unwrap();
      setResponse(responseFromApi);
      dispatch(
        groupsApiSlice.util.invalidateTags([{ type: "Group", id: groupId }])
      );
    } catch (err) {
      console.error(err);
    }
  };

  return { handleSubmit, isLoading, response, error };
};

export default useProjectCreate;
