import useStatusChanger from "./hooks/useStatusChanger";
import { StatusChip } from "../../components/Chip";
import useLang from "../../hooks/useLang";
import Popup from "../../components/Popup";
import { ToggleChip } from "../../components/Chip";
import { TertiaryParagraph } from "../../components/Paragraph";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import { ConfirmationModal } from "../../components/Modal";
import usePolicyChecker from "../auth/usePolicyChecker";

const StatusChanger = ({ group, status }) => {
  const lang = useLang();
  const { modifiers, isLoading } = useStatusChanger(group, status);
  const showStatusChanger = usePolicyChecker({
    action: userActions.PATCH_GROUP_STATUS,
  });

  if (!status) return null;
  if (isLoading) return <p>{lang("loading")}</p>;

  const changeButtons = modifiers
    ? modifiers?.map((modifier) => {
        if (modifier?.status === "CANCELLED") {
          return (
            <ConfirmationModal
              key={modifier.label}
              title={lang("cancel_group_confirmation_title")}
              onSubmit={modifier.onClick}
              renderOpen={(onClick) => (
                <ToggleChip key={modifier.label} onClick={onClick} small>
                  {modifier.label}
                </ToggleChip>
              )}
            >
              <p>{lang("cancel_group_confirmation_text")}</p>
            </ConfirmationModal>
          );
        }
        return (
          <ToggleChip key={modifier.label} onClick={modifier.onClick} small>
            {modifier.label}
          </ToggleChip>
        );
      })
    : null;

  const statusChangerContent = (
    <Popup
      expandBottomRight
      renderOpen={(onClick) => (
        <StatusChip
          small
          status={status}
          onClick={onClick}
          style={{ cursor: "pointer" }}
        />
      )}
    >
      <div
        style={{
          display: "flex",
          gap: "var(--spacing-2)",
          flexDirection: "column",
          whiteSpace: "nowrap",
        }}
      >
        <TertiaryParagraph>
          {`${lang("change_project_status_to")}:`}
        </TertiaryParagraph>
        {changeButtons}
      </div>
    </Popup>
  );

  return (
    <>
      {showStatusChanger ? (
        <AccessWrapper action={userActions.PATCH_GROUP_STATUS}>
          {statusChangerContent}
        </AccessWrapper>
      ) : (
        <AccessWrapper action={userActions.GET_GROUP_STATUS}>
          <StatusChip small status={group?.status} />
        </AccessWrapper>
      )}
    </>
  );
};

export default StatusChanger;
