import IssuesExcerpt from "../issues/IssuesExcerpt";
import { PageHeader } from "../../components/Header";
import { PageHeaderGroup } from "../../components/Group";
import useLang from "../../hooks/useLang";
import IssueCreator from "../issues/IssueCreator";
import { PageFooter } from "../../components/Footer";
import Link from "../../components/Link";
import Button from "../../components/Button";
import useMarkingMode from "../marker/hooks/useMarkingMode";

const AsideIssuesPanel = ({ issues }) => {
  const lang = useLang();
  const { marker } = useMarkingMode();

  const content = issues?.map((issue) => (
    <IssuesExcerpt
      key={issue.id}
      issue={issue}
      renderLink={() => (
        <Link to={`${issue?.link}`}>
          <h3
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >{`${issue?.iid}: ${issue?.title}`}</h3>
        </Link>
      )}
    />
  ));

  return (
    <>
      <PageHeader>
        <PageHeaderGroup>
          <h2 style={{ marginTop: "var(--spacing-2)" }}>{lang("issues")}</h2>
        </PageHeaderGroup>
      </PageHeader>
      {content}
      <PageFooter>
        <IssueCreator
          renderOpen={(onClick) => (
            <Button
              important={!marker}
              icon={"addIssue"}
              emphasized={marker}
              onClick={onClick}
            >
              {lang("create_issue")}
            </Button>
          )}
        />
      </PageFooter>
    </>
  );
};

export default AsideIssuesPanel;
