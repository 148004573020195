import useMarkable from "./hooks/useMarkable";
import { IssueCreatorControlled } from "../issues/IssueCreator";
import Markers from "./Markers";
import ZoomControls from "./ZoomControls";
import classNames from "classnames";

// eslint-disable-next-line
import markerCursor from "./markerCursor.svg";
import styles from "./markable.module.css";

const Markable = ({ frame }) => {
  const {
    imageRef,
    canvasRef,
    markers,
    onAddMarker,
    isLoading,
    markingMode,
    creatorProps,
    figureStyle,
    markerStyle,
    dragActions,
    zoomActions,
  } = useMarkable(frame);

  return (
    <>
      <IssueCreatorControlled {...creatorProps} />
      <div className={styles.markableWrapper}>
        <div ref={canvasRef} className={styles.canvas} {...dragActions}>
          <figure
            className={classNames(styles.markable, {
              [styles.markingMode]: markingMode,
            })}
            style={figureStyle}
          >
            <img
              ref={imageRef}
              src={frame?.hires?.url}
              alt={frame?.original?.name}
              onClick={onAddMarker}
            />
            <Markers
              markers={markers}
              markerStyle={markerStyle}
              isLoading={isLoading}
            />
          </figure>
        </div>
        <ZoomControls {...zoomActions} />
      </div>
    </>
  );
};

export default Markable;
