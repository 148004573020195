import { useEffect, useRef, useState } from "react";

const MOBILE_LARGE = 640;
const TABLET_MIN = 1024; // 768;
const DESKTOP_MED = 992;
const DESKTOP_LARGE = 1200;
const DESKTOP_XL = 2400;

const useScreenSize = () => {
  const screenSizeRef = useRef({});
  const [screenSize, _setScreenSize] = useState({});
  const setScreenSize = (value) => {
    if (JSON.stringify(screenSizeRef.current) !== JSON.stringify(value)) {
      screenSizeRef.current = value;
      _setScreenSize(value);
    }
  };

  const handleResize = () => {
    if (window.innerWidth < MOBILE_LARGE)
      setScreenSize({ isSmallScreen: true, isMobile: true });
    else if (window.innerWidth < TABLET_MIN) setScreenSize({ isMobile: true });
    else if (window.innerWidth < DESKTOP_MED) setScreenSize({ isTablet: true });
    else if (window.innerWidth < DESKTOP_LARGE)
      setScreenSize({ isLaptop: true });
    else if (window.innerWidth < DESKTOP_XL) setScreenSize({ isDesktop: true });
    else setScreenSize({ isBigScreen: true });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  return screenSize;
};

export default useScreenSize;
