import { useRef, useEffect } from "react";
import classNames from "classnames";

import styles from "./errorMessage.module.css";

const ErrorMessage = ({ errMsg, ...rest }) => {
  const errRef = useRef();

  useEffect(() => {
    if (errMsg) errRef?.current?.focus();
  }, [errMsg]);

  // tabIndex="-1" prop is important. Focusing on ErrorMessage does not work without it.
  return (
    <p
      ref={errRef}
      className={classNames(styles.errorMessage, {
        [styles.offscreen]: !errMsg,
      })}
      {...rest}
      aria-live="assertive"
      tabIndex="-1"
    >
      {errMsg}
    </p>
  );
};

export default ErrorMessage;
