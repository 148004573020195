import useSlideshowControls from "./hooks/useSlideshowControls";
import { BundleGroup } from "../../components/Group";
import Button from "../../components/Button";
import classNames from "classnames";
import useLang from "../../hooks/useLang";

import styles from "./slideshowControls.module.css";

const SlideshowControls = ({ frames, currentFrame }) => {
  const lang = useLang();
  const { fileName, pageNum, pagesTotal, onClose, onPrevious, onNext } =
    useSlideshowControls(frames);

  return (
    <div
      className={classNames(styles.slideshowControls, {
        [styles.obsolete]: currentFrame?.obsolete,
      })}
    >
      <div className={styles.controlsSection}>
        {!currentFrame?.obsolete ? (
          <p>{fileName}</p>
        ) : (
          <p>{lang("file_deleted")}</p>
        )}
      </div>
      <div className={styles.controlsSection}>
        <BundleGroup>
          {pagesTotal > 1 ? (
            <Button rounded icon={"chevronLeft"} onClick={onPrevious} />
          ) : null}
          {pagesTotal > 1 ? <p>{`${pageNum} iš ${pagesTotal}`}</p> : null}
          {pagesTotal > 1 ? (
            <Button rounded icon={"chevronRight"} onClick={onNext} />
          ) : null}
        </BundleGroup>
      </div>
      <div className={styles.controlsSection}>
        <Button rounded icon={"cross"} onClick={onClose} />
      </div>
    </div>
  );
};

export default SlideshowControls;
