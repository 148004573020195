import { usePatchSpecificationsMutation } from "../projectsSlice";
import { groupsApiSlice } from "../../projectGroups/groupsSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const useProjectPatch = (id, body) => {
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const [patchSpecifications, { isLoading, error }] =
    usePatchSpecificationsMutation();

  const handleSubmit = async () => {
    try {
      await patchSpecifications({ params: { id }, body }).unwrap();
      dispatch(
        groupsApiSlice.util.invalidateTags([{ type: "Group", id: groupId }])
      );
    } catch (err) {
      console.error(err);
    }
  };

  return { handleSubmit, isLoading, error };
};

export default useProjectPatch;
