import useSharedGroups from "./hooks/useSharedGroups";
import GroupPages from "./GroupPages";

const SharedGroupList = ({ show }) => {
  const groups = useSharedGroups(show);

  if (!show) return null;
  return <GroupPages groups={groups} />;
};

export default SharedGroupList;
