import Section from "../../components/Section";
import Avatar from "./Avatar";
import MemberRemover from "./MemberRemover";
import { BundleGroup, SplitGroup } from "../../components/Group";
import { RoleChip } from "../../components/Chip";
import useLang from "../../hooks/useLang";
import useSimpleDate from "../../hooks/useSimpleDate";
import { SecondaryParagraph } from "../../components/Paragraph";

const MemberExcerpt = ({ member }) => {
  const lang = useLang();
  const { getSimpleDate } = useSimpleDate();

  return (
    <Section>
      <SplitGroup>
        <BundleGroup>
          <BundleGroup width={48}>
            <Avatar initials={member.initials} user={member} />
            <p>{member.username}</p>
          </BundleGroup>
          <BundleGroup width={36}>
            <RoleChip>{lang(member?.role)}</RoleChip>
          </BundleGroup>
          <BundleGroup>
            {member?.last_visit ? (
              <SecondaryParagraph>
                {`${lang("last_visit")}: ${getSimpleDate(member?.last_visit)}`}
              </SecondaryParagraph>
            ) : null}
          </BundleGroup>
        </BundleGroup>
        <MemberRemover member={member} />
      </SplitGroup>
    </Section>
  );
};

export default MemberExcerpt;
