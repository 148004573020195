import { useParams } from "react-router-dom";
import useProject from "../../projects/hooks/useProject";
import { useGetIssueByIdQuery } from "../issuesSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const useIssueById = (issueId) => {
  const navigate = useNavigate();
  const { groupId, projectId } = useParams();
  const { project } = useProject();
  const { data, ...rest } = useGetIssueByIdQuery(issueId, {
    skip: !issueId,
  });

  const { ids, entities } = data || {};
  let issue = ids != null ? entities[ids] : {};
  if (issue.id !== issueId) issue = {};
  if (Object.keys(issue).length === 0 && Array.isArray(project?.issues)) {
    issue = project.issues.find((issue) => issue.id === issueId);
  }
  useEffect(() => {
    if (rest?.error?.status === 500) {
      navigate(`/${groupId}/${projectId}`);
    }
    // eslint-disable-next-line
  }, [rest?.isError]);

  const messagesWithindices = issue?.messages?.map((message, idx) => ({
    ...message,
    issue_id: issue.id,
    idx,
  }));
  const issueWithMessageIndices = { ...issue, messages: messagesWithindices };

  return { issue: issueWithMessageIndices, ...rest };
};

export default useIssueById;
