import useLang from "../../../hooks/useLang";
import useUsers from "../../users/hooks/useUsers";
import useInitials from "./useInitials";

const useCommentAuthor = (comment) => {
  const { users, isLoading } = useUsers();
  const lang = useLang();
  let name = lang("anonymous");
  const foundUser = users.find((user) => user.id === comment.author_id);
  name = foundUser?.username || name;
  const initials = useInitials(name);

  return {
    name,
    initials,
    author: { ...foundUser, userName: name },
    isLoading,
  };
};

export default useCommentAuthor;
