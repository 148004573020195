import useOffersForProject from "./hooks/useOffersForProject";
import { PageHeader } from "../../components/Header";
import OfferExcerpt from "./OfferExcerpt";
import useLang from "../../hooks/useLang";
import PriceProvider from "../projects/PriceProvider";
import Button from "../../components/Button";
import { BundleGroup } from "../../components/Group";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import useMyProfile from "../auth/useMyProfile";

const OffersForProject = ({ project, inColumns }) => {
  const lang = useLang();
  const { user } = useMyProfile();
  const { incommingOffers, outgoingOffers } = useOffersForProject(project);
  const showIncommingOffers = user?.role !== "SUPPLIER";

  if (project?.frozen) return null;

  let incommingOffersContent;
  if (showIncommingOffers) {
    incommingOffersContent = (
      <>
        {inColumns === true || incommingOffers.length > 0 ? (
          <PageHeader style={{ paddingLeft: "var(--spacing-1)" }}>
            <h2>{lang("incomming_offers")}</h2>
          </PageHeader>
        ) : null}
        {incommingOffers.length > 0 ? (
          <>
            {incommingOffers?.map((offer) => (
              <OfferExcerpt
                key={offer.id}
                offer={offer}
                vertical={inColumns}
                project={project}
              />
            ))}
            <br />
          </>
        ) : null}
      </>
    );
  }

  const outgoingOffersContent = (
    <>
      {inColumns === true || outgoingOffers.length > 0 ? (
        <PageHeader style={{ paddingLeft: "var(--spacing-1)" }}>
          <h2>{lang("outgoing_offers")}</h2>
        </PageHeader>
      ) : null}
      {outgoingOffers.length > 0 ? (
        <>
          {outgoingOffers?.map((offer) => (
            <OfferExcerpt
              key={offer.id}
              offer={offer}
              vertical={inColumns}
              project={project}
            />
          ))}
          <br />
        </>
      ) : null}
    </>
  );

  return (
    <>
      {inColumns && showIncommingOffers ? (
        <BundleGroup style={{ alignItems: "flex-start" }}>
          <div
            style={{
              width: "calc(50% - var(--spacing-1))",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {incommingOffersContent}
          </div>
          <div
            style={{
              width: "calc(50% - var(--spacing-1))",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {outgoingOffersContent}
          </div>
        </BundleGroup>
      ) : (
        <>
          {incommingOffersContent}
          {outgoingOffersContent}
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <AccessWrapper action={userActions.POST_OFFER} project={project}>
          <PriceProvider
            project={project || {}}
            renderOpen={(onClick) => (
              <Button onClick={onClick} rounded emphasized icon={"addOffer"}>
                {lang("add_offer")}
              </Button>
            )}
          />
        </AccessWrapper>
      </div>
    </>
  );
};

export default OffersForProject;
