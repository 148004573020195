import usePagination from "../../../hooks/usePagination";
import { useGetNextNumberQuery } from "../groupsSlice";

const useGroupPages = (group) => {
  let organizationId = null;
  let pageSizeInTab = 0;
  let totalEntriesInTab = 0;

  if (group?.data?.pagination) {
    organizationId = Object.keys(group?.data?.pagination)[0];
    pageSizeInTab = Object.values(group?.data?.pagination)[0].pageSize;
    totalEntriesInTab = Object.values(group?.data?.pagination)[0].totalEntries;
  }
  const { pageIndex, pageCount, goToNext, goToPrevious } = usePagination(
    totalEntriesInTab,
    pageSizeInTab
  );

  const { data: nextGroupNumber } = useGetNextNumberQuery(organizationId, {
    skip: organizationId == null || organizationId === "shared",
  });

  return {
    showPagination: pageCount > 1,
    disablePrev: pageIndex <= 1,
    disableNext: pageIndex >= pageCount,
    organizationId,
    nextGroupNumber,
    goToNext,
    goToPrevious,
  };
};

export default useGroupPages;
