import useModal from "../../../hooks/useModal";
import { useState } from "react";
import useMarkerIssues from "./useMarkerIssues";
import useFetchAfterMutationStatus from "../../../hooks/useFetchAfterMutationStatus";

const useMarkCreator = (imageSize) => {
  const [target, setTarget] = useState();
  const { isOpen, onOpen, onClose } = useModal();
  const [mutationLoading, setMutationLoading] = useState(false);
  const { isFetching: issuesFetching } = useMarkerIssues();
  const { isLoading } = useFetchAfterMutationStatus(
    mutationLoading,
    issuesFetching
  );

  const setTargetAndOpen = (event) => {
    const { clientX, clientY } = event;
    const {
      left: canvasLeft,
      top: canvasTop,
      width: canvasWidth,
      height: canvasHeight,
    } = event.currentTarget.getBoundingClientRect();

    const x = ((clientX - canvasLeft) / canvasWidth) * imageSize?.naturalWidth;
    const y = ((clientY - canvasTop) / canvasHeight) * imageSize?.naturalHeight;

    setTarget({ x, y });
    onOpen();
  };

  return [
    {
      isOpen,
      onOpen: setTargetAndOpen,
      onClose,
      target,
      onLoading: setMutationLoading,
    },
    { isLoading },
  ];
};

export default useMarkCreator;
