import Avatar from "../features/users/Avatar";
import { SecondaryParagraph } from "./Paragraph";

import styles from "./ownerBadge.module.css";

const OwnerBadge = ({ user }) => {
  return (
    <div className={styles.ownerBadge}>
      <Avatar user={user} small />
      <SecondaryParagraph>{user?.username}</SecondaryParagraph>
    </div>
  );
};

export default OwnerBadge;
