import Button from "../../components/Button";
import styles from "./zoomControls.module.css";

const ZoomControls = ({ onZoomIn, onZoomOut, onZoomToFit }) => {
  return (
    <div className={styles.zoomControls}>
      <Button rounded icon="plus" onClick={onZoomIn} />
      <Button rounded icon="fitToScreen" onClick={onZoomToFit} />
      <Button rounded icon="minus" onClick={onZoomOut} />
    </div>
  );
};

export default ZoomControls;
