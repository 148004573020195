import Section from "../../components/Section";
import CommentAuthor from "./CommentAuthor";
import VisibilitySensor from "../../components/VisibilitySensor";
import useSingleComment from "./hooks/useSingleComment";
import { BundleGroup } from "../../components/Group";
import { SecondaryParagraph } from "../../components/Paragraph";
import useSimpleDate from "../../hooks/useSimpleDate";

const SingleComment = ({ comment }) => {
  const { onVisible } = useSingleComment(comment);
  const { getSimpleDate } = useSimpleDate();

  return (
    <>
      <Section boxed emphasized={comment?.unseen}>
        <VisibilitySensor onVisible={onVisible}>
          <pre
            style={{
              margin: "0",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {comment?.text}
          </pre>
        </VisibilitySensor>
        <div />
        <BundleGroup>
          <CommentAuthor comment={comment} />
          <SecondaryParagraph>
            {getSimpleDate(comment?.date)}
          </SecondaryParagraph>
        </BundleGroup>
      </Section>
    </>
  );
};

export default SingleComment;
