export const acceptedFileTypes = [
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".txt",
  ".doc",
  ".docx",
  ".odt",
  ".xls",
  ".xlsx",
  ".ods",
  ".ppt",
  ".pptx",
  ".odp",
  ".key",
  ".tiff",
  ".tif",
  ".psd",
  ".eps",
  ".ai",
  ".indd",
  ".raw",
  ".dwg",
  ".skp",
  ".stl",
  ".dxf",
  ".dae",
  ".kmz",
  ".3ds",
  ".dem",
  ".fbx",
  ".xsi",
  ".obj",
  ".vrml",
  ".usdz",
  ".ddf",
  ".ifc",
  ".ifczip",
  ".bmp",
  ".psd",
  ".tga",
  ".ifc",
  ".wrl",
  ".rvt",
  ".rwp",
  ".las",
  ".laz",
  ".tzf",
  ".ply",
  ".e57",
];
