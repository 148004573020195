import classNames from "classnames";
import { NavLink } from "react-router-dom";
import Icon from "../../components/Icon";

import styles from "./navigation.module.css";

const MenuListItem = ({ children, linkTo, icon, label, expanded, ...rest }) => {
  return (
    <li key={linkTo} {...rest} className={styles.menuListContainer}>
      {linkTo && (label || icon) ? (
        <NavLink
          to={linkTo}
          className={({ isActive }) =>
            classNames(styles.menuListItem, { [styles.active]: isActive })
          }
        >
          {icon ? <Icon {...{ [icon]: true }} /> : null}
          {expanded ? label : null}
          <div className={styles.tags}>{children}</div>
        </NavLink>
      ) : null}
    </li>
  );
};

export default MenuListItem;
