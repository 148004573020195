import SpecsList from "../specifications/SpecsList";
import ErrorBoundary from "../../components/ErrorBoundary";
import Section from "../../components/Section";
import ProjectHeader from "./ProjectHeader";
import useProject from "./hooks/useProject";
import VisibleOffer from "../offers/VisibleOffer";
import PriceProvider from "./PriceProvider";
import { BundleGroup } from "../../components/Group";
import Button from "../../components/Button";
import useLang from "../../hooks/useLang";
import VisualsExcerpt from "../visuals/VisualsExcerpt";
import { ProjectNotFound } from "../auth/NotFound";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const ProjectOverview = () => {
  const lang = useLang();
  const { project, isFetching, isError, error } = useProject();
  const specs = project?.specifications ? project.specifications : [];

  if (isError && (error?.status === 404 || error?.status === 403)) {
    return <ProjectNotFound />;
  }

  return (
    <>
      {project?.name ? (
        <ProjectHeader project={{ ...project, isFetching }} />
      ) : null}
      <ErrorBoundary fallback="Error in project specs">
        {" "}
        <>
          <Section>
            <SpecsList specs={[...specs, { k: "notes", v: project?.notes }]} />
            <VisibleOffer offer={project?.offers?.displayed} />
            <AccessWrapper action={userActions.POST_OFFER}>
              <BundleGroup>
                <PriceProvider
                  project={project}
                  renderOpen={(onClick) => (
                    <Button
                      onClick={onClick}
                      rounded
                      emphasized
                      icon={"addOffer"}
                    >
                      {lang("add_offer")}
                    </Button>
                  )}
                />
              </BundleGroup>
            </AccessWrapper>
          </Section>
          <VisualsExcerpt
            visuals={project?.visualizations}
            title={lang("visualizations")}
            type="VISUALIZATION"
          />{" "}
          <VisualsExcerpt
            visuals={project?.drawings}
            title={lang("drawings")}
            type="TECH_DRAWING"
          />
        </>
      </ErrorBoundary>
    </>
  );
};

export default ProjectOverview;
