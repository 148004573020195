import { useRef } from "react";

const useFetchAfterMutationStatus = (mutationLoading, isFetching) => {
  const isLoadingRef = useRef(false);
  const isFetchingRef = useRef(false);

  if (mutationLoading) isLoadingRef.current = true;
  if (isFetching) isFetchingRef.current = true;
  if (isLoadingRef.current && isFetchingRef.current && !isFetching) {
    isLoadingRef.current = false;
    isFetchingRef.current = false;
  }

  return { isLoading: isLoadingRef.current };
};

export default useFetchAfterMutationStatus;
