import useLang from "../../hooks/useLang";
import { FormModal } from "../../components/Modal";
import Form from "../../components/Form";
import Input from "../../components/Input";
import useGroupCreator from "./hooks/useGroupCreator";
import { BundleGroup } from "../../components/Group";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const GroupCreator = ({
  renderOpen,
  organizationId,
  nextGroupNumber,
  loader,
  editContacts,
  title,
  submitLabel,
}) => {
  const lang = useLang();
  const {
    handleSubmit,
    handleCancel,
    nameInput,
    firstNameInput,
    lastNameInput,
    emailInput,
    phoneInput,
    addressInput,
    companyInput,
    isLoading,
    changeContactsLoading,
    showContacts,
    toggleContacts,
    isDisabled,
  } = useGroupCreator(organizationId, editContacts);

  if (isLoading || changeContactsLoading) return loader;
  return (
    <>
      <AccessWrapper action={userActions.CREATE_GROUP} owner={organizationId}>
        <FormModal
          title={title || lang("create_new_group_title")}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          submitLabel={submitLabel || lang("create_group")}
          renderOpen={renderOpen}
          disabled={isDisabled}
        >
          <Form>
            {editContacts ? null : (
              <BundleGroup style={{ minWidth: "var(--width-96)" }}>
                {nextGroupNumber || "..."}
                <Input
                  type="text"
                  placeholder={lang("item_name_placeholder")}
                  required
                  invalid={!nameInput?.value}
                  {...nameInput.attributes}
                />
              </BundleGroup>
            )}
            {editContacts ? null : (
              <Input
                type="checkbox"
                label={lang("customer_details")}
                checked={showContacts === true}
                onChange={toggleContacts}
              />
            )}
            {showContacts ? (
              <>
                {editContacts ? null : (
                  <>
                    <Input
                      label={lang("first_name")}
                      type="text"
                      {...firstNameInput.attributes}
                    />
                    <Input
                      label={lang("last_name")}
                      type="text"
                      {...lastNameInput.attributes}
                    />
                    <Input
                      label={lang("email")}
                      type="email"
                      invalid={!emailInput?.isValid}
                      {...emailInput.attributes}
                    />
                  </>
                )}
                <Input
                  label={lang("company")}
                  type="text"
                  {...companyInput.attributes}
                />
                <Input
                  label={lang("address")}
                  type="text"
                  {...addressInput.attributes}
                />
                <Input
                  label={lang("phone")}
                  type="text"
                  {...phoneInput.attributes}
                />
              </>
            ) : null}
          </Form>
        </FormModal>
      </AccessWrapper>
    </>
  );
};

export default GroupCreator;
