import useLang from "../../hooks/useLang";
import { useEffect } from "react";
import { FormModal, FormModalControlled } from "../../components/Modal";
import Form from "../../components/Form";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import useIssueCreator from "./hooks/useIssueCreator";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const IssueCreatorForm = ({
  titleInput,
  addDescriptionInput,
  descriptionInput,
}) => {
  const lang = useLang();
  return (
    <Form modal>
      <Input
        type="text"
        placeholder={lang("issue_title_placeholder")}
        required
        {...titleInput.attributes}
      />
      <Input
        type="checkbox"
        id="addComment"
        name="addComment"
        label={lang("add_description")}
        {...addDescriptionInput.attributes}
      />
      {addDescriptionInput.value ? (
        <TextArea
          placeholder={lang("description_placeholder")}
          {...descriptionInput.attributes}
        />
      ) : null}
    </Form>
  );
};

const IssueCreator = ({ renderOpen, target }) => {
  const lang = useLang();
  const {
    handleCreateIssue,
    handleCancel,
    titleInput,
    addDescriptionInput,
    descriptionInput,
    isLoading,
    projectId,
  } = useIssueCreator(target);

  if (!projectId) return null;
  if (isLoading) return <p>{lang("loading")}</p>;
  return (
    <>
      <AccessWrapper action={userActions.POST_ISSUE}>
        <FormModal
          title={lang("create_new_issue_title")}
          onSubmit={handleCreateIssue}
          onCancel={handleCancel}
          submitLabel={lang("create_issue")}
          renderOpen={renderOpen}
        >
          <IssueCreatorForm
            titleInput={titleInput}
            addDescriptionInput={addDescriptionInput}
            descriptionInput={descriptionInput}
          />
        </FormModal>
      </AccessWrapper>
    </>
  );
};

const IssueCreatorControlled = ({ target, onClose, onLoading, ...rest }) => {
  const lang = useLang();
  const {
    handleCreateIssue,
    handleCancel,
    titleInput,
    addDescriptionInput,
    descriptionInput,
    isLoading,
  } = useIssueCreator(target);

  useEffect(() => {
    onLoading(isLoading);
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <>
      <AccessWrapper action={userActions.POST_ISSUE}>
        <FormModalControlled
          title={lang("create_new_issue_title")}
          onSubmit={handleCreateIssue}
          onCancel={() => {
            handleCancel();
            onClose();
          }}
          onClose={onClose}
          {...rest}
        >
          <IssueCreatorForm
            titleInput={titleInput}
            addDescriptionInput={addDescriptionInput}
            descriptionInput={descriptionInput}
          />
        </FormModalControlled>
      </AccessWrapper>
    </>
  );
};

export { IssueCreatorControlled };
export default IssueCreator;
