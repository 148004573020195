import classNames from "classnames";

import styles from "./formSection.module.css";

const FormSection = ({ children, alignRight }) => {
  return (
    <section
      className={classNames(styles.formSection, {
        [styles.alignRight]: alignRight,
      })}
    >
      {children}
    </section>
  );
};

export default FormSection;
