import useLang from "../../hooks/useLang";
import useMemberAdder from "./hooks/useMemberAdder";
import { FormModal } from "../../components/Modal";
import UserFinder from "./UserFinder";
import { RemovableChip, ToggleChip } from "../../components/Chip";
import Form from "../../components/Form";
import FormSection from "../../components/FormSection";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const MemberAdder = ({ renderOpen, loader }) => {
  const lang = useLang();
  const {
    handleSubmit,
    handleCancel,
    handleUserSelect,
    handleEmailEnter,
    selectedUsers,
    handleUserDeselect,
    availableRoles,
    selectedRole,
    selectRole,
    isLoading,
    isValid,
  } = useMemberAdder();

  const roleSelectors = availableRoles?.map((role) => (
    <ToggleChip
      key={role}
      active={role === selectedRole}
      onToggle={() => {
        selectRole(role);
      }}
    >
      {lang(role)}
    </ToggleChip>
  ));

  if (isLoading) return loader;

  return (
    <AccessWrapper action={userActions.POST_MEMBER}>
      <FormModal
        title={lang("add_member_title")}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        submitLabel={lang("add_member")}
        renderOpen={renderOpen}
        disabled={!isValid}
      >
        <Form>
          <FormSection>
            {selectedUsers?.map((selectedUser) =>
              selectedUser.id ? (
                <RemovableChip
                  key={selectedUser?.id}
                  onRemove={() => {
                    handleUserDeselect(selectedUser);
                  }}
                >
                  {selectedUser?.username}
                </RemovableChip>
              ) : null
            )}
          </FormSection>
          <FormSection>
            <UserFinder
              onSelect={handleUserSelect}
              onEmailEnter={handleEmailEnter}
            />
          </FormSection>
          <FormSection>{roleSelectors}</FormSection>
          <div />
          <div />
        </Form>
      </FormModal>
    </AccessWrapper>
  );
};

export default MemberAdder;
