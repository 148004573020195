import styles from "./sortButton.module.css";

const SortButton = ({ value, icon, ...rest }) => {
  return (
    <div className={styles.sortButton} {...rest}>
      {value}
      {icon}
    </div>
  );
};

export default SortButton;
