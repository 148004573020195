import { Outlet } from "react-router-dom";
import useMyProfile from "../features/auth/useMyProfile";
import { PageNotFound } from "../features/auth/NotFound";
import useLang from "../hooks/useLang";
import usePolicyChecker from "../features/auth/usePolicyChecker";

const AuthorizedRoute = ({ action }) => {
  const lang = useLang();
  const { loadingProfile } = useMyProfile();
  const checkPassed = usePolicyChecker({ action });

  if (loadingProfile) return <p>{lang("loading")}</p>;
  if (!checkPassed) return <PageNotFound />;
  return <Outlet />;
};

export default AuthorizedRoute;
