import { useEffect } from "react";
import useInput from "../../../hooks/useInput";
import {
  useAddNewGroupMutation,
  useChangeContactsMutation,
} from "../groupsSlice";
import useAlerts from "../../alerts/useAlerts";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useGroup from "./useGroup";

const useGroupCreator = (organizationId, editContacts) => {
  const navigate = useNavigate();
  const { group } = useGroup();
  const nameInput = useInput("");
  const [showContacts, setShowContacts] = useState(editContacts);
  const firstNameInput = useInput(
    !editContacts ? "" : group?.contacts?.first_name || ""
  );
  const lastNameInput = useInput(
    !editContacts ? "" : group?.contacts?.last_name || ""
  );
  const emailInput = useInput(
    !editContacts ? "" : group?.contacts?.email || ""
  );
  const phoneInput = useInput(
    !editContacts ? "" : group?.contacts?.phone || ""
  );
  const addressInput = useInput(
    !editContacts ? "" : group?.contacts?.address || ""
  );
  const companyInput = useInput(
    !editContacts ? "" : group?.contacts?.company?.name || ""
  );
  const { onError } = useAlerts();
  const [addNewGroup, { isLoading }] = useAddNewGroupMutation();
  const [changeContactsMutation, { isLoading: changeContactsLoading }] =
    useChangeContactsMutation();

  const handleEditContacts = async () => {
    const body = {
      phone: phoneInput?.value || null,
      address: addressInput?.value || null,
      company: companyInput?.value ? { name: companyInput?.value } : null,
    };
    try {
      await changeContactsMutation({
        params: { id: group?.id },
        body,
      }).unwrap();
    } catch (err) {
      onError(err);
    }
  };

  const handleCreateGroup = async () => {
    try {
      let contacts = null;
      if (showContacts) {
        contacts = {
          first_name: firstNameInput?.value,
          last_name: lastNameInput?.value,
          email: emailInput?.value,
          phone: phoneInput?.value,
          address: addressInput?.value,
          company: { name: companyInput?.value },
        };
      }
      const response = await addNewGroup({
        params: { organizationId },
        body: {
          name: nameInput.value,
          contacts,
        },
      }).unwrap();
      if (response?.group_id) {
        navigate(`/${response?.group_id}`);
      }
    } catch (err) {
      onError(err);
    }
  };

  const handleCancel = () => {
    nameInput.reset();
    firstNameInput.reset();
    lastNameInput.reset();
    emailInput.reset();
    phoneInput.reset();
    addressInput.reset();
    companyInput.reset();
  };

  const toggleContacts = () => {
    setShowContacts((state) => !state);
  };

  useEffect(() => {
    if (group && Object.keys(group).length > 0) {
      phoneInput.setValue(group?.contacts?.phone || "");
      addressInput.setValue(group?.contacts?.address || "");
      companyInput.setValue(group?.contacts?.company?.name || "");
    }
    // eslint-disable-next-line
  }, [group]);

  let isDisabled;
  if (editContacts) {
    isDisabled = false;
  } else {
    isDisabled = false;
    if (!nameInput?.value) isDisabled = true;
    if (showContacts && !emailInput?.isValid) {
      isDisabled = true;
    }
  }

  return {
    handleSubmit: !editContacts ? handleCreateGroup : handleEditContacts,
    handleCancel,
    nameInput,
    firstNameInput,
    lastNameInput,
    emailInput,
    phoneInput,
    addressInput,
    companyInput,
    showContacts,
    toggleContacts,
    isLoading,
    changeContactsLoading,
    isDisabled,
  };
};

export default useGroupCreator;
