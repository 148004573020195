import useLang from "../../hooks/useLang";
import Button from "../../components/Button";
import PriceProvider from "./PriceProvider";
import { userActions } from "../../config/accessConfig";
import AccessWrapper from "../auth/AccessWrapper";
import IssueCounterChip from "../issues/IssueCounterChip";
import ProjectPatcher from "./ProjectPatcher";
import ProjectFreezer from "./ProjectFreezer";
import Link from "../../components/Link";
import classNames from "classnames";
import { useState, useRef } from "react";
import { TertiaryParagraph } from "../../components/Paragraph";
import { OverflowingParagraph } from "../../components/Paragraph";
import TotalPrice from "./TotalPrice";

import styles from "./specsTable.module.css";

const SpecsTableBigScreen = ({ group }) => {
  const lang = useLang();
  let specsKeyList = [];
  const [titleHover, setTitleHover] = useState();
  const [priceHover, setPriceHover] = useState();
  const dialogOpenRef = useRef();

  if (
    !(
      group &&
      group?.projects &&
      Array.isArray(group?.projects) &&
      group?.projects.length > 0
    )
  ) {
    return null;
  }

  group?.projects?.map((project) => {
    project?.specifications?.map((specification, idx) => {
      if (specsKeyList.indexOf(specification.k) < 0) {
        specsKeyList.push(specification.k);
      }
      return false;
    });
    return false;
  });

  const handleOpen = () => {
    dialogOpenRef.current = true;
  };

  const specsTitles = group?.projects?.map((project) => {
    const result = (
      <div
        key={project.id}
        className={classNames(styles.specsCell, styles.titles, {
          [styles.frozen]: project?.frozen,
        })}
        onMouseEnter={() => {
          setTitleHover(project.id);
        }}
        onMouseLeave={() => {
          if (!dialogOpenRef.current) {
            setTitleHover((state) => (state ? undefined : state));
          }
        }}
      >
        <Link
          to={`/${project?.group_id}/${project?.id}`}
          style={{
            width: "calc(100% - var(--spacing-2))",
          }}
        >
          <h3
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {project?.name}
          </h3>
        </Link>
        {titleHover === project.id ? (
          <div
            style={{
              position: "absolute",
              bottom: "var(--spacing-3)",
              right: "var(--spacing-1)",
              gap: "var(--spacing-1)",
              display: "flex",
            }}
          >
            <AccessWrapper
              action={userActions.PATCH_PROJECT_SPECS}
              project={project}
            >
              <ProjectPatcher
                project={{ ...project, isFetching: group?.isFetching }}
                loader={<Button rounded emphasized loader disabled />}
                onClose={() => {
                  dialogOpenRef.current = false;
                  setTitleHover(false);
                  setPriceHover(false);
                }}
                renderOpen={(onClick) => (
                  <Button
                    rounded
                    emphasized
                    icon={"pencil"}
                    onClick={() => {
                      onClick();
                      handleOpen();
                    }}
                  />
                )}
              />{" "}
            </AccessWrapper>
            <AccessWrapper
              action={userActions.FREEZE_PROJECT}
              project={project}
            >
              <ProjectFreezer
                project={{ ...project, isFetching: group?.isFetching }}
                rounded
              />
            </AccessWrapper>
          </div>
        ) : null}
        <IssueCounterChip
          unseen={project?.no_of_unseen}
          open={project?.no_of_open_issues}
          projectId={project?.id}
        />
      </div>
    );
    return result;
  });

  let specsHeaders;
  const specsContent = group?.projects?.map((project, idx) => {
    if (idx === 0) {
      specsHeaders = specsKeyList?.map((specsKey) => (
        <div
          key={specsKey}
          className={classNames(styles.specsHeader, styles.specification)}
        >
          <TertiaryParagraph>{specsKey}</TertiaryParagraph>
        </div>
      ));
      specsHeaders.push(
        <div key={"notes"} className={styles.notesHeader}>
          <TertiaryParagraph>{lang("notes")}</TertiaryParagraph>
        </div>
      );
    }
    const result = specsKeyList?.map((specsKey) => {
      const spec = project.specifications.find(
        (specification) => specification.k === specsKey
      );
      return (
        <div
          key={`${specsKey}-${spec?.v || "x"}`}
          className={classNames(styles.specsCell, {
            [styles.notAvailable]: !spec,
            [styles.frozen]: project?.frozen,
          })}
        >
          <OverflowingParagraph>{spec ? spec?.v : " "}</OverflowingParagraph>
        </div>
      );
    });
    return (
      <div key={`${project?.id}-${project?.notes}`} style={{ display: "flex" }}>
        {result}
        <div
          className={classNames(styles.notesCell, {
            [styles.frozen]: project?.frozen,
          })}
        >
          <OverflowingParagraph>
            {project ? project?.notes : " "}
          </OverflowingParagraph>
        </div>
      </div>
    );
  });

  const priceList = group?.projects?.map((project) => {
    let visibleOffers = project?.offers?.displayed.map(
      (displayedOffer) => displayedOffer.amount
    );
    let sortedOffers = visibleOffers?.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
    let lowestPrice;
    if (Array.isArray(sortedOffers) && sortedOffers.length > 0) {
      lowestPrice = sortedOffers[0];
    }
    if (project?.frozen)
      return (
        <div
          key={project?.id}
          className={classNames(styles.specsCell, styles.price, {
            [styles.frozen]: project?.frozen,
          })}
        />
      );
    return (
      <div
        key={project?.id}
        className={classNames(styles.specsCell, styles.price, {
          [styles.frozen]: project?.frozen,
        })}
        onMouseEnter={() => {
          setPriceHover(project.id);
        }}
        onMouseLeave={() => {
          if (!dialogOpenRef.current)
            setPriceHover((state) => (state ? undefined : state));
        }}
      >
        {lowestPrice != null
          ? `${lowestPrice} ${project?.offers?.displayed[0]?.currency}`
          : ""}
        {sortedOffers?.length > 1 ? <p>...</p> : null}
        {priceHover === project.id ? (
          <AccessWrapper action={userActions.POST_OFFER} project={project}>
            <div
              style={{
                position: "absolute",
                bottom: "var(--spacing-3)",
                right: "var(--spacing-1)",
              }}
            >
              <PriceProvider
                project={project}
                onClose={() => {
                  dialogOpenRef.current = false;
                  setTitleHover(false);
                  setPriceHover(false);
                }}
                renderOpen={(onClick) => (
                  <Button
                    onClick={() => {
                      onClick();
                      handleOpen();
                    }}
                    rounded
                    emphasized
                    icon={"addOffer"}
                  />
                )}
              />
            </div>
          </AccessWrapper>
        ) : null}
      </div>
    );
  });

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <div className={classNames(styles.specsHeader, styles.titles)} />
          {specsTitles}
        </div>
        <div style={{ flexGrow: "1", overflow: "auto" }}>
          <div style={{ display: "flex" }}>{specsHeaders}</div>
          {specsContent}
        </div>
        <div>
          <div className={classNames(styles.specsHeader, styles.price)}>
            {lang("price_vat_excluded")}
          </div>
          {priceList}
        </div>
      </div>
      <TotalPrice group={group} />
    </>
  );
};

export default SpecsTableBigScreen;
