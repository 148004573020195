import useProjectCreator from "./hooks/useProjectCreator";
import ProjectModifier, { ProjectModifierControlled } from "./ProjectModifier";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const ProjectCreator = ({ renderOpen }) => {
  let modifierAttribs = useProjectCreator();
  return (
    <AccessWrapper action={userActions.CREATE_PROJECT}>
      <ProjectModifier {...{ ...modifierAttribs, renderOpen }} />
    </AccessWrapper>
  );
};

const ProjectCreatorControlled = ({ isOpen, handleClose, handleCancel }) => {
  let modifierAttribs = useProjectCreator();
  return (
    <AccessWrapper action={userActions.CREATE_PROJECT}>
      <ProjectModifierControlled
        {...{
          ...modifierAttribs,
          isOpen,
          handleClose,
          handleCancel: () => {
            modifierAttribs?.handleCancel();
            handleCancel();
          },
        }}
      />
    </AccessWrapper>
  );
};

export { ProjectCreatorControlled };
export default ProjectCreator;
