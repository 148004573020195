const useRelativeCoords = (imageSize) => {
  const getX = (originalX) => {
    return originalX / imageSize?.scale;
  };

  const getRightX = (originalX) => {
    const leftX = getX(originalX);
    return imageSize?.width - leftX;
  };

  const getY = (originalY) => {
    return originalY / imageSize?.scale;
  };

  const isOnRight = (originalX) => {
    if (!imageSize) return false;
    if (Object.keys(imageSize).length === 0) return false;
    return originalX / imageSize?.scale > imageSize?.width / 2;
  };

  return { getX, getRightX, getY, isOnRight };
};

export default useRelativeCoords;
