import useUsers from "../users/hooks/useUsers";
import useGroup from "../projectGroups/hooks/useGroup";
import { selectCurrentUser } from "./authSlice";
import { useSelector } from "react-redux";
import { useMyProfileQuery } from "./authApiSlice";

const useMyProfile = () => {
  const { users } = useUsers();
  const userFromAuth = useSelector(selectCurrentUser);
  const userFromApi = users?.find((user) => user.id === userFromAuth);
  const id = userFromApi?.id;
  const { group, isLoading: groupLoading } = useGroup();
  const {
    data: myProfile,
    isLoading: loadingProfile,
    refetch,
  } = useMyProfileQuery();

  const groupMember = group?.members?.find(
    (member) => member.id === userFromAuth
  );
  let role = {};
  if (userFromApi?.role) {
    if (userFromApi?.role === "OWNER") role.owner = true;
    if (userFromApi?.role === "ADMIN") role.admin = true;
  }
  let groupRole = {};
  if (groupMember?.role) {
    if (groupMember?.role === "OWNER") groupRole.owner = true;
    if (groupMember?.role === "ADMIN") groupRole.admin = true;
  }
  const isAuthorized = (requiredRightsArray) => {
    if (!requiredRightsArray) return true;
    let authorized = [];
    if (typeof requiredRightsArray === "string")
      authorized = [requiredRightsArray];
    else if (Array.isArray(requiredRightsArray))
      authorized = [...requiredRightsArray];
    else return true;

    let result = authorized.length > 0 ? false : true;
    const foundIndex = authorized.findIndex((authorizedItem) =>
      groupMember?.role
        ? authorizedItem.toLowerCase() === groupMember?.role?.toLowerCase()
        : false
    );
    if (foundIndex > -1) result = true;

    return result;
  };

  let user;
  if (myProfile) {
    if (userFromApi) {
      user = { ...userFromApi, ...myProfile };
    } else {
      user = { ...myProfile };
    }
  }

  return {
    role,
    groupRole,
    id,
    isAuthorized,
    user,
    loadingProfile: loadingProfile || groupLoading,
    refetch,
  };
};

export default useMyProfile;
