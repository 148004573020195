import useOrganizationGroups from "./hooks/useOrganizationGroups";
import GroupPages from "./GroupPages";

const OrganizationGroupList = ({ owner, show }) => {
  const groups = useOrganizationGroups(owner, show);

  if (!show) return null;
  return <GroupPages groups={groups} />;
};

export default OrganizationGroupList;
