import { BundleGroup } from "../../components/Group";
import { CountChip } from "../../components/Chip";
import { useParams } from "react-router";
import Icon from "../../components/Icon";
import Link from "../../components/Link";

const UnseenIssuesChip = ({
  unseen,
  projectId: projectIdFromProps,
  noIcon,
  noLink,
  frame,
}) => {
  const { groupId, projectId } = useParams();
  if (!unseen) return null;
  let content = (
    <CountChip unseen>
      {noIcon ? null : <Icon unseenIssues emphasized />}
      {unseen}
    </CountChip>
  );
  if (groupId && (projectId || projectIdFromProps) && !noLink) {
    if (frame) {
      content = (
        <Link
          to={`/${groupId}/${projectId || projectIdFromProps}/specs?frame=${
            frame.id
          }&showIssues=true`}
          style={{ padding: "0" }}
        >
          {content}
        </Link>
      );
    } else {
      content = (
        <Link
          to={`/${groupId}/${projectId || projectIdFromProps}/issues`}
          style={{ padding: "0" }}
        >
          {content}
        </Link>
      );
    }
  }
  return <>{content}</>;
};

const OpenIssuesChip = ({
  open,
  projectId: projectIdFromProps,
  noIcon,
  noLink,
  frame,
  darker,
}) => {
  const { groupId, projectId } = useParams();
  if (!open) return null;
  let content = (
    <CountChip darker={darker}>
      {noIcon ? null : <Icon issues />}
      {open}
    </CountChip>
  );
  if (groupId && (projectId || projectIdFromProps) && !noLink) {
    if (frame) {
      content = (
        <Link
          to={`/${groupId}/${projectId || projectIdFromProps}/specs?frame=${
            frame.id
          }&showIssues=true`}
          style={{ padding: "0" }}
        >
          {content}
        </Link>
      );
    } else {
      content = (
        <Link
          to={`/${groupId}/${projectId || projectIdFromProps}/issues`}
          style={{ padding: "0" }}
        >
          {content}
        </Link>
      );
    }
  }
  return <>{content}</>;
};

const IssueCounterChip = ({ unseen, open, ...rest }) => {
  return (
    <BundleGroup width={36} style={{ gap: "var(--spacing-1)" }}>
      <BundleGroup width={16}>
        <UnseenIssuesChip unseen={unseen} {...rest} />
      </BundleGroup>
      <BundleGroup width={16}>
        <OpenIssuesChip open={open} {...rest} />
      </BundleGroup>
    </BundleGroup>
  );
};

export { UnseenIssuesChip, OpenIssuesChip };
export default IssueCounterChip;
