import classNames from "classnames";
import styles from "./header.module.css";

const Header = ({
  children,
  pageHeader,
  sectionHeader,
  dialogHeader,
  alert,
  ...rest
}) => {
  return (
    <header
      className={classNames(styles.header, {
        [styles.pageHeader]: pageHeader,
        [styles.sectionHeader]: sectionHeader,
        [styles.dialogHeader]: dialogHeader,
        [styles.alertHeader]: alert,
      })}
      {...rest}
    >
      {children}
    </header>
  );
};

const PageHeader = ({ children, ...rest }) => {
  return (
    <Header pageHeader {...rest}>
      {children}
    </Header>
  );
};

const SectionHeader = ({ children, ...rest }) => {
  return (
    <Header sectionHeader {...rest}>
      {children}
    </Header>
  );
};

const DialogHeader = ({ children, ...rest }) => {
  return (
    <Header dialogHeader {...rest}>
      {children}
    </Header>
  );
};

const AlertDialogHeader = ({ children, ...rest }) => {
  return (
    <Header dialogHeader alert {...rest}>
      {children}
    </Header>
  );
};

export { PageHeader, SectionHeader, DialogHeader, AlertDialogHeader };
export default Header;
