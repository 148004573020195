import {
  FormModalControlled,
  ConfirmationModalControlled,
} from "../../components/Modal";
import useReplaceHandler from "./hooks/useNameConflictHandler";
import useLang from "../../hooks/useLang";

const NameConflictHandler = ({ placeholders, onResolve }) => {
  const lang = useLang();
  const {
    isExistingOpen,
    uploadingFiles,
    isUploadingOpen,
    existingFiles,
    onCancelExisting,
    onSubmitExisting,
    onConfirm,
  } = useReplaceHandler(placeholders, onResolve);

  return (
    <>
      <FormModalControlled
        isOpen={isExistingOpen}
        cancelLabel={lang("skip")}
        submitLabel={lang("overwrite")}
        onCancel={onCancelExisting}
        onSubmit={onSubmitExisting}
      >
        <p>
          {lang("file_with_name_exists").replace(
            "%FILE_NAME%",
            `"${existingFiles[0]?.name}"`
          )}
        </p>
      </FormModalControlled>
      <ConfirmationModalControlled
        isOpen={isUploadingOpen}
        onSubmit={onConfirm}
        submitLabel={lang("skip")}
      >
        <p>
          {lang("file_with_name_being_uploaded").replace(
            "%FILE_NAME%",
            `"${uploadingFiles.map((file) => file.name).join(", ")}"`
          )}
        </p>
      </ConfirmationModalControlled>
    </>
  );
};

/*
    file_with_name_exists: "Byla %FILE_NAME% jau egzistuoja.",
    file_with_name_being_uploaded: "Byla %FILE_NAME% šiuo metu įkeliama.",
*/

export default NameConflictHandler;

/*
        cancelLabel={lang("cancel")}
        submitLabel={lang("merge")}
        onCancel={handleCancel}
        onClose={handleClose}
        onSubmit={handleSubmit}
*/
