import useOrganizations from "../../organizations/hooks/useOrganizations";
import useLang from "../../../hooks/useLang";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useSearchParams from "../../../hooks/useSearchParams";
import { useGetSharedGroupsQuery } from "../groupsSlice";
import { useMyProfileQuery } from "../../auth/authApiSlice";
import { getErrorMessage } from "../../../utils/errorUtils";

const useGroupTabs = () => {
  const lang = useLang();
  const navigate = useNavigate();
  const {
    organizations,
    isLoading: loadingOrganizations,
    isSuccess,
    error: organizationsError,
  } = useOrganizations();
  const { getParam, getLinkWithParam } = useSearchParams();
  const ownerId = getParam("owner");
  const {
    data: sharedGroups,
    isLoading: loadingSharedGroups,
    isSuccess: isSharedGroupsSuccess,
    error: sharedGroupsError,
  } = useGetSharedGroupsQuery({ pageNo: 1 });
  const { data: myProfile, isLoading: profileLoading } = useMyProfileQuery();

  let owners = [];
  let allOwners = [];
  let tabLabels = [];
  if (isSuccess) {
    if (organizations?.ids?.length) {
      tabLabels = organizations?.ids?.map(
        (id) => organizations?.entities[id]?.name
      );
      tabLabels.push(lang("my_projects"));
      owners = organizations?.ids;
      allOwners = [...owners, "my"];
    }
  }
  tabLabels.push(lang("shared_projects"));
  allOwners = [...allOwners, "shared"];

  const tabAttributes = allOwners?.map((owner, index) => {
    return { key: owner, label: tabLabels[index], param: "owner", to: owner };
  });

  const isLoading =
    loadingOrganizations || loadingSharedGroups || profileLoading;

  useEffect(() => {
    if (isSuccess) {
      if (ownerId == null) {
        navigate(`/?${getLinkWithParam("owner", allOwners[0])}`, {
          replace: true,
        });
      } else {
        if (allOwners.findIndex((owner) => owner === ownerId) < 0) {
          navigate(`/?${getLinkWithParam("owner", allOwners[0])}`, {
            replace: true,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  let hasGroups = false;
  if (
    (Array.isArray(organizations?.ids) && organizations?.ids?.length > 0) ||
    (Array.isArray(sharedGroups?.ids) && sharedGroups?.ids?.length > 0)
  ) {
    hasGroups = true;
  }

  const error = organizationsError || sharedGroupsError;
  const errorMessage = getErrorMessage(error);

  return {
    hasGroups,
    owner: ownerId,
    organizations: owners,
    tabAttributes,
    tabLabels,
    isLoading,
    isSuccess: isSuccess && isSharedGroupsSuccess,
    showTabs: tabLabels?.length > 1,
    myProfile,
    error,
    errorMessage,
  };
};

export default useGroupTabs;
