import useOffersOverview from "./hooks/useOffersOverview";
import useLang from "../../hooks/useLang";
import Section from "../../components/Section";
import ProjectSwitch from "../projects/ProjectSwitch";
import OffersForProject from "./OffersForProject";
import { BundleGroup } from "../../components/Group";
import Header from "../../components/Header";
import Link from "../../components/Link";
import useScreenSize from "../../hooks/useScreenSize";

const OffersOverview = () => {
  const lang = useLang();
  const { isSmallScreen } = useScreenSize();
  const { isGroup, groupId, project, projects, isLoading } =
    useOffersOverview();

  let content;
  if (isLoading) {
    content = <p>{lang("loading")}</p>;
  } else if (isGroup) {
    content = projects.map((project) => (
      <Section key={project.id}>
        <Header>
          <Link to={`/${groupId}/${project?.id}/offers`}>
            <h2>{project?.name}</h2>
          </Link>
        </Header>
        <BundleGroup vertical>
          <OffersForProject project={project} inColumns={!isSmallScreen} />
        </BundleGroup>
      </Section>
    ));
  } else {
    content = (
      <>
        <OffersForProject project={project} inColumns={!isSmallScreen} />
      </>
    );
  }

  return (
    <>
      <ProjectSwitch />
      {content}
    </>
  );
};

export default OffersOverview;
