import { useState, useRef, useEffect } from "react";

const useUploader = () => {
  const [uploadStarted, setUploadStarted] = useState(false);
  const [uploadFinished, setUploadFinished] = useState(false);
  const [placeholders, setPlaceholders] = useState([]);
  const placeholdersRef = useRef();

  const [finishedCounter, setFinishedCounter] = useState(0);

  const uploadFile = async (placeholder) => {
    const xhr = new XMLHttpRequest();
    // const success = await new Promise((resolve) => {
    await new Promise((resolve) => {
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          setPlaceholders((state) => {
            const newState = state.map((currentPlaceholder) => {
              if (currentPlaceholder.id === placeholder.id) {
                const progress = Math.round((event.loaded / event.total) * 100);
                return {
                  ...currentPlaceholder,
                  progress,
                };
              }
              return currentPlaceholder;
            });
            return newState;
          });
        }
      });
      xhr.addEventListener("loadend", () => {
        resolve(xhr.readyState === 4 && xhr.status === 200);
      });
      xhr.open("PUT", placeholder.placeholder_url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.send(placeholder.binary);
    });
    setPlaceholders((state) => {
      const newState = state.map((currentPlaceholder) => {
        if (currentPlaceholder.id === placeholder.id) {
          return {
            ...currentPlaceholder,
            progress: 100,
            uploaded: true,
          };
        }
        return currentPlaceholder;
      });
      return newState;
    });
    setFinishedCounter((state) => {
      const newState = state + 1;
      return newState;
    });
  };

  const uploadFiles = (currentPlaceholders) => {
    if (uploadStarted === true) return false;
    setUploadStarted(true);
    setUploadFinished(false);
    setFinishedCounter(0);
    setPlaceholders(currentPlaceholders);
    currentPlaceholders.map((placeholder) => {
      uploadFile(placeholder);
      return false;
    });
  };

  const resetPlaceholder = (placeholderToReset) => {
    const placeholderIndex = placeholdersRef.current.findIndex(
      (placeholder) => placeholder.id === placeholderToReset.id
    );
    if (placeholderIndex >= 0) {
      setPlaceholders((state) => {
        const newState = state.filter(
          (placeholder) => placeholder.id !== placeholderToReset.id
        );
        return newState;
      });
    }
  };

  useEffect(() => {
    if (uploadStarted) {
      const unfinished = placeholders.filter(
        (placeholder) => placeholder.uploaded !== true
      );
      if (unfinished.length === 0) {
        setUploadStarted(false);
        setUploadFinished(true);
      }
    }
    // eslint-disable-next-line
  }, [uploadStarted, finishedCounter]);

  useEffect(() => {
    placeholdersRef.current = placeholders;
  }, [placeholders]);

  return {
    uploadFiles,
    data: placeholders,
    isFinished: uploadFinished,
    resetPlaceholder,
    finishedCounter,
  };
};

export default useUploader;
