import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

const useFileReader = () => {
  const [uploadLength, setUploadLength] = useState(0);
  const [uploads, setUploads] = useState([]);
  const [result, setResult] = useState([]);
  const [isFinished, setIsFinished] = useState(false);

  const readFiles = async (files) => {
    if (!files) return false;
    const fileArray = Array.from(files);
    if (fileArray && Array.isArray(fileArray)) {
      setUploadLength(fileArray.length);
      setUploads([]);
      fileArray?.map((file) => {
        file.arrayBuffer().then((binary) => {
          setUploads((state) => {
            const uuid = uuidv4();
            return [
              ...state,
              {
                name: file.name,
                size: file.size,
                file_id: uuid,
                id: uuid,
                prepared: false,
                uploaded: false,
                binary,
              },
            ];
          });
        });
        return false;
      });
    }
  };

  const resetExcept = () => {
    setUploadLength(uploads?.length);
    setUploads(uploads);
    setResult(uploads);
    setIsFinished(false);
  };

  const reset = () => {
    setUploadLength(0);
    setUploads([]);
    setResult([]);
    setIsFinished(false);
  };

  useEffect(() => {
    if (
      uploadLength > 0 &&
      uploads?.length &&
      uploads?.length === uploadLength
    ) {
      setResult(uploads);
      setIsFinished(true);
    }
  }, [uploadLength, uploads]);

  return { readFiles, reset, resetExcept, data: result, isFinished };
};

export default useFileReader;
