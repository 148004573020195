import { Outlet } from "react-router-dom";
import { AlertModal, AlertModalWithOverride } from "../Modal";
import useAlertHandler from "../../features/alerts/useAlertHandler";
import ErrorMessage from "../ErrorMessage";

const AlertHandler = () => {
  const { showModal, hasOverride, title, message, onClose, onOverride } =
    useAlertHandler();
  if (hasOverride) {
    return (
      <>
        <Outlet />
        <AlertModalWithOverride
          isOpen={showModal}
          onClose={onClose}
          onOverride={onOverride}
          title={title}
        >
          <ErrorMessage errMsg={message} />
        </AlertModalWithOverride>
      </>
    );
  }

  return (
    <>
      <Outlet />
      <AlertModal isOpen={showModal} onClose={onClose} title={title}>
        <ErrorMessage errMsg={message} />
      </AlertModal>
    </>
  );
};

export default AlertHandler;
