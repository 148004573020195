import IssueMeta from "./IssueMeta";
import ErrorBoundary from "../../components/ErrorBoundary";
import Section from "../../components/Section";
import CommentCounter from "../comments/CommentCounter";
import useIssueExcerpt from "./hooks/useIssueExcerpt";
import { StatusChip } from "../../components/Chip";
import { BundleGroup } from "../../components/Group";

const IssuesExcerpt = ({ issue: issueFromProps, renderLink }) => {
  const { issue } = useIssueExcerpt(issueFromProps);
  return (
    <Section paddingSm emphasized={issue?.unseen}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "calc(100% - var(--width-36))",
            overflow: "hidden",
          }}
        >
          {typeof renderLink === "function" ? renderLink(() => {}) : null}
        </div>
        <BundleGroup>
          <StatusChip status={issue?.status} small />
          <CommentCounter issue={issue} />
        </BundleGroup>
      </div>
      {issue?.description ? (
        <div>
          <p>{issue?.description}</p>
        </div>
      ) : null}
      <ErrorBoundary fallback="Error">
        <IssueMeta issue={issue} />
      </ErrorBoundary>
      {issue?.target?.url ? (
        <img src={issue?.target?.url} alt={issue?.title} width={100} />
      ) : null}
    </Section>
  );
};

export default IssuesExcerpt;
