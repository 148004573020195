import useMyGroups from "./hooks/useMyGroups";
import GroupPages from "./GroupPages";

const MyGroupList = ({ show }) => {
  const groups = useMyGroups(show);

  if (!show) return null;
  return <GroupPages groups={groups} />;
};

export default MyGroupList;
