import { apiSlice } from "../api/apiAuthenticatedSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    myProfile: builder.query({
      query: (id) => `/my/profile`,
      providesTags: [{ type: "Profile" }],
    }),
    patchMyProfile: builder.mutation({
      query: (body) => ({
        url: `/my/profile`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: "Profile" }],
    }),
    addEmail: builder.mutation({
      query: (body) => ({
        url: "/my/profile/emails",
        method: "POST",
        body: { ...body },
      }),
    }),
    verifyEmail: builder.mutation({
      query: (token) => ({
        url: `/my/profile/confirm_email/${token}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Profile" }],
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: [{ type: "Profile" }],
    }),
    getToken: builder.mutation({
      query: (params) => ({
        url: "oauth2/token",
        method: "POST",
        params,
      }),
      invalidatesTags: [{ type: "Profile" }],
    }),
    checkInvitation: builder.mutation({
      query: (invitationId) => ({
        url: `invitations/${invitationId}/process`,
        method: "POST",
        body: { action: "CHECK" },
      }),
      invalidatesTags: [{ type: "Profile" }],
    }),
    mergeInvitation: builder.mutation({
      query: ({ invitationId, code, merge }) => ({
        url: `invitations/${invitationId}/process`,
        method: "POST",
        body: { action: `${merge ? "MERGE" : "DONT_MERGE"}`, code },
      }),
      invalidatesTags: [{ type: "Profile" }],
    }),
  }),
});

export const {
  useMyProfileQuery,
  usePatchMyProfileMutation,
  useLoginMutation,
  useGetTokenMutation,
  useCheckInvitationMutation,
  useMergeInvitationMutation,
  useAddEmailMutation,
  useVerifyEmailMutation,
} = authApiSlice;
