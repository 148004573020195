import useGroupPages from "./hooks/useGroupPages";
import GroupExcerpt from "./GroupExcerpt";
import { PageFooter } from "../../components/Footer";
import Button from "../../components/Button";
import ErrorBoundary from "../../components/ErrorBoundary";
import GroupCreator from "./GroupCreator";
import useLang from "../../hooks/useLang";
import GroupListHeader from "./GroupListHeader";
import { BundleGroup } from "../../components/Group";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const GroupPages = ({ groups }) => {
  const lang = useLang();
  const {
    organizationId,
    showPagination,
    disablePrev,
    disableNext,
    nextGroupNumber,
    goToNext,
    goToPrevious,
  } = useGroupPages(groups);
  let content;

  if (groups?.isSuccess) {
    const { ids: groupIds, entities: groupEntities } = groups?.data
      ? groups?.data
      : {};

    content = (
      <>
        <AccessWrapper action={userActions.CREATE_GROUP} owner={organizationId}>
          <ErrorBoundary fallback="Error in create group modal">
            <BundleGroup
              style={{
                marginTop: "var(--spacing-2)",
                marginBottom: "var(--spacing-2)",
              }}
            >
              <GroupCreator
                organizationId={organizationId}
                nextGroupNumber={nextGroupNumber}
                loader={
                  <Button important disabled loader>
                    {lang("create_group")}
                  </Button>
                }
                renderOpen={(onClick) => (
                  <Button important onClick={onClick}>
                    {lang("create_group")}
                  </Button>
                )}
              />
            </BundleGroup>
          </ErrorBoundary>
        </AccessWrapper>
        <GroupListHeader owner={organizationId} />
        {groupIds?.map((groupId) => (
          <GroupExcerpt
            key={groupId}
            group={{
              ...groupEntities[groupId],
              isFetching: groups?.isFetching,
            }}
            owner={organizationId}
          />
        ))}
        {showPagination ? (
          <PageFooter>
            <Button
              icon={"chevronLeft"}
              onClick={goToPrevious}
              disabled={disablePrev}
            />
            <Button
              icon={"chevronRight"}
              onClick={goToNext}
              disabled={disableNext}
            />
          </PageFooter>
        ) : null}
      </>
    );
  }

  return <>{content}</>;
};

export default GroupPages;
