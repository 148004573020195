import classNames from "classnames";
import { useRef, useEffect, useState } from "react";
import styles from "./paragraph.module.css";

const Paragraph = ({
  children,
  secondary,
  tertiary,
  meta,
  ellipsisOverflow,
  ...rest
}) => {
  return (
    <p
      className={classNames({
        [styles.secondary]: secondary,
        [styles.tertiary]: tertiary,
        [styles.meta]: meta,
        [styles.ellipsisOverflow]: ellipsisOverflow,
      })}
      {...rest}
    >
      {children}
    </p>
  );
};

const TertiaryParagraph = ({ children, ...rest }) => {
  return (
    <Paragraph tertiary {...rest}>
      {children}
    </Paragraph>
  );
};

const SecondaryParagraph = ({ children, ...rest }) => {
  return (
    <Paragraph secondary {...rest}>
      {children}
    </Paragraph>
  );
};

const MetaParagraph = ({ children, ...rest }) => {
  return (
    <SecondaryParagraph meta {...rest}>
      {children}
    </SecondaryParagraph>
  );
};

const OverflowingParagraph = ({ children, ...rest }) => {
  const textRef = useRef(null);
  const [truncatedText, setTruncatedText] = useState(children);

  useEffect(() => {
    const textElement = textRef.current;

    if (textElement) {
      const overflowing = textElement.scrollHeight > textElement.clientHeight;
      const truncatedByCharCount =
        children && typeof children === "string" ? children.slice(0, 65) : "";
      const textArray = truncatedByCharCount.split(" ");
      if (overflowing) {
        setTruncatedText(`${textArray.slice(0, 9).join(" ")}...`);
      }
    }
  }, [children]);

  return (
    <>
      <pre
        ref={textRef}
        style={{
          margin: "0",
          height: "100%",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
        }}
        {...rest}
      >
        {truncatedText}
      </pre>
    </>
  );
};

export {
  SecondaryParagraph,
  TertiaryParagraph,
  MetaParagraph,
  OverflowingParagraph,
};
export default Paragraph;
