const authUrl = process.env.REACT_APP_POOL_AUTH_URL;
const clientId = process.env.REACT_APP_POOL_CLIENT_ID;

const useAuth = () => {
  const refreshToken = localStorage.getItem("refreshToken");

  const getCallbackUrl = (dir) => {
    let protocol = "https:";
    let host = "localhost";
    if (window) {
      host = window.location.host;
      protocol = window.location.protocol;
    }
    if (!host.startsWith("localhost")) protocol = "https:";
    return `${protocol}//${host}/${dir ? dir : "cognito"}`;
  };

  const getLoginUrl = () => {
    const queryParams = [
      `response_type=code`,
      `client_id=${clientId}`,
      `redirect_uri=${getCallbackUrl()}`,
    ];
    return `https://${authUrl}/login?${queryParams.join("&")}`;
  };

  const getLogoutUrl = () => {
    const queryParams = [
      `client_id=${clientId}`,
      `logout_uri=${getCallbackUrl("login")}`,
    ];
    return `https://${authUrl}/logout?${queryParams.join("&")}`;
  };

  const setRefreshToken = (value) => {
    if (value != null) localStorage.setItem("refreshToken", value);
    else localStorage.removeItem("refreshToken");
  };

  return {
    refreshToken,
    setRefreshToken,
    getLoginUrl,
    getLogoutUrl,
    getCallbackUrl,
    clientId,
  };
};

export default useAuth;
