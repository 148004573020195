import { useGetSharedGroupsQuery } from "../groupsSlice";
import useSearchParams from "../../../hooks/useSearchParams";

const useSharedGroups = (show) => {
  const { getParam } = useSearchParams();
  let params = {};
  params.pageNo = show ? getParam("page") : 1;
  if (show && getParam("sort_by") && getParam("sort_order"))
    params.sort = `${getParam("sort_order")}(${getParam("sort_by")})`;
  if (show && getParam("status")) {
    const statusParam = getParam("status");
    if (statusParam === "OFFERS") {
      params.status = ["INQUIRY", "OFFER"];
    }
    if (statusParam === "ORDERS") {
      params.status = ["PENDING", "IN_PROGRESS", "WARRANTY"];
    }
    if (statusParam === "COMPLETED") {
      params.status = ["COMPLETED"];
    }
    if (statusParam === "CANCELLED") {
      params.status = ["DECLINED", "CANCELLED"];
    }
    if (statusParam === "ON_HOLD") {
      params.status = ["ON_HOLD"];
    }
  }
  const { data, isLoading, isFetching, isSuccess, ...rest } =
    useGetSharedGroupsQuery(params);

  return { data, isLoading, isFetching, isSuccess, ...rest };
};

export default useSharedGroups;
