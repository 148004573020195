import { useEffect, useState } from "react";
import useImageScale from "./useImageScale";

const useResizeDetector = (elementRef) => {
  const [size, setSize] = useState({});
  const [offset, setOffset] = useState({});
  const imageScale = useImageScale(elementRef, size?.width);

  useEffect(() => {
    if (elementRef.current) {
      const { offsetLeft, offsetTop } = elementRef.current;
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          setSize({ width, height });
        }
      });
      const currentElement = elementRef.current;
      resizeObserver.observe(currentElement);
      setOffset({ left: offsetLeft, top: offsetTop });

      return () => {
        resizeObserver.unobserve(currentElement);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    width: size?.width,
    height: size?.height,
    offsetLeft: offset?.left,
    offsetTop: offset?.top,
    ...imageScale,
  };
};

export default useResizeDetector;
