import classNames from "classnames";
import styles from "./markers.module.css";
import useLang from "../../hooks/useLang";
import useMarkers from "./hooks/useMarkers";

const Markers = ({ markers, isLoading, markerStyle }) => {
  const lang = useLang();
  const { modifiedMarkers, onOpenIssue, setHighlight, unsetHighlight } =
    useMarkers(markers);
  let content;
  if (Array.isArray(modifiedMarkers)) {
    content = modifiedMarkers?.map((marker) => {
      return (
        <div
          onClick={() => {
            onOpenIssue(marker);
          }}
          onTouchStart={() => {
            onOpenIssue(marker);
          }}
          onMouseEnter={(event) => {
            event.stopPropagation();
            setHighlight(marker);
          }}
          onMouseLeave={(event) => {
            event.stopPropagation();
            unsetHighlight(marker);
          }}
          key={marker.id}
          className={classNames(styles.marker, {
            [styles.nonActive]: marker.nonActive,
            [styles.expandLeft]: marker.expandLeft,
          })}
          style={{
            ...markerStyle,
            top: marker.y,
            left: marker.expandLeft ? "unset" : marker.x,
            right: marker.expandLeft ? marker.rightX : "unset",
            transformOrigin: marker.expandLeft
              ? "top right"
              : markerStyle?.transformOrigin,
          }}
        >
          {`${marker.label}`}
        </div>
      );
    });
  }
  return (
    <>
      {content}
      {isLoading ? (
        <div className={styles.markersLoader}>{lang("loading")}</div>
      ) : null}
    </>
  );
};

export default Markers;
