import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { token: null, uid: null },
  reducers: {
    setCredentials: (state, action) => {
      const { token, uid } = action.payload;
      state.token = token;
      state.uid = uid || state.uid;
    },
    discardToken: (state) => {
      state.token = null;
    },
    ageToken: (state) => {
      state.token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI2MzdiNDQ5YjUzNTc0YWQ4YWYzOTJmMzUiLCJ0aWQiOiI2NDE0MzQwOWQ5YTI3NTY4ODg1OGE1NDgiLCJleHAiOjE2NzkxMDQ4MDB9.H6eJolZoioskaM_HXZCpi2Ggb_o4aj-6UmIbCIdPTAs";
    },
    logOut: (state) => {
      state.token = null;
      state.uid = null;
    },
  },
});

export const { setCredentials, discardToken, ageToken, logOut } =
  authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.uid;
export const selectCurrentToken = (state) => state.auth.token;
