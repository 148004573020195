import useUserFinder from "./hooks/useUserFinder";
import Input from "../../components/Input";
import useLang from "../../hooks/useLang";
import UserSelectCard from "./UserSelectCard";

import styles from "./userFinder.module.css";

const UserFinder = ({ onSelect, onEmailEnter }) => {
  const lang = useLang();
  const {
    suggestions,
    showSuggestions,
    inputValue,
    inputAttributes,
    isLoading,
  } = useUserFinder(onEmailEnter);

  let content;
  if (isLoading) content = <p>{lang("searching")}</p>;
  else if (suggestions?.ids?.length === 0)
    content = <p>{lang("no_matches_found")}</p>;
  else {
    content = suggestions?.ids?.map((id) => (
      <UserSelectCard
        key={id}
        user={suggestions?.entities[id]}
        onSelect={() => {
          onSelect(suggestions?.entities[id]);
        }}
      />
    ));
  }

  return (
    <div className={styles.userFinder}>
      <Input
        type={`${inputValue.includes("@") ? "email" : "text"}`}
        value={inputValue}
        {...inputAttributes}
      />
      {showSuggestions ? <div>{content}</div> : null}
    </div>
  );
};

export default UserFinder;
