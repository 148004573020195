import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import { useFreezeProjectMutation } from "./projectsSlice";
import useAlerts from "../alerts/useAlerts";
import useGroup from "../projectGroups/hooks/useGroup";
import { groupsApiSlice } from "../projectGroups/groupsSlice";

const ProjectFreezer = ({ project, rounded, ...rest }) => {
  const dispatch = useDispatch();
  const { onError } = useAlerts();
  const { group } = useGroup();
  const [freezeProject, { isLoading }] = useFreezeProjectMutation();

  const handleSubmit = async (e) => {
    if (e && e?.preventDefault) e.preventDefault();
    try {
      await freezeProject({
        params: { id: project.id },
        body: { frozen: !project?.frozen },
      }).unwrap();
      dispatch(
        groupsApiSlice.util.invalidateTags([{ type: "Group", id: group?.id }])
      );
    } catch (err) {
      onError(err);
    }
  };

  return (
    <AccessWrapper action={userActions.FREEZE_PROJECT}>
      {isLoading ? (
        <Button inline={!rounded} rounded={rounded} loader {...rest} />
      ) : (
        <Button
          inline={!rounded}
          rounded={rounded}
          icon={project.frozen ? "unfreeze" : "freeze"}
          onClick={handleSubmit}
          {...rest}
        />
      )}
    </AccessWrapper>
  );
};

export default ProjectFreezer;
