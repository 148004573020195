import { PageHeader } from "../../components/Header";
import { PageHeaderGroup } from "../../components/Group";
import useLang from "../../hooks/useLang";
import SingleComment from "../comments/SingleComment";
import { PageFooter } from "../../components/Footer";
import ErrorBoundary from "../../components/ErrorBoundary";
import CommentCreator from "../comments/CommentCreator";
import Button from "../../components/Button";
import useMarkingMode from "../marker/hooks/useMarkingMode";
import useMarkIssueAsSeen from "../issues/hooks/useMarkIssueAsSeen";

const AsideCommentsPanel = ({ issue, goBack }) => {
  const lang = useLang();
  const { marker } = useMarkingMode();
  useMarkIssueAsSeen(issue);

  let content = [];
  if (Array.isArray(issue?.messages)) {
    content = issue.messages?.map((comment) => (
      <SingleComment key={comment.id} comment={comment} />
    ));
  }

  return (
    <>
      <div style={{ margin: "var(--spacing-2) 0" }}>
        <Button inline emphasized icon={"arrowLeft"} onClick={goBack}>
          {lang("issues")}
        </Button>
      </div>
      <PageHeader>
        <PageHeaderGroup>
          <h2 style={{ marginTop: "var(--spacing-2)" }}>{issue?.title}</h2>
        </PageHeaderGroup>
      </PageHeader>
      {content}
      <PageFooter>
        <ErrorBoundary fallback="Error in create comment modal">
          <CommentCreator
            renderOpen={(onClick) => (
              <Button important={!marker} emphasized={marker} onClick={onClick}>
                {lang("add_comment")}
              </Button>
            )}
          />
        </ErrorBoundary>
      </PageFooter>
    </>
  );
};

export default AsideCommentsPanel;
