import classNames from "classnames";
import styles from "./footer.module.css";

const Footer = ({
  children,
  pageFooter,
  sectionFooter,
  dialogFooter,
  ...rest
}) => {
  return (
    <footer
      className={classNames(styles.header, {
        [styles.pageFooter]: pageFooter,
        [styles.sectionFooter]: sectionFooter,
        [styles.dialogFooter]: dialogFooter,
      })}
      {...rest}
    >
      {children}
    </footer>
  );
};

const SectionFooter = ({ children, ...rest }) => {
  return (
    <Footer sectionFooter {...rest}>
      {children}
    </Footer>
  );
};

const DialogFooter = ({ children, ...rest }) => {
  return (
    <Footer dialogFooter {...rest}>
      {children}
    </Footer>
  );
};

const PageFooter = ({ children, ...rest }) => {
  return (
    <Footer pageFooter {...rest}>
      {children}
    </Footer>
  );
};

export { SectionFooter, DialogFooter, PageFooter };

export default Footer;
