import useAlerts from "./useAlerts";
import useLang from "../../hooks/useLang";

const useAlertHandler = () => {
  const lang = useLang();
  const {
    error,
    errorMessage,
    hasOverride,
    override,
    requestBody,
    onReset,
    onOverride,
  } = useAlerts();
  const showModal = error != null;
  let title;
  if (error != null) title = lang("error");

  const handleOverrides = () => {
    onOverride();
    setTimeout(() => {
      onReset();
    }, 0);
  };

  return {
    showModal,
    hasOverride,
    overrideBody: { ...requestBody, ...override },
    message: errorMessage,
    title,
    onOverride: handleOverrides,
    onClose: onReset,
  };
};

export default useAlertHandler;
