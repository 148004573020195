import useUsers from "./useUsers";
import { useInitialsFromArray } from "../../comments/hooks/useInitials";

const useUsersWithInitials = () => {
  const { users, ...rest } = useUsers();
  const initials = useInitialsFromArray(users?.map((user) => user.username));
  let usersWithInitials = users?.map((user, idx) => ({
    ...user,
    initials: initials[idx],
  }));
  return { users: usersWithInitials, initials, ...rest };
};

export default useUsersWithInitials;
