import classNames from "classnames";
import ReactDOM from "react-dom";
import ErrorBoundary from "./ErrorBoundary";
import SideNav from "../features/navigation/SideNav";

import styles from "./aside.module.css";
const Aside = ({ asideAttributes }) => {
  return ReactDOM.createPortal(
    <>
      <aside
        className={classNames(styles.aside, {
          [styles.expanded]: asideAttributes?.expanded,
          [styles.collapsed]: !asideAttributes?.expanded,
        })}
      >
        <ErrorBoundary fallback="Error side nav">
          <SideNav {...asideAttributes} />
        </ErrorBoundary>
      </aside>
    </>,
    document.getElementById("sideNav")
  );
};

export default Aside;
