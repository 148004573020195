import { useParams } from "react-router-dom";
import { useCloseIssueMutation } from "../issuesSlice";
import { projectsApiSlice } from "../../projects/projectsSlice";
import { useDispatch } from "react-redux";
import useAlerts from "../../alerts/useAlerts";

const useIssueToggle = (issue) => {
  const { onError } = useAlerts();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [closeMutation, { ...rest }] = useCloseIssueMutation();

  const onIssueToggle = async () => {
    try {
      await closeMutation({
        params: { id: issue?.id },
        body: { status: issue?.status === "OPEN" ? "CLOSED" : "OPEN" },
      }).unwrap();
      dispatch(
        projectsApiSlice.util.invalidateTags([
          { type: "Project", id: projectId },
        ])
      );
    } catch (err) {
      onError(err);
    }
  };

  return { onIssueToggle, ...rest };
};

export default useIssueToggle;
