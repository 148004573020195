import Section from "../../components/Section";
import { SecondaryParagraph } from "../../components/Paragraph";
import useLang from "../../hooks/useLang";
import useOfferExcerpt from "./hooks/useOfferExcerpt";
import OwnerBadge from "../../components/OwnerBadge";
import Button from "../../components/Button";
import { SplitGroup, BundleGroup } from "../../components/Group";
import { ConfirmationModal } from "../../components/Modal";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const OfferExcerpt = ({ offer, vertical, project }) => {
  const lang = useLang();
  const { user, usersLoading, removeLoading, handleRemove, id } =
    useOfferExcerpt(offer, project);

  let sectionStyle = {};
  if (removeLoading) {
    sectionStyle.opacity = "0.5";
  }

  return (
    <>
      <Section boxed style={sectionStyle}>
        <SplitGroup style={{ alignItems: "flex-start" }}>
          <BundleGroup vertical={vertical}>
            {" "}
            <p>{`${offer.amount} ${offer.currency} (${
              offer.vat_included ? lang("vat_included") : lang("vat_excluded")
            })`}</p>
            {offer.notes ? (
              <SecondaryParagraph>{offer.notes}</SecondaryParagraph>
            ) : null}
          </BundleGroup>
          {id != null ? (
            <AccessWrapper
              action={userActions.DELETE_OFFER}
              offerOwner={offer?.owner_id}
            >
              <ConfirmationModal
                title={lang("remove_offer_confirmation_title")}
                onSubmit={handleRemove}
                renderOpen={(onClick) => (
                  <Button
                    inline
                    icon={"trashIcon"}
                    onClick={onClick}
                    disabled={removeLoading}
                  />
                )}
              >
                <p>{lang("remove_offer_confirmation_text")}</p>
              </ConfirmationModal>
            </AccessWrapper>
          ) : null}
        </SplitGroup>
        {!usersLoading ? (
          <div style={{ display: "flex", gap: "var(--spacing-1)" }}>
            <OwnerBadge user={user} />
          </div>
        ) : null}
      </Section>
    </>
  );
};

export default OfferExcerpt;
