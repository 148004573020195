import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import styles from "./popup.module.css";

const Popup = ({
  children,
  renderOpen,
  expandRight,
  expandBottomRight,
  expandLeft,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const popupRef = useRef(null);
  const location = useLocation();

  const togglePopup = (event) => {
    if (event) {
      if (typeof event.preventDefault === "function") event.preventDefault();
    }
    setIsOpen((state) => !state);
  };

  const closePopup = () => {
    setIsOpen((state) => (state === false ? state : false));
  };

  const handleClickOutside = (event) => {
    if (containerRef.current) {
      if (containerRef.current.contains(event.target)) {
        event.preventDefault();
      } else {
        closePopup();
      }
    } else if (popupRef.current) {
      if (popupRef.current.contains(event.target)) {
        event.preventDefault();
      } else {
        closePopup();
      }
    }
  };

  useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.popup} ref={containerRef}>
      {typeof renderOpen === "function"
        ? renderOpen((event) => {
            togglePopup(event);
          })
        : null}
      {isOpen ? (
        <div
          className={classNames(styles.popupContainer, {
            [styles.expandRight]: expandRight,
            [styles.expandLeft]: expandLeft,
            [styles.expandBottomRight]: expandBottomRight,
          })}
          ref={popupRef}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default Popup;
