import usePolicyChecker from "./usePolicyChecker";

const AccessWrapper = ({
  children,
  action,
  offerOwner,
  group: groupFromProps,
  project: projectFromProps,
  issue: issueFromProps,
}) => {
  const checkPassed = usePolicyChecker({
    action,
    projectFromProps,
    groupFromProps,
    issueFromProps,
    offerOwner,
  });
  if (!checkPassed) return false;
  return <>{children}</>;
};

export default AccessWrapper;
