import SelectCard from "../../components/SelectCard";
import Avatar from "./Avatar";

const UserSelectCard = ({ user, onSelect }) => {
  return (
    <SelectCard onSelect={onSelect}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "var(--spacing-2)",
        }}
      >
        <Avatar initials={user.initials} user={user} small />
        <h3>{user.username}</h3>
      </div>
    </SelectCard>
  );
};

export default UserSelectCard;
