import { useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import useGroup from "../features/projectGroups/hooks/useGroup";
import useProject from "../features/projects/hooks/useProject";
import useIssueById from "../features/issues/hooks/useIssueById";
import { PageNotFound, ProjectNotFound } from "../features/auth/NotFound";
import { isNotFoundError } from "../utils/errorUtils";

const NotFoundHandler = () => {
  const { issueId } = useParams();
  const { error: groupError } = useGroup(true);
  const { error: projectError } = useProject();
  const { error: issueError } = useIssueById(issueId);

  if (isNotFoundError(groupError)) {
    return <ProjectNotFound />;
  }
  if (isNotFoundError(projectError)) {
    return <ProjectNotFound />;
  }
  if (isNotFoundError(issueError)) {
    return <PageNotFound />;
  }

  return <Outlet />;
};

export default NotFoundHandler;
