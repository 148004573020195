import classNames from "classnames";
import Icon from "./Icon";
import Loader from "./Loader";
import { useState } from "react";

import styles from "./button.module.css";

const Button = ({
  children,
  inline,
  rounded,
  emphasized,
  warning,
  important,
  alert,
  icon,
  loader,
  ...rest
}) => {
  const [hover, setHover] = useState(false);
  let props = {};
  if (icon) props[icon] = true;
  if (emphasized) props.emphasized = true;
  if (warning) props.warning = true;
  if (important) props.important = true;
  if (alert) props.alert = true;
  if (hover) props.hover = true;

  return (
    <button
      disabled={rest?.disabled || loader}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className={classNames(styles.button, {
        [styles.base]: !inline && !rounded,
        [styles.inline]: inline,
        [styles.rounded]: rounded,
        [styles.emphasized]: emphasized,
        [styles.warning]: warning,
        [styles.important]: important,
        [styles.alert]: alert,
        [styles.iconOnly]: children == null,
        [styles.disabled]: (rest && rest.disabled) || loader,
      })}
      {...rest}
    >
      {icon && !loader ? <Icon {...props} /> : null}
      {loader ? (
        <Loader
          xs
          emphasized={emphasized}
          important={important}
          warning={warning}
          alert={alert}
        />
      ) : null}
      {children}
    </button>
  );
};

export default Button;
