import useLang from "../../../hooks/useLang";
import { useChangeStatusMutation } from "../groupsSlice";
import useAlerts from "../../alerts/useAlerts";
import useFetchAfterMutationStatus from "../../../hooks/useFetchAfterMutationStatus";

const actionsByStatus = {
  INQUIRY: ["OFFER"],
  OFFER: ["DECLINED", "PENDING"],
  PENDING: ["IN_PROGRESS", "CANCELLED", "ON_HOLD"],
  IN_PROGRESS: ["COMPLETED", "CANCELLED", "ON_HOLD"],
  COMPLETED: ["WARRANTY"],
  WARRANTY: ["COMPLETED"],
  ON_HOLD: ["IN_PROGRESS"],
  DECLINED: ["OFFER"],
};

const useStatusChanger = (group, status) => {
  const lang = useLang();
  const [changeStatusMutation, { isLoading: mutationLoading }] =
    useChangeStatusMutation();
  const { onError } = useAlerts();
  const { isLoading } = useFetchAfterMutationStatus(
    mutationLoading,
    group?.isFetching
  );

  const handleStatusChange = async (newStatus) => {
    try {
      const id = group?.id;
      await changeStatusMutation({
        params: { id },
        body: {
          status: newStatus,
        },
      }).unwrap();
    } catch (err) {
      onError(err);
    }
  };

  const modifiers =
    status && actionsByStatus[status]
      ? actionsByStatus[status]?.map((newStatus) => {
          return {
            label: lang(newStatus),
            status: newStatus,
            onClick: () => {
              handleStatusChange(newStatus);
            },
          };
        })
      : [];

  return {
    modifiers,
    isLoading,
  };
};

export default useStatusChanger;
