import useSlideId from "./useSlideId";
import useSearchParams from "../../../hooks/useSearchParams";

const useSlideshow = (frames, obsoleteFrames) => {
  const { getParam, addParam } = useSearchParams();
  const { slideId, closeSlide } = useSlideId();
  let slide = frames?.find((frame) => frame.id === slideId);
  if (slide == null) {
    slide = obsoleteFrames?.find((frame) => frame.id === slideId);
    if (slide != null) {
      slide = { ...slide, obsolete: true };
    }
  }
  const expanded = getParam("showIssues") === "true";

  return {
    show: slideId != null && slide != null,
    imgUrl: slide?.hires?.url,
    fileName: slide?.original?.name,
    frame: slide,
    onClose: closeSlide,
    onExpandAside: () => {
      addParam("showIssues", true);
    },
    onCollapseAside: () => {
      addParam("showIssues", false);
    },
    asideExpanded: expanded,
  };
};

export default useSlideshow;
