import { useEffect, useState } from "react";
import { useMyProfileQuery } from "../../auth/authApiSlice";
import useSearchParams from "../../../hooks/useSearchParams";
import { useVerifyEmailMutation } from "../../auth/authApiSlice";
import useAlerts from "../../alerts/useAlerts";

const useMyProfile = () => {
  const { data: myProfile } = useMyProfileQuery();
  const { getParam, removeParam } = useSearchParams();
  const { onError } = useAlerts();
  const [verifyEmailMutation] = useVerifyEmailMutation();
  const confirmEmailToken = getParam("confirm_email");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const verifyEmail = async (token) => {
    try {
      await verifyEmailMutation(token).unwrap();
      removeParam("confirm_email");
      setShowSuccessMessage(true);
    } catch (err) {
      onError(err);
    }
  };

  const onHideSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  useEffect(() => {
    if (confirmEmailToken != null) {
      verifyEmail(confirmEmailToken);
    }
    // eslint-disable-next-line
  }, [confirmEmailToken]);

  return { myProfile, showSuccessMessage, onHideSuccessMessage };
};

export default useMyProfile;
