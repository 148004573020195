import { useEffect } from "react";
import useSearchParams from "./useSearchParams";

const PAGE = "page";
const firstIndex = 1;
const minItemsPerPage = 1;
const defaultItemsPerPage = 15;

const usePagination = (itemsLength, itemsPerPage = defaultItemsPerPage) => {
  const { getParam, addParam } = useSearchParams();
  const pageParam = getParam(PAGE);
  const pageIndex = Number(pageParam) ? Number(pageParam) : firstIndex;
  const pageSize = Math.max(
    Number(itemsPerPage) ? Number(itemsPerPage) : 1,
    minItemsPerPage
  );
  const lastIndex = Math.max(Math.ceil(itemsLength / pageSize), firstIndex);

  const goToFirst = (event, replace) => {
    if (event) event.preventDefault();
    addParam(PAGE, firstIndex, replace);
  };

  const goToLast = (event) => {
    if (event) event.preventDefault();
    addParam(PAGE, lastIndex);
  };

  const goToNext = (event) => {
    if (event) event.preventDefault();
    addParam(PAGE, Math.min(pageIndex + 1, lastIndex));
  };

  const goToPrevious = (event) => {
    if (event) event.preventDefault();
    addParam(PAGE, Math.max(pageIndex - 1, firstIndex));
  };

  const goToPage = (pageIndex) => {
    addParam(PAGE, pageIndex);
  };

  useEffect(() => {
    goToFirst(null, true);
    // eslint-disable-next-line
  }, [itemsLength]);

  return {
    pageIndex,
    pageCount: lastIndex,
    pageSize,
    goToFirst,
    goToLast,
    goToNext,
    goToPrevious,
    goToPage,
  };
};

export default usePagination;
