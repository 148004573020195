import { useState, useEffect } from "react";
import { useHideFrameInGroupMutation } from "../../projectGroups/groupsSlice";
import { useHideFrameInProjectMutation } from "../../projects/projectsSlice";
import { useParams } from "react-router";

const apiForTypes = {
  VISUALIZATION: "visualizations",
  TECH_DRAWING: "drawings",
  COMMON_FILE: "common_files",
};

const useFrameHider = (frame) => {
  const [isLoading, setIsLoading] = useState(false);
  const { groupId, projectId } = useParams();
  const [hideInProjectMutation] = useHideFrameInProjectMutation();
  const [hideInGroupMutation] = useHideFrameInGroupMutation();
  const hideFrameMutation =
    frame.type === "COMMON_FILE" ? hideInGroupMutation : hideInProjectMutation;
  const showFrame = async () => {
    const body = { show: !frame.show };
    setIsLoading(true);
    try {
      await hideFrameMutation({
        groupId: groupId,
        projectId: projectId,
        frameId: frame.id,
        type: apiForTypes[frame.type],
        body,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, [frame.show]);

  return { showFrame, isLoading };
};

export default useFrameHider;
