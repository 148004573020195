import { useParams } from "react-router-dom";
import Link from "../../components/Link";

const CommentCounter = ({ issue }) => {
  const { groupId, projectId } = useParams();

  return (
    <div style={{ width: "var(--width-12)" }}>
      <Link to={`/${groupId}/${projectId}/issues/${issue.id}`} icon={"chat"}>
        {issue?.message_count ? issue?.message_count : null}
      </Link>
    </div>
  );
};

export default CommentCounter;
