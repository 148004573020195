import { useMyProfileQuery } from "./authApiSlice";
import useLang from "../../hooks/useLang";

const NotFound = ({ title }) => {
  const lang = useLang();
  const { data: myProfile, isLoading } = useMyProfileQuery();
  const username = isLoading ? "..." : myProfile?.username;
  const email = isLoading ? "..." : myProfile?.primary_email;

  return (
    <>
      <h2>{title.replace("%USERNAME%", username).replace("%EMAIL%", email)}</h2>
      <br />
      <p>{lang("get_invitation_message")}</p>
    </>
  );
};

const ProjectNotFound = () => {
  const lang = useLang();
  return <NotFound title={lang("project_not_found_title")} />;
};

const PageNotFound = () => {
  const lang = useLang();
  return <NotFound title={lang("page_not_found_title")} />;
};

export { ProjectNotFound, PageNotFound };
export default NotFound;
