import { useParams } from "react-router-dom";
import useSearchParams from "../../../hooks/useSearchParams";
import useIssueById from "./useIssueById";

const useIssue = () => {
  const { issueId } = useParams();
  const { getParam } = useSearchParams();
  const issueIdFromSearchParams = getParam("frameIssue");
  const issueIdForQuery = issueId || issueIdFromSearchParams;
  const { issue, ...rest } = useIssueById(issueIdForQuery, {
    skip: !issueIdForQuery,
  });
  return { issue, ...rest };
};

export default useIssue;
