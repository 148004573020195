export const getErrorMessage = (error) => {
  if (error == null) return "";
  if (typeof error === "string") return error;
  if (error.response && error.response.data && error.response.data.message)
    return error.response.data.message;
  if (error.data && error.data.message) return error.data.message;
  if (error.error) return error.error;
  if (error.description) return error.description;
  if (error.message) return error.message;
  if (error.errors && typeof error.errors === "string") return error.errors;
  if (error.errors && typeof Array.isArray(error.errors))
    return error.errors.join(" ");
  console.log(`getErrorMessage: ${error} error format unknown`);
  return Object.entries(error).reduce((str, [p, val]) => {
    return `${str}${p}::${val}\n`;
  }, "");
};

export const isNotFoundError = (error) => {
  if (
    error?.status === 500 ||
    error?.status === 400 ||
    getErrorMessage(error).includes("role checking was not performed")
  )
    return true;
  return false;
};
