import useMyProfile from "./useMyProfile";
import useGroup from "../projectGroups/hooks/useGroup";
import useProject from "../projects/hooks/useProject";
import useSearchParams from "../../hooks/useSearchParams";
import { useGetPoliciesQuery } from "../users/usersSlice";
import { localPolicies } from "../../config/accessConfig";

const usePolicyChecker = ({
  action,
  projectFromProps,
  groupFromProps,
  offerOwner,
}) => {
  const { data: policiesFromApi } = useGetPoliciesQuery();
  const { user } = useMyProfile();
  const { getParam } = useSearchParams();
  const owner = getParam("owner");
  const { group: groupFromApi } = useGroup();
  const { project: projectFromApi } = useProject();
  const group =
    groupFromApi && Object.keys(groupFromApi).length > 0
      ? groupFromApi
      : groupFromProps;
  const project =
    projectFromApi && Object.keys(projectFromApi).length > 0
      ? projectFromApi
      : projectFromProps;

  if (action == null) return false;
  let policies = localPolicies[action];
  if (policiesFromApi) {
    const allPolicies = { ...localPolicies, ...policiesFromApi };
    policies = allPolicies[action];
  }
  if (policies == null) return false;
  if (!Array.isArray(policies)) return false;
  if (policies.length === 0) return false;
  let policiesByRole = {};

  const snakeToCamel = (str) => {
    return str.replace(/_([a-z])/g, (match, letter) => {
      return letter.toUpperCase();
    });
  };

  const dotToCamel = (str) => {
    return str.replace(/\.(.)/g, (match, letter) => {
      return letter.toUpperCase();
    });
  };

  const policiesRenamed = policies.map((policy) => {
    const result = {};
    if (typeof policy === "string") return policy;
    const policyKeys = Object.keys(policy);
    policyKeys.map((policyKey) => {
      const camelCaseKey = dotToCamel(snakeToCamel(policyKey.replace("$", "")));
      result[camelCaseKey] = policy[policyKey];
      return false;
    });
    return result;
  });

  policiesRenamed.map((policy) => {
    if (policy.role) {
      policy.role?.map((roleName) => {
        const { role, ...rest } = policy;
        policiesByRole[roleName] = rest;
        if (policiesByRole[roleName].projectFrozen == null)
          policiesByRole[roleName].projectFrozen = [false, null];
        return false;
      });
    } else if (typeof policy === "string") {
      policiesByRole[policy] = { projectFrozen: [false, null] };
    }
    return false;
  });

  let role = user?.role;
  if (role == null && owner != null) {
    if (owner === "my") {
      role = "OWNER";
    } else if (owner === "shared") {
      role = "CUSTOMER";
    } else {
      role = "ADMIN";
    }
  }
  if (role == null) return false;
  const rolePolicy = policiesByRole[role];
  if (rolePolicy == null) return false;

  const isInPolicy = (policy, value) => {
    if (Array.isArray(policy)) {
      return policy.indexOf(value) >= 0;
    }
    return policy === value;
  };

  if (rolePolicy.groupStatus != null) {
    if (!isInPolicy(rolePolicy.groupStatus, group?.status)) return false;
  }
  if (rolePolicy.projectFrozen != null) {
    if (!isInPolicy(rolePolicy.projectFrozen, project?.frozen === true))
      return false;
  }
  if (rolePolicy.offerIsOwner != null) {
    if (
      !isInPolicy(
        rolePolicy.offerIsOwner,
        offerOwner && offerOwner === user?.id
      )
    )
      return false;
  }
  return true;
};

export default usePolicyChecker;
