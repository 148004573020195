import ErrorBoundary from "../../components/ErrorBoundary";
import HomeLink from "./HomeLink";
import ProjectMarker from "./ProjectMarker";
import MenuList from "./MenuList";
import MenuListItem from "./MenuListItem";
import Button from "../../components/Button";
import { useParams } from "react-router-dom";
import useLang from "../../hooks/useLang";
import { SecondaryParagraph } from "../../components/Paragraph";
import useScreenSize from "../../hooks/useScreenSize";
import useGroup from "../projectGroups/hooks/useGroup";
import useIssuesByStatus from "../issues/hooks/useIssuesByStatus";
import { UnseenIssuesChip, OpenIssuesChip } from "../issues/IssueCounterChip";
import { SplitGroup, BundleGroup } from "../../components/Group";
import { CountChip } from "../../components/Chip";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import useMyProfile from "../auth/useMyProfile";

const SideNav = ({ expanded, onToggle }) => {
  const lang = useLang();
  const { groupId, projectId } = useParams();
  const { isMobile } = useScreenSize();
  const { openIssues, unseenIssues } = useIssuesByStatus();
  const { group } = useGroup();
  const { user } = useMyProfile();

  const activeProjects = group?.projects?.filter((project) => !project?.frozen);
  let projectsWithOffers = 0;
  activeProjects?.map((project) => {
    if (user?.role === "SUPPLIER") {
      if (project?.offers?.outgoing?.length > 0) {
        projectsWithOffers = projectsWithOffers + 1;
      }
    } else {
      if (project?.offers?.incoming?.length > 0) {
        projectsWithOffers = projectsWithOffers + 1;
      }
    }
    return false;
  });

  return (
    <>
      <nav>
        {expanded ? (
          <SplitGroup>
            <ErrorBoundary fallback="Error in home link">
              <HomeLink expanded />
            </ErrorBoundary>
          </SplitGroup>
        ) : (
          <BundleGroup vertical>
            <ErrorBoundary fallback="Error in home link">
              <HomeLink />
            </ErrorBoundary>
          </BundleGroup>
        )}
        <hr />
        <ErrorBoundary fallback="Error in project marker">
          <ProjectMarker expanded={expanded} />
        </ErrorBoundary>
        <ErrorBoundary fallback="Error in menu list">
          {groupId ? (
            <MenuList>
              {projectId ? (
                <>
                  <MenuListItem
                    icon={"description"}
                    linkTo={`${groupId}/${projectId}/specs`}
                    label={lang("specs")}
                    expanded={expanded}
                  />
                  <MenuListItem
                    icon={"issues"}
                    linkTo={`${groupId}/${projectId}/issues`}
                    label={lang("issues")}
                    expanded={expanded}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "var(--spacing-1)",
                      }}
                    >
                      {expanded && unseenIssues && unseenIssues.length > 0 ? (
                        <UnseenIssuesChip
                          unseen={unseenIssues.length}
                          noIcon
                          noLink
                        />
                      ) : null}
                      {expanded && openIssues && openIssues.length > 0 ? (
                        <OpenIssuesChip
                          open={openIssues.length}
                          noIcon
                          noLink
                          darker
                        />
                      ) : null}
                    </div>
                  </MenuListItem>
                  <AccessWrapper action={userActions.SHOW_PROJECT_OFFERS_PAGE}>
                    <MenuListItem
                      icon={"offer"}
                      linkTo={`${groupId}/${projectId}/offers`}
                      label={lang("price_offers")}
                      expanded={expanded}
                    />
                  </AccessWrapper>
                  <MenuListItem
                    icon={"members"}
                    linkTo={`${groupId}/${projectId}/members`}
                    label={lang("members")}
                    expanded={expanded}
                  />
                </>
              ) : (
                <>
                  <MenuListItem
                    icon={"members"}
                    linkTo={`${groupId}/members`}
                    label={lang("members")}
                    expanded={expanded}
                  />
                  <AccessWrapper action={userActions.SHOW_GROUP_OFFERS_PAGE}>
                    <MenuListItem
                      icon={"offer"}
                      linkTo={`${groupId}/offers`}
                      label={lang("price_offers")}
                      expanded={expanded}
                    >
                      {" "}
                      {expanded &&
                      group &&
                      group?.projects &&
                      group?.projects?.length > 0 ? (
                        <CountChip
                          darker
                        >{`${projectsWithOffers}/${activeProjects?.length}`}</CountChip>
                      ) : null}
                    </MenuListItem>
                  </AccessWrapper>
                </>
              )}
            </MenuList>
          ) : null}
        </ErrorBoundary>
      </nav>
      <div
        style={{
          marginTop: "var(--spacing-6)",
          marginBottom: "var(--spacing-6)",
          marginLeft: "var(--spacing-3)",
        }}
      >
        {!isMobile ? (
          <>
            <div style={{ height: "var(--spacing-2)" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "var(--spacing-2)",
              }}
            >
              <Button
                icon={`${expanded ? "collapseLeft" : "collapseRight"}`}
                onClick={onToggle}
              />
              {expanded ? (
                <SecondaryParagraph>{lang("collapse")}</SecondaryParagraph>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default SideNav;
