import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";

const usersAdapter = createEntityAdapter();
const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsersByFragment: builder.query({
      query: (fragment) => `/users/by_fragment/${fragment}`,
      transformResponse: (response) => {
        return usersAdapter.setAll(initialState, response);
      },
      providesTags: [{ type: "UserByFragment", id: (fragment) => fragment }],
    }),
    getPolicies: builder.query({
      query: () => "/policies",
    }),
  }),
});

export const { useGetUsersByFragmentQuery, useGetPoliciesQuery } =
  usersApiSlice;
