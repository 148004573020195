import { useState, useEffect } from "react";

const useNameConflictHandler = (placeholders, onResolve) => {
  const [handlerCalled, setHandlerCalled] = useState(false);
  const [isExistingCounter, setIsExistingCounter] = useState(0);
  const [isUploadingOpen, setIsUploadingOpen] = useState(false);
  const [existingFiles, setExistingFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [overwrites, setOverWrites] = useState([]);

  useEffect(() => {
    const existingFiles = placeholders.filter(
      (placeholder) => placeholder.message_code === "EXISTS"
    );
    const uploadingFiles = placeholders.filter(
      (placeholder) => placeholder.message_code === "UPLOADING"
    );
    setExistingFiles(existingFiles);
    setUploadingFiles(uploadingFiles);

    if (existingFiles.length) {
      setIsExistingCounter(existingFiles.length);
      setOverWrites([]);
      setHandlerCalled(true);
    }
    if (uploadingFiles.length) {
      setIsUploadingOpen(true);
      setHandlerCalled(true);
    }
    if (!existingFiles.length && !uploadingFiles.length) {
      onResolve([]);
    }
    // eslint-disable-next-line
  }, [placeholders]);

  const onCancelExisting = () => {
    setExistingFiles((state) => state.slice(1));
    setIsExistingCounter((state) => state - 1);
  };

  const onSubmitExisting = () => {
    setOverWrites((state) => {
      const newState = [...state, { ...existingFiles[0], overwrite: true }];
      return newState;
    });
    setExistingFiles((state) => state.slice(1));
    setIsExistingCounter((state) => state - 1);
  };

  const onConfirm = () => {
    setIsUploadingOpen(false);
  };

  useEffect(() => {
    if (handlerCalled && isExistingCounter <= 0 && !isUploadingOpen) {
      const placeholdersWithoutConflicts = placeholders.filter(
        (placeholer) => placeholer.message_code == null
      );
      onResolve([...placeholdersWithoutConflicts, ...overwrites]);
      setExistingFiles([]);
      setUploadingFiles([]);
      setOverWrites([]);
      setHandlerCalled(false);
    }
    // eslint-disable-next-line
  }, [handlerCalled, isUploadingOpen, isExistingCounter]);

  return {
    isExistingOpen: isExistingCounter > 0,
    isUploadingOpen: isExistingCounter <= 0 && isUploadingOpen,
    existingFiles,
    uploadingFiles,
    onCancelExisting,
    onSubmitExisting,
    onConfirm,
  };
};

export default useNameConflictHandler;
