import useProject from "../../projects/hooks/useProject";
import useGroup from "../../projectGroups/hooks/useGroup";
import { useParams } from "react-router";

const useOffersOverview = () => {
  const { groupId, projectId } = useParams();
  const { project, isLoading } = useProject();
  const { group } = useGroup();

  const incommingOffers = project?.offers?.incoming
    ? project?.offers?.incoming
    : [];
  const outgoingOffers = project?.offers?.outgoing
    ? project?.offers?.outgoing
    : [];

  const activeProjects = group?.projects?.filter((project) => !project.frozen);

  return {
    isGroup: projectId == null,
    groupId,
    project,
    projects: activeProjects || [],
    incommingOffers,
    outgoingOffers,
    isLoading,
  };
};

export default useOffersOverview;
