import classNames from "classnames";
import styles from "./screener.module.css";
import useLang from "../hooks/useLang";
import { useEffect } from "react";

const Screener = ({ children, dialog, onClick }) => {
  useEffect(() => {
    document.body.classList.add("overlay-open");
    return () => {
      document.body.classList.remove("overlay-open");
    };
  }, []);

  return (
    <div
      className={classNames(styles.screener, { [styles.dialog]: dialog })}
      onClick={() => {
        if (typeof onClick === "function") {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};

const SlideshowScreener = ({ children, onClick }) => {
  useEffect(() => {
    document.body.classList.add("slideshow-open");
    return () => {
      document.body.classList.remove("slideshow-open");
    };
  }, []);

  return (
    <div
      className={classNames(styles.screener)}
      onClick={() => {
        if (typeof onClick === "function") {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};

const DialogScreener = ({ children, show }) => {
  if (!show) return null;
  return <Screener dialog>{children}</Screener>;
};

const DialogLoadingScreener = ({ show }) => {
  const lang = useLang();
  return (
    <DialogScreener show={show}>
      <p>{lang("Loading")}</p>
    </DialogScreener>
  );
};

export { DialogScreener, DialogLoadingScreener, SlideshowScreener };
export default Screener;
