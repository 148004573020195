import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMarkIssueAsSeenMutation } from "../issuesSlice";
import { projectsApiSlice } from "../../projects/projectsSlice";
import { groupsApiSlice } from "../../projectGroups/groupsSlice";
import { useDispatch } from "react-redux";

const useMarkIssueAsSeen = (issue) => {
  const dispatch = useDispatch();
  const { groupId, projectId } = useParams();
  const [markAsSeenMutation] = useMarkIssueAsSeenMutation();

  const markAsSeen = async () => {
    try {
      await markAsSeenMutation({
        params: { issueId: issue?.id },
      }).unwrap();
      dispatch(
        projectsApiSlice.util.invalidateTags([
          { type: "Project", id: projectId },
        ])
      );
      dispatch(
        groupsApiSlice.util.invalidateTags([{ type: "Group", id: groupId }])
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (issue?.unseen && issue?.message_count === 0) {
      markAsSeen();
    }
    // eslint-disable-next-line
  }, []);

  return {};
};

export default useMarkIssueAsSeen;
