import useLang from "../../../hooks/useLang";
import useUsers from "../../users/hooks/useUsers";
import useInitials from "../../comments/hooks/useInitials";
import { useRemoveOfferMutation } from "../../projects/projectsSlice";
import { groupsApiSlice } from "../../projectGroups/groupsSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import useAlerts from "../../alerts/useAlerts";

const useOfferExcerpt = (offer, project) => {
  const lang = useLang();
  const [removeLoading, setRemoveLoading] = useState(false);
  const { users, isLoading: usersLoading } = useUsers();
  const { groupId, projectId } = useParams();
  const dispatch = useDispatch();
  const { onError } = useAlerts();

  const [removeOfferMutation] = useRemoveOfferMutation();
  let name = lang("anonymous");
  const foundUser = users.find((user) => user.id === offer.owner_id);
  name = foundUser?.username || name;
  const initials = useInitials(name);
  let id = projectId;
  if (project?.id != null) id = project?.id;

  const handleRemove = async () => {
    if (id == null) {
      return false;
    }
    try {
      setRemoveLoading(true);
      const response = await removeOfferMutation({
        params: { id, offerId: offer?.id },
      });
      if (response?.error) {
        throw response.error;
      }
      dispatch(
        groupsApiSlice.util.invalidateTags([{ type: "Group", id: groupId }])
      );
    } catch (err) {
      setRemoveLoading(false);
      onError(err);
      console.error(err);
    }
  };

  useEffect(() => {
    setRemoveLoading(false);
  }, [offer]);

  return {
    name,
    initials,
    user: foundUser,
    usersLoading,
    handleRemove,
    removeLoading,
    id,
  };
};

export default useOfferExcerpt;
