import { apiSlice } from "../api/apiAuthenticatedSlice";

export const recipesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecipes: builder.query({
      query: () => `/recipes`,
      providesTags: [{ type: "Recipe" }],
    }),
  }),
});

export const { useGetRecipesQuery } = recipesApiSlice;
