import useMyProfile from "../auth/useMyProfile";
import Avatar from "../users/Avatar";
import Popup from "../../components/Popup";
import useAuth from "../auth/useAuth";
import useLang from "../../hooks/useLang";
import Link from "../../components/Link";

import styles from "./profileButton.module.css";

const ProfileButton = ({ expanded, right }) => {
  const lang = useLang();
  const { user } = useMyProfile();
  const { getLogoutUrl } = useAuth();

  const handleLogout = (event) => {
    event.preventDefault();
    window.location.href = getLogoutUrl();
  };

  return (
    <div className={styles.profileButton} style={{ cursor: "pointer" }}>
      <Popup
        expandRight={!right && !expanded}
        expandLeft={right}
        renderOpen={(onClick) => <Avatar user={user} onClick={onClick} />}
      >
        {user?.username ? (
          <>
            <div style={{ whiteSpace: "nowrap" }}>{user?.username}</div>
            <hr />
          </>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "var(--spacing-2)",
          }}
        >
          <Link to={"/profile"}>{lang("profile")}</Link>
          <a
            href={getLogoutUrl()}
            onClick={handleLogout}
            style={{
              padding: "0 var(--spacing-1)",
              display: "flex",
              flexGrow: "1",
            }}
          >
            {lang("logout")}
          </a>
        </div>
      </Popup>
    </div>
  );
};

export default ProfileButton;
