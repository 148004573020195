import { SplitGroup, BundleGroup } from "../../components/Group";
import { TertiaryParagraph } from "../../components/Paragraph";

const GroupExcerptTemplate = ({
  groupName,
  dateCreated,
  dateModified,
  status,
  issues,
  unseenIssues,
  openIssues,
  isHeader,
  owner,
}) => {
  return (
    <SplitGroup>
      <BundleGroup>
        <BundleGroup width={24} style={{ overflow: "unset" }}>
          {status}
        </BundleGroup>
        {groupName}
      </BundleGroup>
      <BundleGroup>
        <BundleGroup width={28}>{dateCreated}</BundleGroup>
        <BundleGroup width={28}>{dateModified}</BundleGroup>
        {isHeader ? (
          <BundleGroup vertical centered>
            <BundleGroup width={32} centered>
              {issues}
            </BundleGroup>
            <BundleGroup>
              <BundleGroup width={16} centered>
                <TertiaryParagraph>{unseenIssues}</TertiaryParagraph>
              </BundleGroup>
              <BundleGroup width={16} centered>
                <TertiaryParagraph>{openIssues}</TertiaryParagraph>
              </BundleGroup>
            </BundleGroup>
          </BundleGroup>
        ) : (
          <>
            <BundleGroup width={16} alignRight>
              {unseenIssues}
            </BundleGroup>
            <BundleGroup width={16} alignRight>
              {openIssues}
            </BundleGroup>
          </>
        )}
      </BundleGroup>
    </SplitGroup>
  );
};

export default GroupExcerptTemplate;
