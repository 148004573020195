const getInitials = (name) => {
  if (!name) return "--";
  let initials;
  const [firstName, lastName] = name.split(" ");
  if (!lastName) {
    initials = firstName.slice(0, 2).toUpperCase();
  } else {
    initials = [firstName.slice(0, 1), lastName.slice(0, 1)]
      .join("")
      .toUpperCase();
  }
  return initials;
};

const useInitials = (name) => {
  return getInitials(name);
};

const useInitialsFromArray = (nameArray) => {
  if (!Array.isArray(nameArray)) {
    if (!nameArray) return "--";
    if (typeof nameArray === "string") return getInitials(nameArray);
  }
  if (nameArray.length === 0) return "--";
  const toReturn = nameArray?.map((name) => {
    const result = getInitials(name);
    return result;
  });
  return toReturn;
};

export { useInitialsFromArray };
export default useInitials;
