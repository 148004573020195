import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router";
import { projectsApiSlice } from "../../projects/projectsSlice";
import { groupsApiSlice } from "../../projectGroups/groupsSlice";
import { useDispatch } from "react-redux";
import useFileReader from "./useFileReader";
import usePlaceholders from "./usePlaceholders";
import useUploader from "./useUploader";
import { userActions } from "../../../config/accessConfig";
import useProject from "../../projects/hooks/useProject";

const actionByType = {
  VISUALIZATION: userActions.POST_VISUALIZATIONS,
  TECH_DRAWING: userActions.POST_DRAWINGS,
  COMMON_FILE: userActions.POST_COMMON_FILES,
};

const visibilityActionByType = {
  VISUALIZATION: userActions.PATCH_VISUALIZATIONS_VISIBILITY,
  TECH_DRAWING: userActions.PATCH_DRAWINGS_VISIBILITY,
  COMMON_FILE: userActions.PATCH_COMMON_FILES_VISIBILITY,
};

const useVisualsExcerpt = (visuals, type) => {
  const dispatch = useDispatch();
  const { groupId, projectId } = useParams();
  const { project } = useProject();
  const originalFrames = Array.isArray(visuals?.frames) ? visuals?.frames : [];
  let files = [];
  if (Array.isArray(visuals?.files)) files = visuals?.files;
  if (Array.isArray(project?.common_files?.files)) {
    const commonFiles = project?.common_files?.files?.map((file) => ({
      ...file,
      commonFile: true,
    }));
    files = [...files, ...commonFiles];
  }

  const frames = originalFrames?.map((frame) => {
    const file = files.find((file) => file.id === frame.original.id);
    return { ...frame, original: { ...file } };
  });
  const [placeholdersFromApi, setPlaceholdersFromApi] = useState([]);
  const placeholdersRef = useRef();
  const uploaderRef = useRef();
  const [placeholdersPaused, setPlaceholdersPaused] = useState([]);
  const [showHidden, setShowHidden] = useState(false);

  const obsoleteFrames = project?.obsolete?.frames || [];

  const {
    readFiles,
    reset: resetFileReader,
    resetExcept: resetFileReaderExcept,
    data: uploads,
    isFinished: readingFinished,
  } = useFileReader();
  const {
    getPlaceHolders,
    reset: resetPlaceholders,
    data: placeholders,
    isFinished: getPlaceholdersFinished,
  } = usePlaceholders({
    type,
    groupId,
    projectId,
  });
  const {
    uploadFiles,
    data: placeholdersWithProgress,
    isFinished: uploadFinished,
    resetPlaceholder,
    finishedCounter,
  } = useUploader();

  const handleDrop = async (files) => {
    readFiles(files);
  };

  const onResolve = (overwrites) => {
    getPlaceHolders(overwrites);
    resetFileReaderExcept(overwrites);
  };

  const handleUploadClick = (e) => {
    e.preventDefault();
    uploaderRef.current.click();
  };

  const handleFileChange = (e) => {
    handleDrop(e.target.files);
    uploaderRef.current.value = null;
  };

  const filenameFound = (newFile, fileArray) => {
    const foundIndex =
      fileArray && Array.isArray(fileArray)
        ? fileArray.findIndex(
            (file) => file.name.toLowerCase() === newFile.name.toLowerCase()
          )
        : -1;
    return foundIndex >= 0;
  };

  const checkForConflicts = (uploads, files, placeholders) => {
    let withoutConflicts = [];
    let existingFiles = [];
    let uploadingFiles = [];
    if (uploads && Array.isArray(uploads)) {
      uploads.map((upload) => {
        let hasConflicts = false;
        if (filenameFound(upload, files)) {
          hasConflicts = true;
          existingFiles = [
            ...existingFiles,
            { ...upload, message_code: "EXISTS" },
          ];
        }
        if (filenameFound(upload, placeholders)) {
          hasConflicts = true;
          uploadingFiles = [
            ...uploadingFiles,
            { ...upload, message_code: "UPLOADING" },
          ];
        }
        if (!hasConflicts) {
          withoutConflicts = [...withoutConflicts, upload];
        }
        return false;
      });
      return { withoutConflicts, existingFiles, uploadingFiles };
    }
  };

  useEffect(() => {
    if (readingFinished) {
      const { withoutConflicts, existingFiles, uploadingFiles } =
        checkForConflicts(uploads, visuals?.files, visuals?.placeholders);
      if (existingFiles?.length > 0 || uploadingFiles?.length > 0) {
        setPlaceholdersPaused([
          ...withoutConflicts,
          ...existingFiles,
          ...uploadingFiles,
        ]);
        resetFileReader();
      } else {
        getPlaceHolders(withoutConflicts);
      }
    }
    // eslint-disable-next-line
  }, [readingFinished]);

  useEffect(() => {
    if (getPlaceholdersFinished) {
      const filteredPlaceHolders = placeholders.filter(
        (placeholder) => placeholder.placeholder_url != null
      );
      const pausedPlaceholders = placeholders.filter(
        (placeholder) => placeholder.placeholder_url == null
      );
      if (filteredPlaceHolders.length) {
        uploadFiles(filteredPlaceHolders);
      }
      if (pausedPlaceholders) {
        setPlaceholdersPaused(pausedPlaceholders);
      }
      resetFileReader();
    }
    // eslint-disable-next-line
  }, [getPlaceholdersFinished]);

  useEffect(() => {
    if (uploadFinished) {
      resetPlaceholders();
    }
    // eslint-disable-next-line
  }, [uploadFinished]);

  useEffect(() => {
    if (finishedCounter > 0) {
      if (projectId) {
        dispatch(
          projectsApiSlice.util.invalidateTags([
            { type: "Project", id: projectId },
          ])
        );
      } else {
        dispatch(
          groupsApiSlice.util.invalidateTags([{ type: "Group", id: groupId }])
        );
      }
    }
    // eslint-disable-next-line
  }, [finishedCounter]);

  useEffect(() => {
    if (visuals?.placeholders && visuals?.placeholders?.length > 0) {
      setPlaceholdersFromApi(visuals?.placeholders);
    } else if (placeholdersFromApi.length > 0) {
      setPlaceholdersFromApi([]);
    }
    // eslint-disable-next-line
  }, [visuals]);

  useEffect(() => {
    placeholdersRef.current = placeholdersFromApi;
  }, [placeholdersFromApi]);

  const mergePlaceholders = (oldArray, newArray) => {
    let merged;
    if (oldArray.length > 0) {
      merged = oldArray.map((oldItem) => {
        const newValue = newArray.find(
          (newItem) =>
            oldItem.id === newItem.file_id ||
            oldItem.id === newItem.placeholder_id
        );
        if (newValue) {
          return newValue;
        }
        return oldItem;
      });
      if (merged.length !== newArray.length) {
        const unmerged = newArray.filter(
          (newItem) =>
            merged.findIndex((mergedItem) => mergedItem.id === newItem.id) < 0
        );
        return [...merged, ...unmerged];
      }
      return merged;
    }
    return newArray;
  };

  const getAllPlaceHolders = (
    uploads,
    placeholders,
    placeholdersWithProgress,
    placeholdersFromApi
  ) => {
    let result = [];
    if (placeholdersFromApi?.length > 0) {
      const filteredFromApi = placeholdersFromApi.filter(
        (placeholderFromApi) => placeholderFromApi.state !== "UPLOADING"
      );
      result = mergePlaceholders(result, filteredFromApi);
    }
    if (uploads.length > 0) {
      result = [...uploads];
    }
    if (placeholders.length > 0) {
      result = mergePlaceholders(result, placeholders);
    }
    if (placeholdersWithProgress.length > 0) {
      result = mergePlaceholders(result, placeholdersWithProgress);
    }
    if (placeholdersFromApi?.length > 0) {
      const filteredFromApi = placeholdersFromApi.filter(
        (placeholderFromApi) => placeholderFromApi.state !== "UPLOADING"
      );
      result = mergePlaceholders(result, filteredFromApi);
    }
    return result;
  };

  const allPlaceholders = getAllPlaceHolders(
    uploads,
    placeholders,
    placeholdersWithProgress,
    placeholdersFromApi
  );
  const getProgressFromApi = async (placeholder) => {
    try {
      const response = await fetch(placeholder.progress_url);
      const data = await response.json();
      if (data.state === "UPLOADING") {
        return false;
      }
      resetPlaceholder(placeholder);
      if (data.state === "COMPLETE") {
        if (projectId) {
          dispatch(
            projectsApiSlice.util.invalidateTags([
              { type: "Project", id: projectId },
            ])
          );
        } else {
          dispatch(
            groupsApiSlice.util.invalidateTags([{ type: "Group", id: groupId }])
          );
        }
      } else {
        setPlaceholdersFromApi((state) => {
          const newState = state.map((placeholderFromState) => {
            if (
              placeholderFromState.placeholder_id === placeholder.placeholder_id
            ) {
              return {
                ...placeholderFromState,
                progress: Math.round(
                  (data.progress / Math.max(data.total, 1)) * 100
                ),
                state: data.state,
              };
            }
            return placeholderFromState;
          });
          return newState;
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const trackProgress = () => {
    if (!placeholdersRef.current) return false;
    if (!Array.isArray(placeholdersRef.current)) return false;
    placeholdersRef.current.map((placeholder) => {
      if (placeholder.progress_url) {
        getProgressFromApi(placeholder);
      }
      return false;
    });
  };

  useEffect(() => {
    const intervalId = setInterval(trackProgress, 1000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, []);

  const onShowHidden = () => {
    setShowHidden((state) => !state);
  };

  const framesWithType = frames.map((frame) => {
    return {
      ...frame,
      type,
    };
  });
  const slideshowFrames = framesWithType.filter((frame) => frame?.show);

  return {
    project,
    frames: framesWithType,
    obsoleteFrames,
    slideshowFrames,
    placeholders: allPlaceholders,
    handleDrop,
    placeholdersPaused,
    onResolve,
    handleUploadClick,
    fileInputAttribs: { ref: uploaderRef, onChange: handleFileChange },
    showHidden,
    onShowHidden,
    action: actionByType[type],
    visibilityAction: visibilityActionByType[type],
  };
};

export default useVisualsExcerpt;
