import { useState } from "react";

const useModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
    if (typeof props?.onCancel === "function") props.onCancel();
  };

  return { isOpen, onOpen, onClose };
};

export default useModal;
