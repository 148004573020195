import { useState } from "react";
import classNames from "classnames";

import styles from "./dragAndDropArea.module.css";

const DragAndDropArea = ({ children, onDrop, canDrop, showOutline}) => {
  const [dragging, setDragging] = useState(0);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items.length > 0) {
      setDragging((state) => state + 1);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging((state) => Math.max(state - 1, 0));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(0);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = e.dataTransfer.files;
      e.dataTransfer.clearData();
      if (typeof onDrop === "function" && canDrop) onDrop(files);
    }
  };

  return (
    <div
      className={classNames(styles.dragAndDropArea, {
        [styles.dragging]: dragging > 0,
        [styles.notDragging]: dragging <= 0,
        [styles.restricted]: !canDrop,
        [styles.withOutline]: showOutline,
      })}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {children}
    </div>
  );
};

export default DragAndDropArea;
