import { useState } from "react";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import ErrorBoundary from "../../components/ErrorBoundary";
import Form from "../../components/Form";
import FormSection from "../../components/FormSection";
import { useRemoveProjectMutation } from "./projectsSlice";
import useLang from "../../hooks/useLang";
import useAlerts from "../alerts/useAlerts";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { groupsApiSlice } from "../projectGroups/groupsSlice";
import { useDispatch } from "react-redux";

const RemoveProjectButton = ({ project, ...rest }) => {
  const lang = useLang();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onError } = useAlerts();
  const [modalOpen, setModalOpen] = useState(false);
  const [removeProject, { isLoading }] = useRemoveProjectMutation();
  const { groupId } = useParams();

  const handleSubmit = async (e) => {
    if (e && e?.preventDefault) e.preventDefault();
    try {
      await removeProject(project.id).unwrap();
      dispatch(
        groupsApiSlice.util.invalidateTags([{ type: "Group", id: groupId }])
      );
      navigate(`/${groupId}`);
    } catch (err) {
      onError(err);
    } finally {
      setModalOpen(false);
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  let content;
  if (isLoading) {
    content = <Loader />;
  } else {
    content = (
      <Form modal onSubmit={handleSubmit}>
        <h2>Pašalinti</h2>
        <p>
          {lang("remove_project_confirmation_text").replace(
            "%PROJECT_NAME%",
            project?.name
          )}
        </p>
        <FormSection alignRight>
          <Button onClick={handleCancel} autoFocus>
            Ne
          </Button>
          <Button alert type="submit">
            Taip
          </Button>
        </FormSection>
      </Form>
    );
  }

  return (
    <>
      <AccessWrapper action={userActions.DELETE_PROJECT}>
        {isLoading ? (
          <Button inline loader {...rest} />
        ) : (
          <Button
            inline
            icon={"trashIcon"}
            onClick={() => {
              setModalOpen(true);
            }}
            {...rest}
          />
        )}
        <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <ErrorBoundary fallback="Error in remove project form">
            {content}
          </ErrorBoundary>
        </Modal>
      </AccessWrapper>
    </>
  );
};

export default RemoveProjectButton;
