import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import useSearchParams from "../../../hooks/useSearchParams";

const useFrameListItem = (frame, issue) => {
  const { groupId, projectId } = useParams();
  const { getLinkWithMultipleParams } = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const link = `/${groupId}${
    projectId ? "/" + projectId + "/specs" : ""
  }?${getLinkWithMultipleParams({
    frame: frame?.id,
    frameIssue: issue?.id || undefined,
    showIssues: issue?.id ? true : undefined,
  })}`;

  const onShowSlide = (event) => {
    if (event && typeof event.preventDefault === "function")
      event.preventDefault();
    navigate(link, { state: { origin: location?.pathname } });
  };
  const openIssues = Array.isArray(frame?.issues)
    ? frame.issues.filter((issue) => issue.status === "OPEN").length
    : 0;
  const unseenIssues = Array.isArray(frame?.issues)
    ? frame.issues.filter((issue) => issue.unseen).length
    : 0;
  return { onShowSlide, unseenIssues, openIssues, link };
};

export default useFrameListItem;
