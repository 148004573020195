import { useEffect } from "react";
import useInput from "../../../hooks/useInput";
import useDynamicForm from "../../../hooks/useDynamicForm";

const usePatchableInputs = (currentValues, selectedTemplate) => {
  let notes;
  const filteredCurrentValues = currentValues?.filter((value) => {
    if (value.k === "notes") notes = value?.v;
    return value.k !== "notes";
  });
  const commentInput = useInput(notes || "");

  const inputsFromTemplate = selectedTemplate?.ingredients?.map(
    (ingredient) => ({
      key: ingredient.name,
      label: ingredient.name,
      value: currentValues ? currentValues[ingredient.name] : "",
    })
  );
  const inputsFromCurrentValues = filteredCurrentValues?.map(
    (currentValue) => ({
      key: currentValue.k,
      label: currentValue.k,
      value: currentValue?.v,
    })
  );

  let initialInputs = [];
  if (inputsFromTemplate) {
    if (inputsFromCurrentValues?.length > 0) {
      initialInputs = inputsFromCurrentValues?.filter(
        (inputFromCurrentValue) =>
          inputsFromTemplate.findIndex(
            (inputFromTemplate) =>
              inputFromTemplate.id === inputFromCurrentValue.template
          ) < 0
      );
      initialInputs = [...inputsFromTemplate, ...initialInputs];
    }
    initialInputs = inputsFromTemplate;
  } else if (inputsFromCurrentValues?.length > 0) {
    initialInputs = inputsFromCurrentValues;
  }

  const { inputs, values, reset: resetSpecs } = useDynamicForm(initialInputs);

  const reset = () => {
    commentInput.reset();
    resetSpecs();
  };

  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, [JSON.stringify(currentValues != null ? currentValues : {})]);

  // let specifications = [];
  const specsKeys = Object.keys(values);
  const specifications = specsKeys?.map((specsKey) => ({
    k: specsKey,
    v: values[specsKey],
  }));
  if (commentInput?.value != null) notes = commentInput?.value;

  return { specifications, notes, inputs, commentInput, values, reset };
};

export default usePatchableInputs;
