import { useEffect, useState } from "react";

const useOriginalSizeDetector = (imageRef) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  useEffect(() => {
    if (imageRef.current) {
      const imageElement = imageRef.current;
      if (imageElement.naturalWidth && imageElement.naturalHeight) {
        setWidth(imageElement.naturalWidth);
        setHeight(imageElement.naturalHeight);
      } else {
        // Wait for the image to load
        imageElement.addEventListener("load", () => {
          setWidth(imageElement.naturalWidth);
          setHeight(imageElement.naturalHeight);
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  return { width, height };
};

export default useOriginalSizeDetector;
