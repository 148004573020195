import classNames from "classnames";
import styles from "./chip.module.css";
import Button from "./Button";
import useLang from "../hooks/useLang";

const Chip = ({
  children,
  withButton,
  toggleChip,
  statusChip,
  countChip,
  roleChip,
  active,
  status,
  darker,
  unseen,
  small,
  ...rest
}) => {
  return (
    <div
      className={classNames(styles.chip, {
        [styles.withButton]: withButton,
        [styles.statusChip]: statusChip,
        [styles.toggleChip]: toggleChip,
        [styles.roleChip]: roleChip,
        [styles.countChip]: countChip,
        [styles[String(status).toLowerCase()]]: statusChip && status,
        [styles.unseen]: unseen,
        [styles.darker]: darker,
        [styles.small]: small,
        [styles.active]: toggleChip && active,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

const RemovableChip = ({ children, onRemove }) => {
  return (
    <Chip withButton>
      {children}
      <Button
        inline
        icon={"cross"}
        onClick={(event) => {
          event?.preventDefault();
          onRemove();
        }}
      />
    </Chip>
  );
};

const ToggleChip = ({ children, active, onToggle, ...rest }) => {
  return (
    <Chip
      toggleChip
      active={active}
      onClick={(event) => {
        event?.preventDefault();
        if (typeof onToggle === "function") onToggle();
      }}
      {...rest}
    >
      {children}
    </Chip>
  );
};

const StatusChip = ({ status, ...rest }) => {
  const lang = useLang();

  return (
    <Chip statusChip status={status} {...rest}>
      {lang(status)}
    </Chip>
  );
};

const CountChip = ({ children, unseen, darker, ...rest }) => {
  return (
    <Chip countChip unseen={unseen} darker={darker} {...rest}>
      {children}
    </Chip>
  );
};

const RoleChip = ({ children, ...rest }) => {
  return (
    <Chip roleChip {...rest}>
      {children}
    </Chip>
  );
};

export { RemovableChip, ToggleChip, StatusChip, CountChip, RoleChip };
export default Chip;
