import { useRef } from "react";
import useInput from "../../../hooks/useInput";
import { useGetUsersByFragmentQuery } from "../usersSlice";
import { useInitialsFromArray } from "../../comments/hooks/useInitials";

const useUserFinder = (onEmailEnter) => {
  const { value: inputValue, attributes: inputAttributes } = useInput();
  const inputValueRef = useRef();
  const isEmail = inputValue && inputValue.includes("@");
  const isEmailRef = useRef(false);

  const { data: suggestions, isLoading } = useGetUsersByFragmentQuery(
    inputValue.toLowerCase(),
    { skip: !inputValue || isEmail }
  );

  const suggestionArray = suggestions?.ids?.map(
    (id) => suggestions?.entities[id]?.username
  );
  const initials = useInitialsFromArray(suggestionArray);
  let entitiesWithInitials = {};
  suggestions?.ids?.map((id, index) => {
    return (entitiesWithInitials[id] = {
      ...suggestions?.entities[id],
      initials: initials[index],
    });
  });

  let showSuggestions = !!inputValue || (!inputValue && isLoading);
  if (isEmail) {
    isEmailRef.current = true;
    showSuggestions = false;
    if (inputValue !== inputValueRef.current) {
      inputValueRef.current = inputValue;
      onEmailEnter(inputValue);
    }
  } else {
    if (isEmailRef.current) {
      isEmailRef.current = false;
      onEmailEnter(null);
    }
    inputValueRef.current = inputValue;
  }

  return {
    suggestions: { ids: suggestions?.ids, entities: entitiesWithInitials },
    showSuggestions,
    inputValue,
    inputAttributes,
    isLoading,
    isEmail,
  };
};

export default useUserFinder;
