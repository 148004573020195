import useLang from "../../hooks/useLang";
import useEmailAdder from "./useEmailAdder";
import Modal, { FormModal } from "../../components/Modal";
import Form from "../../components/Form";
import Input from "../../components/Input";
import { DialogFooter } from "../../components/Footer";
import Button from "../../components/Button";

const EmailAdder = ({ renderOpen }) => {
  const lang = useLang();
  const { emailInput, showDialog, onHideDialog, handleSubmit, handleCancel } =
    useEmailAdder();

  return (
    <>
      <FormModal
        title={lang("add_email")}
        renderOpen={renderOpen}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        disabled={!emailInput?.value || !emailInput?.isValid}
      >
        <Form modal>
          <Input
            type="email"
            label={lang("email")}
            invalid={!emailInput?.isValid || !emailInput?.value}
            {...emailInput.attributes}
          />
        </Form>
      </FormModal>
      <Modal isOpen={showDialog} onClose={onHideDialog}>
        {lang("invitation_sent")}
        <DialogFooter>
          <Button important onClick={onHideDialog}>
            {lang("Close")}
          </Button>
        </DialogFooter>
      </Modal>
    </>
  );
};

export default EmailAdder;
