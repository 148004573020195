import useProjectPatcher from "./hooks/useProjectPatcher";
import ProjectModifier from "./ProjectModifier";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const ProjectPatcher = ({ project, renderOpen, loader, onClose }) => {
  let modifierAttribs = useProjectPatcher(project, onClose);
  return (
    <AccessWrapper action={userActions.PATCH_PROJECT_SPECS} project={project}>
      <ProjectModifier {...{ ...modifierAttribs, renderOpen, loader }} />
    </AccessWrapper>
  );
};

export default ProjectPatcher;
