import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import { useParams } from "react-router-dom";
import useProject from "../projects/hooks/useProject";
import useGroup from "../projectGroups/hooks/useGroup";

import styles from "./navigation.module.css";

const ProjectMarker = ({ expanded }) => {
  const { groupId, projectId } = useParams();
  const { group } = useGroup();
  const { project } = useProject();

  return (
    <section className={styles.projectMarker}>
      {groupId && projectId ? (
        <Link to={`/${groupId}`} className={styles.returnButton}>
          <Icon arrowReturnUp size={16} />
        </Link>
      ) : null}
      {expanded ? <h2>{projectId ? project?.name : group.name}</h2> : null}
    </section>
  );
};

export default ProjectMarker;
