import useSearchParams from "../../../hooks/useSearchParams";

const useSlideId = () => {
  const { getParam, removeParam } = useSearchParams();
  const slideId = getParam("frame");

  const closeSlide = () => {
    removeParam("frame");
  };

  return { slideId, closeSlide };
};

export default useSlideId;
