const OVERRIDES = "__OVERRIDES__";

const useOverrides = () => {
  const getOverrides = (response) => {
    if (response?.data) {
      const overrides = response.data[OVERRIDES];
      if (overrides && Array.isArray(overrides) && overrides.length > 0) {
        const result = {};
        result[OVERRIDES] = overrides;
        return result;
      }
    }
    return null;
  };

  const hasOverrides = (response) => {
    if (getOverrides(response) != null) return true;
    return false;
  };

  return { hasOverrides, getOverrides };
};

export default useOverrides;
