import { NavLink } from "react-router-dom";
import Icon from "./Icon";
import { useState } from "react";

import styles from "./link.module.css";

const Link = ({ children, to, icon, ...rest }) => {
  const [hover, setHover] = useState(false);
  let props = {};
  if (icon) props[icon] = true;
  if (hover) props.hover = true;
  props.emphasized = true;

  return (
    <NavLink
      to={to}
      className={styles.link}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      {...rest}
    >
      {icon ? <Icon {...props} /> : null}
      {children}
    </NavLink>
  );
};

export default Link;
