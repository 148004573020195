import { useReducer } from "react";

const useDynamicForm = (initialInputs = [], unusedInputs = []) => {
  const getInitialInputState = (input) => {
    return {
      key: input.key,
      label: input.label,
      name: input.key,
      id: input.key,
      value: input.value != null ? input.value : "",
      onChange: (e) => {
        dispatch({
          type: "change_value",
          payload: { key: input.key, value: e.currentTarget.value },
        });
      },
      remove: () => {
        dispatch({
          type: "remove_input",
          payload: input.key,
        });
      },
    };
  };

  const formDataInit = ({ inputs, unusedInputs }) => {
    const newState = {
      inputs: inputs && Array.isArray(inputs) ? inputs : [],
      unusedInputs:
        unusedInputs && Array.isArray(unusedInputs) ? unusedInputs : [],
    };
    if (initialInputs.length > 0) {
      newState.inputs = initialInputs?.map((initialInput) =>
        getInitialInputState(initialInput)
      );
      newState.values = {};
      newState.inputs?.map((input) => {
        newState.values[input.key] = input.value;
        return false;
      });
    }
    return newState;
  };

  const formDataRemove = (state, inputKeyToRemove) => {
    const inputToRemove = state.inputs.find(
      (input) => input.key === inputKeyToRemove
    );
    if (!inputToRemove) {
      console.warn(`Input with key "${inputKeyToRemove}" not found`);
      return state;
    }
    const newInputs = state.inputs.filter(
      (input) => input.key !== inputKeyToRemove
    );
    const newValues = { ...state.values };
    delete newValues[inputKeyToRemove];
    return {
      ...state,
      inputs: newInputs,
      unusedInputs: [...state.unusedInputs, inputToRemove],
      values: newValues,
    };
  };

  const formDataAdd = (state, inputKeyToAdd) => {
    const inputToAdd = state.unusedInputs.find(
      (input) => input.key === inputKeyToAdd
    );
    if (!inputToAdd) {
      console.warn(`Unused input with key "${inputKeyToAdd}" not found`);
      return state;
    }
    const initialInputState = getInitialInputState(inputToAdd);
    const newInputs = [...state.inputs, initialInputState];
    const newUnusedInputs = state.unusedInputs.filter(
      (input) => input.key !== inputKeyToAdd
    );
    const newValues = { ...state.values };
    newValues[inputKeyToAdd] = initialInputState.value;
    return {
      ...state,
      inputs: newInputs,
      unusedInputs: newUnusedInputs,
      values: newValues,
    };
  };

  const formDataChangeValue = (state, { key, value }) => {
    const inputToChange = state.inputs.find((input) => input.key === key);
    if (!inputToChange) {
      console.warn(`Input with key "${key}" not found`);
      return state;
    }
    const newInputs = state.inputs?.map((input) => {
      if (input.key === key) {
        return { ...input, value };
      }
      return input;
    });
    const newValues = { ...state.values };
    newValues[key] = value;
    return {
      ...state,
      inputs: newInputs,
      values: newValues,
    };
  };

  const formDataReducer = (state, action) => {
    switch (action.type) {
      case "remove_input":
        return formDataRemove(state, action.payload);
      case "add_input":
        return formDataAdd(state, action.payload);
      case "change_value":
        return formDataChangeValue(state, action.payload);
      case "reset":
        return formDataInit({ input: initialInputs, unusedInputs });
      default:
        console.warn(`Invalid action type "${action.type}"`);
        return state;
    }
  };

  const [formData, dispatch] = useReducer(
    formDataReducer,
    { inputs: initialInputs, unusedInputs },
    formDataInit
  );

  const addInput = (inputKey) => {
    dispatch({ type: "add_input", payload: inputKey });
  };

  const reset = () => {
    dispatch({ type: "reset" });
  };

  return {
    inputs: formData.inputs,
    unusedInputs: formData.unusedInputs,
    addInput,
    reset,
    values: formData.values || {},
  };
};

export default useDynamicForm;
