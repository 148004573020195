import useLang from "../../hooks/useLang";
import { BundleGroup } from "../../components/Group";
import { SecondaryParagraph } from "../../components/Paragraph";

const VisibleOffer = ({ offer }) => {
  const lang = useLang();
  if (!offer || !offer[0]) return null;

  return (
    <>
      <BundleGroup
        style={{
          alignItems: "flex-start",
          borderTop: "1px solid var(--clr-neutral-100)",
          paddingTop: "var(--spacing-2)",
        }}
      >
        <BundleGroup width={36}>
          <SecondaryParagraph>{`${lang("price")}`}</SecondaryParagraph>
        </BundleGroup>
        <BundleGroup vertical>
          {offer.map((offerVersion) => (
            <BundleGroup key={offerVersion.id}>
              <BundleGroup>
                <p style={{ fontSize: "var(--text-lg-size)" }}>
                  {`${offerVersion?.amount} ${offerVersion?.currency}`}
                </p>
              </BundleGroup>
              {offerVersion?.notes ? (
                <p
                  style={{ marginLeft: "var(--spacing-4)" }}
                >{`${offerVersion?.notes}`}</p>
              ) : null}
            </BundleGroup>
          ))}
        </BundleGroup>
      </BundleGroup>
    </>
  );
};

export default VisibleOffer;
