import { apiSlice } from "../api/apiAuthenticatedSlice";

export const commentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewComment: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/issues/${params?.id}/messages`,
          method: "POST",
          body,
        };
      },
    }),
    markAsRead: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/issues/${params?.issueId}/messages/${params?.messageIdx}/seen`,
          method: "POST",
        };
      },
    }),
  }),
});

export const { useAddNewCommentMutation, useMarkAsReadMutation } =
  commentsApiSlice;
