import useUsersWithInitials from "../../users/hooks/useUsersWithInitials";

const useIssueExcerpt = (issueFromProps) => {
  const { users } = useUsersWithInitials();
  const owner = users?.find((user) => user.id === issueFromProps?.owner_id);
  let issue = { ...issueFromProps };
  if (owner) {
    issue.owner = { ...owner };
  }

  return { issue };
};

export default useIssueExcerpt;
