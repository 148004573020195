import classNames from "classnames";
import styles from "./group.module.css";

const Group = ({
  children,
  header,
  page,
  bundle,
  vertical,
  split,
  width,
  alignLeft,
  alignRight,
  alignTop,
  centered,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        styles.group,
        { [styles.header]: header },
        { [styles.page]: page },
        { [styles.bundle]: bundle },
        { [styles.vertical]: vertical },
        { [styles.split]: split },
        { [styles.fixedWidth]: width },
        { [styles[`width-${width}`]]: width },
        { [styles.centered]: centered },
        { [styles.alignRight]: alignRight },
        { [styles.alignLeft]: alignLeft },
        { [styles.alignTop]: alignTop }
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

const BundleGroup = ({
  children,
  width,
  alignRight,
  alignLeft,
  alignTop,
  vertical,
  centered,
  ...rest
}) => {
  return (
    <Group
      bundle
      vertical={vertical}
      width={width}
      alignRight={alignRight}
      centered={centered}
      alignLeft={alignLeft}
      alignTop={alignTop}
      {...rest}
    >
      {children}
    </Group>
  );
};

const SplitGroup = ({ children, ...rest }) => {
  return (
    <Group split {...rest}>
      {children}
    </Group>
  );
};

const HeaderGroup = ({ children, ...rest }) => {
  return (
    <Group header {...rest}>
      {children}
    </Group>
  );
};

const HeaderButtonGroup = ({ children, ...rest }) => {
  return <Group {...rest}>{children}</Group>;
};

const PageHeaderGroup = ({ children, ...rest }) => {
  return (
    <Group page header {...rest}>
      {children}
    </Group>
  );
};

const PageHeaderButtonGroup = ({ children, ...rest }) => {
  return (
    <Group page {...rest}>
      {children}
    </Group>
  );
};

export {
  BundleGroup,
  SplitGroup,
  PageHeaderGroup,
  PageHeaderButtonGroup,
  HeaderGroup,
  HeaderButtonGroup,
};
export default Group;
