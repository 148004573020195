import { useState, useEffect } from "react";
import { getErrorMessage } from "../../../utils/errorUtils";
import useProjectPatch from "./useProjectPatch";
import usePatchableInputs from "./usePatchableInputs";
import useLang from "../../../hooks/useLang";
import useFetchAfterMutationStatus from "../../../hooks/useFetchAfterMutationStatus";

const useProjectPatcher = (project, onClose) => {
  const lang = useLang();
  const [mutationStarted, setMutationStarted] = useState(false);
  const specs = Array.isArray(project.specifications)
    ? project.specifications
    : [];
  const patchableInputs = usePatchableInputs([
    ...specs,
    { k: "notes", v: project?.notes },
  ]);
  const body = {
    specifications: patchableInputs?.specifications,
    notes: patchableInputs?.notes || "",
  };
  const { handleSubmit, isLoading, error } = useProjectPatch(project?.id, body);
  const { isLoading: reloading } = useFetchAfterMutationStatus(
    isLoading,
    project.isFetching
  );

  useEffect(() => {
    if (reloading) {
      setMutationStarted(true);
    } else if (mutationStarted) {
      setMutationStarted(false);
      patchableInputs.reset();
    }
    // eslint-disable-next-line
  }, [reloading]);

  const handleCancel = () => {
    if (typeof onClose === "function") onClose();
    patchableInputs.reset();
  };

  return {
    modalTitle: lang("edit_specifications_title"),
    submitLabel: lang("edit_specifications"),
    handleSubmit,
    handleCancel,
    isLoading,
    reloading,
    ...patchableInputs,
    errMsg: getErrorMessage(error),
    show: true,
    enabled: true,
  };
};

export default useProjectPatcher;
