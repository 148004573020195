import useFrameIssues from "./useFrameIssues";
import useSearchParams from "../../../hooks/useSearchParams";
import { useLocation } from "react-router-dom";
import useFrameIssueId from "./useFrameIssueId";
import useIssueById from "../../issues/hooks/useIssueById";
import { useParams } from "react-router-dom";

const useSlideshowAside = () => {
  const { issues, ...rest } = useFrameIssues();
  const { getLinkWithParam, removeParam } = useSearchParams();
  const { pathname } = useLocation();
  const frameIssue = useFrameIssueId();
  const { issue } = useIssueById(frameIssue);
  const { projectId } = useParams();
  const showIssues = frameIssue == null;
  const showComments = frameIssue != null;

  let issuesWithLinks = [];
  if (Array.isArray(issues)) {
    issuesWithLinks = issues?.map((issue) => {
      const link = `${pathname}?${getLinkWithParam("frameIssue", issue.id)}`;
      return { ...issue, link };
    });
  }

  const closeComments = () => {
    removeParam("frameIssue");
  };

  return {
    showAside: projectId != null,
    issues: issuesWithLinks,
    showIssues,
    showComments,
    issue,
    closeComments,
    ...rest,
  };
};

export default useSlideshowAside;
