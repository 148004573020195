import styles from "./textArea.module.css";

const TextArea = ({ children, ...rest }) => {
  return (
    <textarea className={styles.textarea} {...rest}>
      {children}
    </textarea>
  );
};

export default TextArea;
