import classNames from "classnames";
import styles from "./avatar.module.css";

const Avatar = ({ initials, small, user, ...rest }) => {
  return (
    <div
      className={classNames(styles.avatar, { [styles.small]: small })}
      {...rest}
    >
      {!user ? (
        initials
      ) : user.picture ? (
        <img
          src={user.picture}
          className={styles.avatarImage}
          alt={user.username || user.id}
        />
      ) : (
        user.initials
      )}
    </div>
  );
};

export default Avatar;
