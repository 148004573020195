import useInput from "../../hooks/useInput";
import { useAddEmailMutation } from "../auth/authApiSlice";
import useAlerts from "../alerts/useAlerts";
import { useState } from "react";

const useEmailAdder = () => {
  const { onError } = useAlerts();
  const emailInput = useInput("");
  const [addEmailMutation] = useAddEmailMutation();
  const [showDialog, setShowDialog] = useState(false);

  const onHideDialog = () => {
    setShowDialog(false);
  };

  const handleSubmit = async () => {
    try {
      await addEmailMutation({
        email: emailInput?.value,
      }).unwrap();
      setShowDialog(true);
    } catch (err) {
      onError(err);
    }
  };

  const handleCancel = () => {
    emailInput.reset();
  };

  return {
    enabled: emailInput?.value,
    emailInput,
    handleSubmit,
    handleCancel,
    showDialog,
    onHideDialog,
  };
};

export default useEmailAdder;
