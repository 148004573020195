import useLang from "../../hooks/useLang";
import { SecondaryParagraph } from "../../components/Paragraph";
import OwnerBadge from "../../components/OwnerBadge";
import { BundleGroup } from "../../components/Group";
import useSimpleDate from "../../hooks/useSimpleDate";

const IssueMeta = ({ issue }) => {
  const lang = useLang();
  const { getSimpleDate } = useSimpleDate();

  return (
    <>
      <BundleGroup>
        <SecondaryParagraph>{`${lang("created_by")}: `}</SecondaryParagraph>
        <OwnerBadge user={issue?.owner} />
        <SecondaryParagraph>
          {getSimpleDate(issue?.date_created)}
        </SecondaryParagraph>
      </BundleGroup>
    </>
  );
};

export default IssueMeta;
