import styles from "./navigation.module.css";

const MenuList = ({ children }) => {
  return (
    <ul className={styles.menuList}>
      {children ? (
        <>
          {Array.isArray(children) ? children?.map((child) => child) : children}
        </>
      ) : null}
    </ul>
  );
};

export default MenuList;
