import useProfilePatcher from "./hooks/useProfilePatcher";
import { FormModal } from "../../components/Modal";
import Form from "../../components/Form";
import Input from "../../components/Input";
import { ToggleChip } from "../../components/Chip";
import useLang from "../../hooks/useLang";
import { BundleGroup } from "../../components/Group";
import { SecondaryParagraph } from "../../components/Paragraph";

const ProfilePatcher = ({ renderOpen }) => {
  const lang = useLang();
  const {
    usernameInput,
    emails,
    primaryEmail,
    selectEmail,
    handleSubmit,
    handleCancel,
  } = useProfilePatcher();

  const emailChips = emails.map((email) => {
    return (
      <ToggleChip
        key={email}
        active={email === primaryEmail}
        onClick={() => {
          selectEmail(email);
        }}
      >
        {email}
      </ToggleChip>
    );
  });

  return (
    <FormModal
      title={lang("edit_profile")}
      renderOpen={renderOpen}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <Form modal>
        <Input
          label={lang("username")}
          labelWidth={40}
          {...usernameInput.attributes}
        />
        <BundleGroup style={{ alignItems: "flex-start" }}>
          <BundleGroup width={40} style={{ flexShrink: "0" }}>
            <SecondaryParagraph>{lang("primary_email")}</SecondaryParagraph>
          </BundleGroup>
          <BundleGroup style={{ flexShrink: "1", flexWrap: "wrap" }}>
            {emailChips}
          </BundleGroup>
        </BundleGroup>
      </Form>
    </FormModal>
  );
};

export default ProfilePatcher;
