import SpecsList from "../specifications/SpecsList";
import Link from "../../components/Link";
import Section from "../../components/Section";
import IssueCounterChip from "../issues/IssueCounterChip";
import { BundleGroup, SplitGroup } from "../../components/Group";
import Button from "../../components/Button";
import ProjectPatcher from "./ProjectPatcher";
import ProjectFreezer from "./ProjectFreezer";
import PriceProvider from "./PriceProvider";
import { SecondaryParagraph } from "../../components/Paragraph";
import useLang from "../../hooks/useLang";
import TotalPrice from "./TotalPrice";

const SpecsTableSmallScreen = ({ group }) => {
  const lang = useLang();
  const projects = group?.projects.map((project) => {
    let visibleOffers = project?.offers?.displayed.map(
      (displayedOffer) => displayedOffer.amount
    );
    let sortedOffers = visibleOffers?.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
    let lowestPrice;
    if (Array.isArray(sortedOffers) && sortedOffers.length > 0) {
      lowestPrice = sortedOffers[0];
    }

    const specs = project?.specifications ? project.specifications : [];
    return (
      <Section
        key={project.id}
        style={{
          backgroundColor: project?.frozen ? "var(--clr-neutral-200)" : "unset",
        }}
      >
        <SplitGroup>
          <BundleGroup>
            <BundleGroup>
              <Link
                to={`/${project?.group_id}/${project?.id}`}
                style={{ padding: "0" }}
              >
                <h2>{project?.name}</h2>
              </Link>
            </BundleGroup>
            <IssueCounterChip
              unseen={project?.no_of_unseen}
              open={project?.no_of_open_issues}
              projectId={project?.id}
            />
          </BundleGroup>
          <BundleGroup>
            <ProjectPatcher
              project={{ ...project, isFetching: group?.isFetching }}
              loader={<Button rounded emphasized loader disabled />}
              renderOpen={(onClick) => (
                <Button rounded emphasized icon={"pencil"} onClick={onClick} />
              )}
            />
            <ProjectFreezer
              project={{ ...project, isFetching: group?.isFetching }}
              rounded
            />
          </BundleGroup>
        </SplitGroup>
        <SpecsList specs={[...specs, { k: "notes", v: project?.notes }]} />
        {!project?.frozen ? (
          <SplitGroup style={{ borderTop: "1px solid var(--clr-neutral-100)" }}>
            <BundleGroup>
              <BundleGroup width={36}>
                <SecondaryParagraph>
                  {lang("price_vat_excluded")}
                </SecondaryParagraph>
              </BundleGroup>
              <BundleGroup>
                {lowestPrice != null
                  ? `${lowestPrice} ${project?.offers?.displayed[0]?.currency}`
                  : ""}
              </BundleGroup>
              {sortedOffers?.length > 1 ? <p>...</p> : null}
            </BundleGroup>
            <PriceProvider
              project={project}
              renderOpen={(onClick) => (
                <Button
                  onClick={onClick}
                  rounded
                  emphasized
                  icon={"addOffer"}
                />
              )}
            />
          </SplitGroup>
        ) : null}
      </Section>
    );
  });

  return (
    <>
      {projects}
      <TotalPrice group={group} />
    </>
  );
};

export default SpecsTableSmallScreen;
