import IssuesExcerpt from "./IssuesExcerpt";
import ErrorBoundary from "../../components/ErrorBoundary";
import { PageHeader } from "../../components/Header";
import { PageFooter } from "../../components/Footer";
import { PageHeaderGroup, PageHeaderButtonGroup } from "../../components/Group";
import IssueCreator from "./IssueCreator";
import useLang from "../../hooks/useLang";
import useIssueList from "./hooks/useIssueList";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import useScreenSize from "../../hooks/useScreenSize";
import ProjectSwitch from "../projects/ProjectSwitch";
import { ParamLinkTabs } from "../../components/Tabs";

const IssuesList = () => {
  const lang = useLang();
  const {
    issues,
    isLoading,
    showPagination,
    goToNext,
    goToPrevious,
    disablePrev,
    disableNext,
    paramTabAttributes,
  } = useIssueList();
  const { isSmallScreen } = useScreenSize();

  let content;
  if (isLoading) {
    content = <p>{lang("Loading")}</p>;
  } else {
    content = (
      <>
        <ParamLinkTabs tabs={paramTabAttributes} />
        {issues?.map((issue) => {
          return (
            <IssuesExcerpt
              key={issue?.id}
              issue={issue}
              renderLink={() => (
                <Link to={`${issue?.link}`}>
                  <h3>{`${issue?.iid}: ${issue?.title}`}</h3>
                </Link>
              )}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      <ProjectSwitch />
      <PageHeader>
        <PageHeaderGroup>
          <h2>{lang("issues")}</h2>
        </PageHeaderGroup>
        <PageHeaderButtonGroup>
          <ErrorBoundary fallback="Error in create issue modal">
            <IssueCreator
              renderOpen={(onClick) => (
                <>
                  {isSmallScreen ? (
                    <Button important icon={"addIssue"} onClick={onClick} />
                  ) : (
                    <Button important icon={"addIssue"} onClick={onClick}>
                      {lang("create_issue")}
                    </Button>
                  )}
                </>
              )}
            />
          </ErrorBoundary>
        </PageHeaderButtonGroup>
      </PageHeader>
      <ErrorBoundary fallback="Error in issues">{content}</ErrorBoundary>
      {showPagination ? (
        <PageFooter>
          <Button
            icon={"chevronLeft"}
            onClick={goToPrevious}
            disabled={disablePrev}
          />
          <Button
            icon={"chevronRight"}
            onClick={goToNext}
            disabled={disableNext}
          />
        </PageFooter>
      ) : null}
    </>
  );
};

export default IssuesList;
