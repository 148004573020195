import { BundleGroup } from "../../components/Group";
import { CreatedSubheader } from "../../components/Subheader";
import { MetaParagraph, TertiaryParagraph } from "../../components/Paragraph";
import useLang from "../../hooks/useLang";
import GroupCreator from "./GroupCreator";
import Button from "../../components/Button";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const GroupSubheader = ({ group }) => {
  const lang = useLang();
  if (group.isLoading) return null;
  if (group.isError) return null;
  return (
    <div>
      <CreatedSubheader
        created={group?.date_created}
        modified={group?.date_modified}
      />
      <>
        <br />
        <div
          style={{
            width: "100%",
            backgroundColor: "var(--clr-neutral-100)",
            padding: "var(--spacing-2) var(--horizontal-padding)",
            borderRadius: "var(--rounded)",
          }}
        >
          <h2>{lang("customer_contacts")}</h2>
          {group?.contacts?.company?.name ? (
            <BundleGroup>
              <BundleGroup width={20}>
                <TertiaryParagraph>{`${lang("company")}:`}</TertiaryParagraph>
              </BundleGroup>
              <MetaParagraph>{`${group?.contacts?.company.name}`}</MetaParagraph>
            </BundleGroup>
          ) : null}

          {group?.contacts?.address ? (
            <BundleGroup>
              <BundleGroup width={20}>
                <TertiaryParagraph>{`${lang("address")}:`}</TertiaryParagraph>
              </BundleGroup>
              <MetaParagraph>{`${group?.contacts?.address}`}</MetaParagraph>
            </BundleGroup>
          ) : null}
          {group?.contacts?.phone ? (
            <BundleGroup>
              <BundleGroup width={20}>
                <TertiaryParagraph>{`${lang("phone")}:`}</TertiaryParagraph>
              </BundleGroup>
              <MetaParagraph>{`${group?.contacts?.phone}`}</MetaParagraph>
            </BundleGroup>
          ) : null}
          <AccessWrapper action={userActions.CHANGE_ADDRESS}>
            <BundleGroup style={{ marginTop: "var(--spacing-2)" }}>
              <GroupCreator
                editContacts
                title={lang("customer_contacts")}
                submitLabel={lang("edit_contacts")}
                loader={
                  <Button disabled loader>
                    {lang("edit_contacts")}
                  </Button>
                }
                renderOpen={(onClick) => (
                  <Button onClick={onClick}>{lang("edit_contacts")}</Button>
                )}
              />
            </BundleGroup>
          </AccessWrapper>
        </div>
      </>
    </div>
  );
};

export default GroupSubheader;
