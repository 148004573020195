import { useParams } from "react-router-dom";
import useGroup from "../../projectGroups/hooks/useGroup";
import { useGetProjectByIdQuery } from "../../projects/projectsSlice";

const useProject = () => {
  const { projectId } = useParams();
  const { group } = useGroup();
  const { data, ...rest } = useGetProjectByIdQuery(projectId, {
    skip: !projectId,
  });
  const { ids, entities } = data || {};
  let project = ids != null ? entities[ids] : {};
  if (project.id !== projectId) project = {};
  if (Object.keys(project).length === 0 && Array.isArray(group?.projects)) {
    project = group.projects.find((project) => project.id === projectId) || {};
  }

  return { project, ...rest };
};

export default useProject;
