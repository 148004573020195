import { useState } from "react";
import validateEmail from "../utils/validateEmail";

const useInput = (initValue) => {
  const intitValueNonUndefined = initValue === undefined ? "" : initValue;
  const [value, setValue] = useState(intitValueNonUndefined);
  const [isValid, setIsValid] = useState(true);

  const reset = () => {
    setValue(intitValueNonUndefined);
    setIsValid(true);
  };

  const isValidEmail = (value) => {
    const isEmailValid = validateEmail(value) || value === "";
    setIsValid((state) => (state !== isEmailValid ? isEmailValid : state));
  };

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (e.target.type === "checkbox") {
      newValue = e.target.checked;
    } else if (e.target.type === "email") {
      newValue = e.target.value.toLowerCase().replace(" ", "");
      isValidEmail(newValue);
    }
    setValue(newValue);
  };

  const attributes = {
    value,
    onChange: handleChange,
  };

  return {
    attributes,
    value,
    setValue,
    reset,
    isValid,
  };
};

export default useInput;
