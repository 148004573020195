import useLang from "../hooks/useLang";
import { BundleGroup } from "./Group";
import { MetaParagraph } from "./Paragraph";
import useSimpleDate from "../hooks/useSimpleDate";

const Subheader = () => {
  return <div>SUBHEADER</div>;
};

const CreatedSubheader = ({ created, modified }) => {
  const lang = useLang();
  const { getSimpleDate } = useSimpleDate();
  return (
    <BundleGroup>
      {created ? (
        <MetaParagraph>{`${lang("created")}: ${getSimpleDate(
          created
        )}`}</MetaParagraph>
      ) : null}
      <div style={{ width: "var(--spacing-4)" }} />
      {modified ? (
        <MetaParagraph>{`${lang("modified")}: ${getSimpleDate(
          modified
        )}`}</MetaParagraph>
      ) : null}
    </BundleGroup>
  );
};

export { CreatedSubheader };
export default Subheader;
