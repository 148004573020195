import { createSlice } from "@reduxjs/toolkit";

const alertsSlice = createSlice({
  name: "alerts",
  initialState: { error: null },
  reducers: {
    setError: (state, action) => {
      const { message } = action.payload;
      state.error = {};
      state.error.message = message;
    },
    setErrorWithOverride: (state, action) => {
      const { message, override, request } = action.payload;
      state.error = {};
      state.error.message = message;
      state.error.hasOverride = override != null;
      state.error.override = override;
      state.error.request = request;
    },
    overrideError: (state) => {
      state.error.postOverride = true;
    },
    resetError: (state) => {
      state.error = null;
    },
  },
});

export const { setError, resetError, setErrorWithOverride, overrideError } =
  alertsSlice.actions;

export default alertsSlice.reducer;

export const selectError = (state) => state.alerts.error;
export const selectErrorMessage = (state) => state.alerts.error?.message;
export const selectHasOverride = (state) => state.alerts.error?.hasOverride;
export const selectOverride = (state) => state.alerts.error?.override;
export const selectPostOverride = (state) => state.alerts.error?.postOverride;
export const selectRequestUrl = (state) => state.alerts.error?.request?.url;
export const selectRequestBody = (state) => state.alerts.error?.request?.body;
