import Button from "../../components/Button";
import { useParams } from "react-router";
import { useRemoveMemberFromGroupMutation } from "../projectGroups/groupsSlice";
import { AlertConfirmationModal } from "../../components/Modal";
import useLang from "../../hooks/useLang";
import useAlerts from "../alerts/useAlerts";
import AccessWrapper from "../auth/AccessWrapper";
import { userActions } from "../../config/accessConfig";

const MemberRemover = ({ member: { id: memberId } }) => {
  const { onError } = useAlerts();
  const lang = useLang();
  const { groupId } = useParams();
  const [removeMemberFromGroup, { isLoading }] =
    useRemoveMemberFromGroupMutation();

  const handleMemberRemove = async () => {
    try {
      await removeMemberFromGroup({
        params: { id: groupId, memberId: memberId },
      }).unwrap();
    } catch (err) {
      onError(err);
    }
  };

  return (
    <>
      <AccessWrapper action={userActions.DELETE_MEMBER}>
        <AlertConfirmationModal
          title={lang("remove_member_confirmation_title")}
          onSubmit={handleMemberRemove}
          renderOpen={(onClick) => (
            <Button inline onClick={onClick} icon={"trashIcon"} />
          )}
          isLoading={isLoading}
        >
          <p>{lang("remove_member_confirmation_text")}</p>
        </AlertConfirmationModal>
      </AccessWrapper>
    </>
  );
};

export default MemberRemover;
