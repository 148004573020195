import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiAuthenticatedSlice";

const projectsAdapter = createEntityAdapter();

const initialState = projectsAdapter.getInitialState();

export const projectsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectById: builder.query({
      query: (id) => `/projects/${id}`,
      transformResponse: (response) => {
        return projectsAdapter.setAll(initialState, [response] || []);
      },
      providesTags: (result, error, arg) => [
        ...(result && result.ids
          ? result?.ids?.map((id) => ({ type: "Project", id }))
          : []),
      ],
    }),
    addNewProject: builder.mutation({
      query: ({ params, body }) => ({
        url: `/groups/${params?.id}/projects`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Project", id: "LIST" }],
    }),
    patchSpecifications: builder.mutation({
      query: ({ params, body }) => ({
        url: `/projects/${params?.id}/specifications`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Project", id: arg?.params?.id },
      ],
    }),
    freezeProject: builder.mutation({
      query: ({ params, body }) => ({
        url: `/projects/${params?.id}`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Project", id: arg?.params?.id },
      ],
    }),
    addOffer: builder.mutation({
      query: ({ params, body }) => ({
        url: `/projects/${params?.id}/offers`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Project", id: arg?.params?.id },
      ],
    }),
    removeOffer: builder.mutation({
      query: ({ params }) => ({
        url: `/projects/${params?.id}/offers/${params?.offerId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Project", id: arg?.params?.id },
      ],
    }),
    addFilesToProject: builder.mutation({
      query: ({ id, body }) => ({
        url: `/projects/${id}/files/append`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Project" }],
    }),
    addDrawingsToProject: builder.mutation({
      query: ({ id, body }) => ({
        url: `/projects/${id}/drawings`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Project" }],
    }),
    addVisualizationsToProject: builder.mutation({
      query: ({ id, body }) => ({
        url: `/projects/${id}/visualizations`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Project" }],
    }),
    removeFromProject: builder.mutation({
      query: ({ projectId, fileId, type }) => ({
        url: `/projects/${projectId}/${type}/${fileId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Project", id: arg?.projectId },
      ],
    }),
    removeProject: builder.mutation({
      query: (id) => ({
        url: `/projects/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Project" }],
    }),
    hideFrameInProject: builder.mutation({
      query: ({ projectId, type, frameId, body }) => ({
        url: `projects/${projectId}/${type}/frames/${frameId}`,
        body,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Project", id: arg?.projectId },
      ],
    }),
  }),
});

export const {
  useGetProjectByIdQuery,
  useAddNewProjectMutation,
  usePatchSpecificationsMutation,
  useFreezeProjectMutation,
  useAddOfferMutation,
  useRemoveOfferMutation,
  useAddFilesToProjectMutation,
  useAddDrawingsToProjectMutation,
  useAddVisualizationsToProjectMutation,
  useRemoveProjectMutation,
  useRemoveFromProjectMutation,
  useHideFrameInProjectMutation,
} = projectsApiSlice;
