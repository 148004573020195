import useLang from "../hooks/useLang";
import { Outlet, useLocation } from "react-router-dom";
import { BundleGroup } from "./Group";
import { CenteredLayout } from "./Layout";
import { Navigate } from "react-router-dom";
import usePersistLogin from "../hooks/usePersistLogin";
import Loader from "./Loader";

const PersistLogin = () => {
  const lang = useLang();
  const { token, user, refreshToken } = usePersistLogin();
  const location = useLocation();

  return (
    <>
      {refreshToken ? (
        <>
          {user && token ? (
            <Outlet />
          ) : (
            <CenteredLayout>
              <BundleGroup vertical centered>
                <Loader />
                <p style={{ textAlign: "center" }}>{lang("loading")}</p>
              </BundleGroup>
            </CenteredLayout>
          )}
        </>
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
};

export default PersistLogin;
